import { Fragment, useState } from "react"
import IconButton from "../IconButton"
import SettingContent from "../Setting/SettingContent"
import SettingSidebar from "../Setting/SettingSidebar"
import DrawerSidebar from "../Setting/shared/DrawerSidebar"
import { ArrowUpDoubleIcon } from "../shared/icons"
import { withLazyProviders } from "./with-lazy-providers"
import RenewSubscriptionModal from "../Setting/RenewSubscriptionModal"
import useCustomRouter from "@/hooks/useCustomRouter"
import classNames from "classnames"

const SettingLayout = () => {
  const [isOpenSidebar, setIsOpenSidebar] = useState(false)
  const router = useCustomRouter()
  const { tab } = router.query

  return (
    <Fragment>
      <div className="w-full flex-1 flex flex-col">
        <IconButton
          className="fixed top-[5rem] left-0 z-[24] hidden md:flex lg:hidden p-2 min-h-0"
          onClick={() => setIsOpenSidebar(true)}
        >
          <ArrowUpDoubleIcon className="rotate-90" width={16} height={16} />
        </IconButton>
        <DrawerSidebar isOpen={isOpenSidebar} setIsOpen={setIsOpenSidebar} />
        <div className="flex items-start w-full flex-1 px-4">
          <SettingSidebar className="hidden lg:block h-[calc((var(--vh,1vh)*100)-4rem)]" />
          <div
            className={classNames(
              "ml-0 lg:ml-8 py-6 pt-14 md:px-4 lg:pt-6 flex flex-col items-center h-full lg:items-start flex-1 w-full",
              {
                "max-w-[120rem]": !tab || tab !== "Plans",
              },
            )}
          >
            <SettingContent />
          </div>
        </div>
      </div>
      <RenewSubscriptionModal />
    </Fragment>
  )
}

export default withLazyProviders(SettingLayout)
