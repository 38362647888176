import ModalsPortal from "../ModalsPortal"
import Sidebar from "../Workspace/Sidebar"
import CommonNavbar from "../common/CommonNavbar"
import MobileNavigation from "../common/MobileNavigation"
import { menuCommonPages } from "./CommonLayout"
import { withLazyProviders } from "./with-lazy-providers";

const WorkspaceLayout = ({ children, hiddenSidebar }: { children: React.ReactNode; hiddenSidebar?: boolean }) => {
  return (
    <main id="layout" className="relative block min-h-screen w-full dark:bg-black dark:text-atherGray-0">
      <div id="layout-inside" className="flex-col min-h-screen flex relative w-full">
        <CommonNavbar navItems={menuCommonPages} />
        <div className={"flex-1 flex pb-[5.15rem] md:pb-0"}>
          {!hiddenSidebar && <Sidebar />}
          {children}
          <MobileNavigation />
        </div>
      </div>
      <ModalsPortal />
    </main>
  )
}

export default withLazyProviders(WorkspaceLayout)
