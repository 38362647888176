import IconButton from "@/components/IconButton"
import React from "react"

interface SignOutAllDeviceProps {
  onSignOutAllDevice: () => void
}

const SignOutAllDevice = ({ onSignOutAllDevice }: SignOutAllDeviceProps) => {
  return (
    <div className="flex flex-col">
      <p className="text-[#FFF8EE] font-semibold mb-3">Sign out from all device</p>
      <p className="text-atherGray-300 text-sm mb-3">
        Logged in on a shared device but forgot to sign out? End all sessions by signing out from all devices.
      </p>
      <IconButton colorScheme="secondary" className="p-2 rounded-lg w-fit" onClick={() => onSignOutAllDevice()}>
        <p className="text-[#fff8ee] text-sm font-semibold">Sign out from all devices</p>
      </IconButton>
    </div>
  )
}

export default SignOutAllDevice
