import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { useSubFoldersInfiniteQuery } from "./useSubFoldersInfiniteQuery"
import { customPromiseAllSettled } from "@/utils/promise"
import { GetSubFoldersResponse } from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"

export const useRecoverFolderMutation = createMutation({
  mutationFn: async ({ ids }: { ids: string[]; parentFolderId?: string }) => {
    await customPromiseAllSettled(
      Promise.all(ids.map(folderId => client.api.folderControllerRecoverDeletedFolders(folderId))),
    )
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        if (variables.parentFolderId) {
          const parentSubFoldersKey = useSubFoldersInfiniteQuery.getKey({
            folderId: variables.parentFolderId,
          })

          const parentSubFoldersQueriesDataEntries = queryClient.getQueriesData<
            InfiniteData<GetSubFoldersResponse, number>
          >({
            queryKey: parentSubFoldersKey,
          })
          if (parentSubFoldersQueriesDataEntries) {
            parentSubFoldersQueriesDataEntries.forEach(([key, parentSubFoldersData]) => {
              if (parentSubFoldersData) {
                const updatedData = {
                  ...parentSubFoldersData,
                  pages: parentSubFoldersData.pages.map(page => {
                    return {
                      ...page,
                      total: page.total - variables.ids.length,
                      folders: page.folders.filter(folder => !variables.ids.includes(folder.id)),
                    }
                  }),
                }

                queryClient.setQueryData(key, updatedData)
              }
            })
          }
        }

        queryClient.invalidateQueries({ queryKey: useSubFoldersInfiniteQuery.getKey() })
      },
    }),
  ],
})
