import IconButton from "@/components/IconButton"
import Modal from "@/components/Modal"
import { useToast } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { getAuth, unlink } from "firebase/auth"
import React from "react"

interface RemoveSocialModalProps {
  isOpen: boolean
  onClose: () => void
}

const RemoveSocialModal = ({ isOpen, onClose }: RemoveSocialModalProps) => {
  const toast = useToast()
  const { userInfoQuery } = useAuth()

  const handleAgree = () => {
    const auth = getAuth()

    if (
      !auth.currentUser ||
      (auth.currentUser && auth.currentUser.providerData.filter(i => i.providerId === "google.com").length === 0)
    )
      return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "profile",
        action: "Remove social account",
        user_name: userInfoQuery?.data?.name ?? "",
        user_username: userInfoQuery?.data?.username ?? "",
        user_id: userInfoQuery?.data?.uid ?? "",
      },
    })

    unlink(auth.currentUser, "google.com")
      .then(() => {
        // Auth provider unlinked from account
        toast({
          status: "success",
          title: "Remove social account successfully",
          message: ["Your GAIA account has been unlinked with your Google account"],
        })

        onClose()
      })
      .catch(error => {
        console.error(error)
        toast({
          status: "error",
          title: "Remove social account failed",
          message: ["Something went wrong, please try again"],
        })
      })
  }

  return (
    <Modal className="max-w-md" title="Remove your Social account" isOpen={isOpen} onClose={onClose}>
      <p className="text-atherGray-300">
        By clicking <span className="text-atherGray-0 font-semibold">Agree</span> button, your GAIA account will be
        unlinked with your <span className="text-atherGray-0 font-semibold">Google account</span>
      </p>
      <div className="mt-6 flex items-center space-x-2 justify-end">
        <IconButton onClick={onClose} colorScheme="secondary">
          Cancel
        </IconButton>
        <IconButton onClick={() => handleAgree()}>Agree</IconButton>
      </div>
    </Modal>
  )
}

export default RemoveSocialModal
