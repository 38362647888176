import client from "@/api/client"
import { AddSDStyleImagesDto, StyleImage } from "@/api/sdk"
import { useToast } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useCreateNewStyleMutation } from "@/queries"
import { useCreateNewStyleStore, useManagementErrorsStore } from "@/stores"
import { css } from "@emotion/css"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { motion } from "framer-motion"
import { useRef, useState } from "react"
import ReactSlider from "react-slider"
import CheckboxComponent from "../Checkbox"
import IconButton from "../IconButton"
import ImageUpload from "../ImageUpload"
import { ImageViewWithDelete } from "../ImageUpload/ImageView"
import Input from "../Input"
import Modal from "../Modal"
import { DeleteIcon, UploadIcon } from "../shared/icons"
import { uploadStyleFiles } from "../Tools/Style/StyleDetail/StyleDetail"
import { useWorkspaceStyleInfiniteQuery } from "@/queries/tools/style/useGetStyleInfiniteQuery"

const CreateStyleModal = () => {
  const { isOpen, setIsOpen } = useCreateNewStyleStore()
  const [selectedThumbnail, setSelectedThumbnail] = useState<number | null>(null)
  const [name, setName] = useState<string | undefined>()
  const [images, setImages] = useState<(StyleImage & { file: Blob | File })[]>([])
  const fileInputImagesRef = useRef<HTMLInputElement>(null)
  const toast = useToast()
  const setErrorState = useManagementErrorsStore(state => state.setErrorState)

  const handleUploadImages = async (files: Blob[]) => {
    googleAnalytics.event({
      action: "click",
      category: "style_detail",
      label: "upload_style_images",
    })

    for (const file of files) {
      if (file.size > 10 * 1000 * 1000) {
        toast({
          status: "error",
          title: "File size too large",
          message: ["Input image exceeds 10MB, please scale down the image and try again"],
        })
        return
      }
    }

    // limit 8 images
    if (images.length + files.length > 8) {
      files = files.slice(0, 8 - images.length)
    }

    const updated = {
      images: [
        ...images,
        ...files.map((file, index) => ({
          url: URL.createObjectURL(file),
          file,
          weight: 0.5,
        })),
      ],
    }

    setImages(updated.images)
  }

  const handleDeleteImage = (idx: number) => {
    const image = images[idx]

    URL.revokeObjectURL(image.url)

    const updated = {
      images: images.filter((_, index) => index !== idx),
    }

    setImages(updated.images)

    if (selectedThumbnail === idx) {
      setSelectedThumbnail(null)
    } else if (selectedThumbnail !== null && selectedThumbnail >= idx) {
      setSelectedThumbnail(selectedThumbnail - 1)
    }
  }

  const handleChangeWeight = (idx: number, weight: number) => {
    const updated = {
      images: images.map((image, index) => (index === idx ? { ...image, weight } : image)),
    }

    setImages(updated.images)
  }

  const handleDeleteAllImages = () => {
    images.forEach(image => {
      URL.revokeObjectURL(image.url)
    })

    setImages([])
    setSelectedThumbnail(null)
  }

  const queryClient = useQueryClient()

  const handleCancel = () => {
    handleDeleteAllImages()
    setIsOpen(false)
    setName(undefined)
    setSelectedThumbnail(null)
  }

  const { mutateAsync: mutateAddStyleImages, isPending: isAdding } = useMutation({
    mutationFn: ({ styleId, data }: { styleId: string; data: AddSDStyleImagesDto }) =>
      client.api.sdStyleControllerAddStyleImages(styleId, data).then(res => res.data),
    onSuccess: () => {
      const key = useWorkspaceStyleInfiniteQuery.getKey()

      queryClient.invalidateQueries({ queryKey: key })

      toast({
        status: "success",
        title: "Style created",
        message: ["You have successfully created a new style"],
      })

      handleCancel()
    },
    onError: (err: any) => {
      toast({ title: "Error", message: [err.message], status: "error" })
    },
  })

  const { mutate: createStyle, isPending: isCreating } = useCreateNewStyleMutation({
    onSuccess(data, variables) {
      if (data && variables.fileIds) {
        mutateAddStyleImages({
          styleId: data.id,
          data: {
            fileIds: variables.fileIds,
            weights: images.map(image => image.weight),
          },
        })
      }
    },
    onError: (err: any) => {
      if (
        err?.message.startsWith("Guest user does not have permission") ||
        err?.message.startsWith("Your subscription has ended")
      ) {
        setErrorState({
          isOpen: true,
          message: err.message,
        })
        return
      }
      toast({ title: "Error", message: [err.message], status: "error" })
    },
  })

  const [isUploading, setIsUploading] = useState(false)

  const handleCreateNewStyle = async () => {
    if (!images || !name || selectedThumbnail === null) return

    setIsUploading(true)

    const uploaded = await uploadStyleFiles(images.map(image => image.file))

    const fileIds = uploaded.map(f => f.id)

    setIsUploading(false)

    const thumbnailUrl = uploaded[selectedThumbnail].url

    if (!thumbnailUrl) return

    createStyle({
      images: [],
      thumbnailId: uploaded[selectedThumbnail].id,
      name,
      isDraft: false,
      fileIds,
    })
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        setIsOpen(false)
      }}
      title="Create new style"
      className="max-w-[80rem]"
    >
      <p className="mb-1">Style name</p>
      <div className="flex md:items-center space-y-4 md:space-y-0 md:space-x-4 mb-6 flex-col md:flex-row">
        <Input
          placeholder="Enter name"
          className="rounded-lg w-[18rem]"
          value={name}
          onChange={e => setName(e.target.value)}
        />
        <CheckboxComponent
          className="rounded-full"
          labelClassName="text-atherGray-300"
          checked
          label="Please select a thumbnail for style (mandatory)"
          onChange={() => {}}
        />
      </div>
      <div className="mb-4 flex lg:items-center flex-col lg:flex-row">
        <p className="text-atherGray-300 flex-1">
          <span className="font-semibold">Style Images </span>Upload upto 8 images to used as style reference. Use
          square images for best results.
        </p>
        <div className="mt-2 lg:mt-0 lg:ml-2 text-right">
          <button
            type="button"
            className={classNames({
              hidden: !images.length,
            })}
            onClick={handleDeleteAllImages}
          >
            <div className="flex items-center text-sm">
              <DeleteIcon className="text-red-500" /> &nbsp; Remove all
            </div>
          </button>
        </div>
      </div>
      <motion.div
        className="flex flex-wrap gap-4"
        initial={{ height: 0, opacity: 0.5 }}
        animate={{ height: "auto", opacity: 1 }}
      >
        {images.length < 8 && (
          <div className="w-full md:w-64 2xl:w72">
            <ImageUpload fileInputRef={fileInputImagesRef} multiple upload={handleUploadImages}>
              <div className="h-full flex flex-col items-center justify-center">
                <div className={classNames("p-2 mb-2 rounded-full bg-atherGray-800")}>
                  <UploadIcon />
                </div>
                <p className="text-atherGray-300 text-center">
                  Upload images or Drag & Drop <br />
                  (max 2048px and less than 10MB)
                </p>
              </div>
            </ImageUpload>
          </div>
        )}
        {images.map((image, idx) => (
          <div key={image.url} className="flex flex-col w-full md:w-64 2xl:w72 mb-4 relative">
            <ImageViewWithDelete
              onClick={() => {
                setSelectedThumbnail(idx)
              }}
              className="overflow-hidden flex-shrink-0 h-[10rem] w-full bg-atherGray-950 border-dashed border border-atherGray-700 rounded-lg"
              url={image.url}
              onDelete={() => handleDeleteImage(idx)}
              preferThumbnail
            />
            <div className="flex justify-between mt-1 mb-2">
              <p className="text-xs text-atherGray-400">Low</p>
              <p className="text-xs text-atherGray-400">Medium</p>
              <p className="text-xs text-atherGray-400">High</p>
            </div>
            <div
              className={css({
                position: "relative",
                width: "100%",
                ".track-slider-0": {
                  backgroundColor: "#5E17EB",
                },
                ".track-slider-1": {
                  backgroundColor: "#E2E8F0",
                },
              })}
            >
              <ReactSlider
                value={image.weight}
                onChange={v => handleChangeWeight(idx, v)}
                min={0}
                max={1}
                step={0.1}
                marks
                className="h-1 horizontal-slider w-full"
                thumbClassName="h-4 w-4 bg-white top-1/2 -translate-y-1/2 rounded-full cursor-pointer"
                trackClassName="h-1 track-slider"
                renderThumb={(props, _) => (
                  <div {...props}>
                    <div
                      className={classNames(
                        "w-2.5 h-2.5 absolute top-1/2 left-1/2 -translate-x-[50%] -translate-y-[50%] rounded-full bg-atherGray-700",
                      )}
                    />
                  </div>
                )}
              />
            </div>
            <div className="absolute top-2 left-2">
              <CheckboxComponent
                showTick
                tickClassName="text-atherGray-500"
                className={classNames("rounded-full", {
                  "bg-transparent border border-atherGray-500": selectedThumbnail !== idx,
                })}
                labelClassName="text-atherGray-300"
                checked={selectedThumbnail === idx}
                label=""
                onChange={() => {
                  setSelectedThumbnail(idx)
                }}
              />
            </div>
          </div>
        ))}
      </motion.div>
      <div className="flex items-center justify-end space-x-2 mt-6">
        <IconButton
          onClick={() => {
            handleCancel()
          }}
          colorScheme="secondary"
        >
          Cancel
        </IconButton>
        <IconButton
          isLoading={isCreating || isUploading || isAdding}
          onClick={handleCreateNewStyle}
          disabled={images.length < 1 || !name || selectedThumbnail === null}
        >
          Create new style
        </IconButton>
      </div>
    </Modal>
  )
}

export default CreateStyleModal
