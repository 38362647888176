import client from "@/api/client"
import { useToast } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useState } from "react"

const useProfile = () => {
  const [error, setError] = useState<Record<string, undefined | string>>({
    name: undefined,
    email: undefined,
    description: undefined,
  })
  const [activeEdit, setActiveEdit] = useState({
    name: false,
    email: false,
    description: false,
  })
  const [isLoading, setIsLoading] = useState<Record<string, boolean>>()

  const qc = useQueryClient()

  const toast = useToast({
    position: "top-center",
  })

  const { mutate: mutateUpdateUserProfile } = useMutation({
    mutationKey: ["patch-user-profile"],
    mutationFn: (data: {
      name?: string
      email?: string
      avatarFileId?: string | null
      description?: string | undefined
    }) => client.api.userControllerUpdateUserProfile(data),
    onMutate(variables) {
      const firstValue = Object.keys(variables)[0]
      if (firstValue)
        setIsLoading(v => ({
          ...v,
          [`${firstValue}`]: true,
        }))
    },
    onError(error: any, variables) {
      const firstValue = Object.keys(variables)[0]

      if (firstValue === "avatarFileId") {
        toast({
          status: "error",
          title: error.message,
        })
      }

      if (firstValue) {
        setError(v => ({
          ...v,
          [`${firstValue}`]: error.message,
        }))
      }
    },
    onSuccess(data, variables) {
      const firstValue = Object.keys(variables)[0]

      if (firstValue) {
        setError(v => ({
          ...v,
          [`${firstValue}`]: undefined,
        }))
        setActiveEdit({
          ...activeEdit,
          [`${firstValue}`]: false,
        })
      }

      toast({
        status: "success",
        title: "Updated your profile",
      })
    },
    onSettled(data, error, variables, context) {
      qc.invalidateQueries({ queryKey: ["user-info"] })
      const firstValue = Object.keys(variables)[0]

      if (firstValue) {
        setIsLoading(v => ({
          ...v,
          [`${firstValue}`]: false,
        }))
      }
    },
  })

  return {
    mutateUpdateUserProfile,
    error,
    setError,
    activeEdit,
    setActiveEdit,
    isLoading,
  }
}

export { useProfile }
