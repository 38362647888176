import client from "@/api/client"
import { googleAnalytics } from "@/lib/gtag"
import { useLeaveFolderStore } from "@/stores"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import useCustomRouter from "@/hooks/useCustomRouter"
import IconButton from "../IconButton"
import Modal from "../Modal"

const LeaveFolderModal = () => {
  const qc = useQueryClient()
  const router = useCustomRouter()

  const { leaveFolder, setLeaveFolder } = useLeaveFolderStore()

  const { mutate: mutateLeaveFolder, isPending: isLoading } = useMutation({
    mutationFn: (id: string) => client.api.folderControllerLeaveFolder(id),
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: ["folders"] })
      qc.invalidateQueries({ queryKey: ["folders-trash"] })
      setLeaveFolder(null)

      if (leaveFolder?.onClose) {
        leaveFolder.onClose()

        return
      }

      router.push("/workspace")
    },
  })

  const handleSubmit = () => {
    if (!leaveFolder) return

    googleAnalytics.event({
      action: "click",
      category: "modal",
      label: `leave_folder_${leaveFolder?.id}`,
      value: 1,
    })

    mutateLeaveFolder(leaveFolder.id)
  }

  const handleClose = () => {
    setLeaveFolder(null)
  }

  return (
    <Modal
      title={"Want to leave the folder?"}
      className="overflow-visible max-w-md"
      isOpen={!!leaveFolder}
      onClose={handleClose}
    >
      <p className="text-sm mb-2 text-atherGray-300">{`Are you sure you want to leave the folder?`}</p>
      <p className="text-sm mb-2 text-atherGray-300">You will no longer have access to this folder and its files.</p>

      <div className="flex items-center justify-end mt-6 space-x-2">
        <IconButton onClick={handleClose} className="px-4 font-semibold">
          Cancel
        </IconButton>
        <IconButton
          onClick={handleSubmit}
          isLoading={isLoading}
          className="px-4 font-semibold bg-atherOrange-500 hover:bg-atherOrange-400 active:bg-atherOrange-600"
        >
          Leave The Folder
        </IconButton>
      </div>
    </Modal>
  )
}

export default LeaveFolderModal
