import Modal from "@/components/Modal"
import { ArrowDownIcon } from "@/components/shared/icons"
import classNames from "classnames"
import { useRef, useState } from "react"
import { useSecurity } from "../useSecurity"
import IconButton from "@/components/IconButton"
import { useOutsideClick } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"

interface DeletetAccountModalProps {
  stateModel: ReturnType<typeof useSecurity>["stateModel"]
  setStateModel: ReturnType<typeof useSecurity>["setStateModel"]
  value: string
  setValue: ReturnType<typeof useSecurity>["setStateValue"]
}

const options = [
  "I’m no longer interested in products/ services provided",
  "I never created this account",
  "I experienced technical issues",
  "My account was hacked",
  "Harassment from other players/ users",
  "Something else",
]

const DeleteAccountModal = ({ stateModel, setStateModel, value, setValue }: DeletetAccountModalProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef(null)
  const { userInfoQuery } = useAuth()

  useOutsideClick({
    ref,
    enabled: isOpen,
    handler: () => setIsOpen(false),
  })

  return (
    <Modal
      isOpen={stateModel.delete.step1}
      onClose={() =>
        setStateModel(v => ({
          ...v,
          delete: {
            ...v.delete,
            step1: false,
          },
        }))
      }
      title="We’re sad to see you go..."
      className="max-w-[520px] overflow-visible"
    >
      <p className="text-atherGray-300 text-sm font-[400] leading-[17.5px] mb-6 ">
        Before you delete, please let us know the reason you are leaving.
      </p>
      <div className="flex flex-col mb-6">
        <p className="text-atherGray-300 text-sm font-[400] leading-[17.5px] mb-2">I leave because...</p>
        <div ref={ref} className="relative">
          <div
            className="flex items-center min-h-[40px] p-2 cursor-pointer bg-atherGray-850 rounded-lg border border-atherGray-800"
            onClick={() => setIsOpen(prev => !prev)}
          >
            <div className="flex items-center flex-1 w-[85%]">
              <p className="pr-4 ml-2 font-[500] truncate">{value}</p>
            </div>
            <span className="px-2">
              <ArrowDownIcon className="text-atherGray-500" />
            </span>
          </div>
          {isOpen && (
            <div className="absolute left-0 right-0 z-50 p-4 translate-y-1 border rounded-lg top-full bg-atherGray-850 border-atherGray-800">
              {options.map((_i, _index) => (
                <div
                  key={_i}
                  className="py-2 cursor-pointer"
                  onClick={() => {
                    setValue(v => ({
                      ...v,
                      reasonValue: _i,
                    }))
                    setIsOpen(false)
                  }}
                >
                  <p
                    className={classNames("font-semibold", {
                      "text-atherGray-0": value === _i,
                      "text-atherGray-300": value !== _i,
                    })}
                  >
                    {_i}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <div className={classNames("flex justify-end w-full space-x-2")}>
        <IconButton
          colorScheme="secondary"
          onClick={() =>
            setStateModel(v => ({
              ...v,
              delete: {
                ...v.delete,
                step1: false,
              },
            }))
          }
        >
          Cancel
        </IconButton>
        <IconButton
          onClick={() => {
            setStateModel(v => ({
              ...v,
              delete: {
                ...v.delete,
                step1: false,
                step2: true,
              },
            }))
            googleAnalytics.event({
              action: "click",
              category: "settings_account",
              label: `account: delete_reason`,
              params: {
                user_id: userInfoQuery?.data?.uid ?? "",
                user_username: userInfoQuery?.data?.username ?? "",
                reason: value,
              },
              value: 1,
            })
          }}
        >
          Next
        </IconButton>
      </div>
    </Modal>
  )
}

export default DeleteAccountModal
