import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { useWorkspaceModelsInfiniteQuery } from "./useWorkspaceModelsInfiniteQuery"
import { InfiniteData } from "@tanstack/react-query"
import { SearchModelResults } from "@/api/sdk"

interface RecoverModelMutationVariables {
  ids: string[]
}

export const useRecoverModelMutation = createMutation({
  mutationFn: async ({ ids }: RecoverModelMutationVariables) => {
    return client.api.sdModelControllerRecoverModels({ modelIds: ids }).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        // Update model list
        const modelListKey = useWorkspaceModelsInfiniteQuery.getKey()
        const modelListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchModelResults, number>>({
          queryKey: modelListKey,
        })

        modelListQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            pages: data.pages.map(page => {
              const updatedPage = {
                ...page,
                data: page.data.filter(model => {
                  return !variables.ids.includes(model.id)
                }),
              }
              updatedPage.total = updatedPage.data.length
              return updatedPage
            }),
          }

          queryClient.setQueryData(key, updatedData)
        })
      },
    }),
  ],
})
