import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { SearchModelTrainingResults } from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"
import { useWorkspaceModelTrainingInfiniteQuery } from "./useWorkspaceModelTrainingInfiniteQuery"

export const useDeleteModelTrainingMutation = createMutation({
  mutationFn: async ({ ids, deletedAt }: { ids: string[]; deletedAt: string | null }) => {
    if (deletedAt) {
      return await client.api.sdModelTrainingControllerFullyDeleteModels({ trainingIds: ids }).then(res => res.data)
    }

    return await client.api.sdModelTrainingControllerSoftDeleteModels({ trainingIds: ids }).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { ids, deletedAt } }) => {
        const workspaceModelTrainingsKey = useWorkspaceModelTrainingInfiniteQuery.getKey()
        const workspaceModelTrainingsQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<SearchModelTrainingResults, number>
        >({
          queryKey: workspaceModelTrainingsKey,
        })
        if (workspaceModelTrainingsQueriesDataEntries) {
          workspaceModelTrainingsQueriesDataEntries.forEach(([key, queryData]) => {
            if (queryData) {
              const updatedData = {
                ...queryData,
                pages: queryData.pages.map(page => {
                  return {
                    ...page,
                    total: page.total - ids.length,
                    data: page.data.filter(modelTraining => !ids.includes(modelTraining.id)),
                  }
                }),
              }
              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
    }),
  ],
})
