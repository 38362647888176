import { WorkspaceDetail, WorkspaceRole, WorkspaceUser } from "@/api/sdk"
import SearchInput from "@/components/Explore/Gallery/GalleryHeader/SearchInput"
import IconButton from "@/components/IconButton"
import LoadingIcon from "@/components/LoadingIcon"
import Modal from "@/components/Modal"
import ShareUserItem from "@/components/Workspace/SharingModal/ShareUserItem"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import classNames from "classnames"
import { memo, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useSetting } from "../SettingProvider"
import { useInfo } from "./useInfo"

interface ChangeOwenerModalProps {
  dataMemberList: ReturnType<typeof useInfo>["members"]["mappedWorkspaceMembers"]
  fetchNextPage: ReturnType<typeof useInfo>["members"]["fetchNextPage"]
  hasNextPage: ReturnType<typeof useInfo>["members"]["hasNextPage"]
  isFetching: ReturnType<typeof useInfo>["members"]["isFetching"]
  isLoadingWorkspaceMembers: ReturnType<typeof useInfo>["members"]["isLoadingWorkspaceMembers"]
  dataWorkspace?: WorkspaceDetail
  isOpen: boolean
  setIsOpen: (v: boolean) => void
  mutateChangeOwner: ReturnType<typeof useInfo>["mutateChangeOwner"]
  isLoadingChangeOwner: boolean
  searchDebounce: string
  setSearchDebounce: (v: string) => void
  totalMember: number
}

const ChangeOwnerModal = ({
  dataMemberList,
  fetchNextPage,
  hasNextPage,
  isFetching,
  totalMember,
  isLoadingWorkspaceMembers,
  dataWorkspace,
  isOpen,
  setIsOpen,
  mutateChangeOwner,
  isLoadingChangeOwner,
  searchDebounce,
  setSearchDebounce,
}: ChangeOwenerModalProps) => {
  const { userInfoQuery } = useAuth()
  const [currentValue, setCurrentValue] = useState<WorkspaceUser | undefined>(undefined)
  const scrollRef = useRef<HTMLDivElement>(null)
  const { findMyWorkspace } = useSetting()

  return (
    <Modal title="Change workspace owner" isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <div className="relative flex-col flex min-h-[450px] ">
        <div className="flex items-center w-full mb-6">
          <div className="w-16 h-16 mr-3 overflow-hidden rounded-full">
            <img
              src={dataWorkspace?.photoUrl || userInfoQuery?.data?.picture}
              alt=""
              className="w-full h-full bg-center"
            />
          </div>
          <div className="flex flex-col overflow-hidden">
            <p className="font-semibold text-base truncate">{dataWorkspace?.name}</p>
            <p className="text-[rgba(255,255,255,0.5)] text-sm truncate">Team - {totalMember} member(s)</p>
          </div>
        </div>
        <p className="w-full text-atherGray-300 text-sm mb-6">
          You can only hand this workspace over to an existing member. Invite the person you&apos;d like to nominate.
        </p>
        <div className="flex items-center w-full px-2 py-1 mb-6 border h-11 border-atherGray-800 rounded-2xl">
          <SearchInput
            searchDebounce={searchDebounce}
            setSearchDebounce={setSearchDebounce}
            onFocus={() => {}}
            onClick={() => {}}
            onEnter={() => {}}
            placeholder="Search for a workspace member"
          />
        </div>
        <div
          ref={scrollRef}
          className="border border-atherGray-800 rounded-xl p-4 mb-6 h-[300px] 2xl:h-[400px] overflow-auto remove-scrollbar"
        >
          <div className="h-full">
            <InfiniteScroll
              loadMore={() => fetchNextPage()}
              hasMore={!!hasNextPage && !isFetching}
              useWindow={false}
              getScrollParent={() => scrollRef.current}
              loader={
                <div key="loader" className="flex items-center justify-center py-4">
                  Loading...
                </div>
              }
            >
              {isLoadingWorkspaceMembers ? (
                <div className="flex items-center justify-center w-full h-full">
                  <LoadingIcon width={20} height={20} />
                </div>
              ) : (
                <div className="space-y-1">
                  {dataMemberList
                    ?.filter(member => member.role != WorkspaceRole.Guest && member.role !== WorkspaceRole.Owner)
                    ?.map(_i => (
                      <ShareUserItem
                        className={classNames("p-2 cursor-pointer hover:bg-atherGray-850 rounded-xl", {
                          "bg-atherGray-800 hover:bg-atherGray-800": _i.user.uid === currentValue?.uid,
                        })}
                        user={{
                          ..._i.user,
                        }}
                        canShare={false}
                        key={_i.id}
                        onClick={() => setCurrentValue(_i.user)}
                      />
                    ))}
                </div>
              )}
            </InfiniteScroll>
          </div>
        </div>
        <div className="flex justify-end w-full space-x-2">
          <IconButton
            colorScheme="secondary"
            onClick={() => {
              setCurrentValue(undefined)
              setIsOpen(false)
            }}
          >
            <p className="font-semibold text-[14px] ">Cancel</p>
          </IconButton>
          <IconButton
            disabled={!currentValue && currentValue !== userInfoQuery?.data?.uid}
            isLoading={isLoadingChangeOwner}
            onClick={() => {
              if (currentValue) {
                mutateChangeOwner({ newOwnerUid: currentValue.uid, nameOwner: currentValue.name })

                googleAnalytics.event({
                  action: "click",
                  category: "settings_account",
                  label: `change workspace owner`,
                  params: {
                    user_id: userInfoQuery?.data?.uid ?? "",
                    user_username: userInfoQuery?.data?.username ?? "",
                    workspace_id: findMyWorkspace?.id ?? "",
                  },
                  value: 1,
                })
              }
            }}
          >
            Change owner
          </IconButton>
        </div>
      </div>
    </Modal>
  )
}

export default memo(ChangeOwnerModal)
