import IconButton from "@/components/IconButton"
import { LeaveIcon } from "@/components/shared/icons"
import classNames from "classnames"
import formatDistance from "date-fns/formatDistance"
import parseISO from "date-fns/parseISO"
import RawItem from "../shared/RawItem"
import SelectedModal from "../shared/SelectedModal"
import LeaveModal from "./LeaveModal"
import { useSharedWorkspace } from "./useSharedWorkspace"

const SharedWorkspace = () => {
  const {
    setSelectedBulkWorkspace,
    selectedBulkWorkspace,
    handleSelectedAll,
    findMySharedWorkspace,
    selectedWorkspaces,
    setSelectedWorkspaces,
    handleLeaveWorkspaces,
    isLoadingMutateLeaveWorkspace,
    isLoadingMutateLeaveWorkspaces,
  } = useSharedWorkspace()

  return (
    <>
      <LeaveModal
        selectedWorkspace={selectedWorkspaces}
        onClose={() => setSelectedWorkspaces(undefined)}
        handleLeaveWorkspaces={handleLeaveWorkspaces}
        isLoadingMutateLeaveWorkspace={isLoadingMutateLeaveWorkspace}
      />
      <SelectedModal
        selectedBulk={selectedBulkWorkspace}
        placeHolder="Leave"
        onAction={() => {
          if (selectedBulkWorkspace && selectedBulkWorkspace.length) {
            setSelectedWorkspaces(findMySharedWorkspace?.filter(_i => selectedBulkWorkspace.includes(_i.id)))
          }
        }}
        isLoading={isLoadingMutateLeaveWorkspaces}
        iconAction={<LeaveIcon color="#DD3A38" width={16} height={16} />}
      />
      <div className="relative w-full overflow-x-auto max-w-[calc(100vw-2rem)]">
        {findMySharedWorkspace?.length === 0 ? (
          <div className="flex justify-center items-center w-full h-[500px]">
            {/* <p className="text-xl text-atherGray-600 ">There was no shared workspace discovered.</p> */}
          </div>
        ) : (
          <table className="w-full text-sm text-left text-gray-500">
            <thead className="text-xs uppercase ">
              <tr className="border-b border-[#323230]">
                <th scope="col" className="px-6 pl-0 pr-3">
                  <div className="flex items-center ">
                    <label className="flex items-center container-checkbox">
                      <p className="font-semibold text-[#7E7D77] normal-case">Workspace</p>
                      <input
                        type="checkbox"
                        checked={selectedBulkWorkspace.length === findMySharedWorkspace?.length}
                        onChange={v => {
                          if (v.target.checked && selectedBulkWorkspace.length !== findMySharedWorkspace?.length) {
                            handleSelectedAll()
                          } else {
                            setSelectedBulkWorkspace([])
                          }
                        }}
                      />
                      <span
                        className={classNames("checkmark", {
                          "!border-0": selectedBulkWorkspace.length === findMySharedWorkspace?.length,
                        })}
                      >
                        {selectedBulkWorkspace.length === findMySharedWorkspace?.length && (
                          <div className="flex items-center justify-center w-full h-full scale-150">
                            <svg
                              width="8"
                              scale={2}
                              height="8"
                              viewBox="0 0 8 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.33594 4.16667C1.33594 4.16667 2.4026 4.77502 2.93594 5.66667C2.93594 5.66667 4.53594 2.16667 6.66927 1"
                                stroke="#FFF8EE"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        )}
                      </span>
                    </label>
                  </div>
                </th>
                <th scope="col" className="px-6 py-3">
                  <p className="font-semibold text-[#7E7D77] normal-case">Role</p>
                </th>
                <th scope="col" className="px-6 py-3">
                  <p className="font-semibold text-[#7E7D77] normal-case">Last login</p>
                </th>
                <th scope="col" className="px-6 py-3"></th>
              </tr>
            </thead>
            <tbody>
              {findMySharedWorkspace?.map((_i, _index) => (
                <RawItem
                  uid={_i.id}
                  name={_i.name}
                  username=""
                  email={""}
                  picture={_i.photoUrl}
                  role={_i.userWorkspace.role}
                  lastLogin={
                    _i.userWorkspace.isActive === true
                      ? "Active"
                      : formatDistance(parseISO(_i.userWorkspace.lastLoginAt), new Date(), { addSuffix: true })
                  }
                  key={_index + _i.id}
                  setSelectedBulk={setSelectedBulkWorkspace}
                  selectedBulk={selectedBulkWorkspace}
                  iconAction={
                    <IconButton colorScheme="transparent" className="p-0" onClick={() => setSelectedWorkspaces([_i])}>
                      <LeaveIcon width={20} height={20} className="text-red-500" />
                    </IconButton>
                  }
                />
              ))}
            </tbody>
          </table>
        )}
      </div>
    </>
  )
}

export default SharedWorkspace
