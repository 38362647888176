import { MenuSetting } from "@/constants"
import Info from "./Info"
import Members from "./Members"
import Privacy from "./Privacy"
import Profile from "./Profile"
import Security from "./Security"
import { useSetting } from "./SettingProvider"
import SharedWorkspace from "./SharedWorkspace"
import PlansUI from "./Plans"
import BillingUI from "./Billing"

const SettingContent = () => {
  const { currentTab } = useSetting()

  return (
    <>
      {currentTab === MenuSetting.about ? (
        <Profile />
      ) : currentTab === MenuSetting.security ? (
        <Security />
      ) : currentTab === MenuSetting.info ? (
        <Info />
      ) : currentTab === MenuSetting.members ? (
        <Members />
      ) : currentTab === MenuSetting["share-workspace"] ? (
        <SharedWorkspace />
      ) : currentTab === MenuSetting.privacy ? (
        <Privacy />
      ) : currentTab === MenuSetting.plans ? (
        <PlansUI />
      ) : currentTab === MenuSetting.billing ? (
        <BillingUI />
      ) : (
        <div></div>
      )}
    </>
  )
}

export default SettingContent
