import Navbar from "../Alpha/Navbar"
import Footer from "../Alpha/Footer"
// import FullViewImageScreen from "../ModalsPortal/FullViewImageScreen"
import DeleteItemModal from "../ModalsPortal/DeleteItemModal"
import ReferralModal from "../ModalsPortal/ReferralModal"
import SharePublicModal from "../ModalsPortal/SharePublicModal"
import BeMissUniverseModal from "../Alpha/VoteMissUniverse/BeMissUniverseModal"
// import SignInModalV2 from "../ModalsPortal/SignInModalV2"
// import SignUpModalV2 from "../ModalsPortal/SignUpModalV2"
import { withLazyProviders } from "./with-lazy-providers";

import dynamic from "next/dynamic"

const FullViewImageScreen = dynamic(() => import("../ModalsPortal/FullViewImageScreen"))
const SignInModalV2 = dynamic(() => import("../ModalsPortal/SignInModalV2"))
const SignUpModalV2 = dynamic(() => import("../ModalsPortal/SignUpModalV2"))


const AlphaLayout = ({
  children,
  hiddenNav,
  hiddenFooter,
}: {
  children: React.ReactNode
  hiddenNav?: boolean
  hiddenFooter?: boolean
}) => {
  return (
    <main id="layout" className="relative block min-h-screen dark:bg-[#000000] dark:text-[#EAE4D4] recipe-ai">
      <div className="flex flex-col min-h-screen">
        {!hiddenNav && <Navbar />}
        <div className="flex flex-col flex-1 text-[#EAE4D4]">{children}</div>
        {!hiddenFooter && <Footer />}
      </div>
      <FullViewImageScreen />
      <DeleteItemModal />
      <ReferralModal />
      <SharePublicModal />
      <BeMissUniverseModal />
      <SignInModalV2 />
      <SignUpModalV2 />
    </main>
  )
}

export default withLazyProviders(AlphaLayout)
