export const SchedulerIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M24 16V24L27 27" stroke="#D5D0C1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M39.0909 32.9068C42.3636 34.6741 44 35.5578 44 37C44 38.4422 42.3636 39.3259 39.0909 41.0932L36.8622 42.2967C34.3488 43.654 33.0921 44.3327 32.4879 43.8393C31.008 42.6308 33.3135 39.5123 33.8807 38.4075C34.4555 37.2879 34.4451 36.6918 33.8807 35.5925C33.3135 34.4877 31.008 31.3692 32.4879 30.1607C33.0921 29.6673 34.3488 30.346 36.8622 31.7033L39.0909 32.9068Z"
        fill="#9E74F3"
        stroke="#9E74F3"
        strokeWidth="3"
      />
      <path
        d="M26.0522 43.896C25.3775 43.9648 24.6929 44 24 44C12.9543 44 4 35.0457 4 24C4 12.9543 12.9543 4 24 4C35.0457 4 44 12.9543 44 24C44 25.3699 43.8623 26.7076 43.5999 28"
        stroke="#D5D0C1"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  )
}
