import Modal from "@/components/Modal"
import classNames from "classnames"
import { useSecurity } from "../useSecurity"
import IconButton from "@/components/IconButton"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "../../../../providers/authContext"

interface LogoutModalProps {
  stateModel: ReturnType<typeof useSecurity>["stateModel"]
  setStateModel: ReturnType<typeof useSecurity>["setStateModel"]
  isLoadingLogoutAll: ReturnType<typeof useSecurity>["isLoadingLogoutAll"]
  handleLogoutAll: ReturnType<typeof useSecurity>["handleLogoutAll"]
}

const LogoutModal = ({ stateModel, setStateModel, isLoadingLogoutAll, handleLogoutAll }: LogoutModalProps) => {
  const { userInfoQuery } = useAuth()
  return (
    <Modal
      isOpen={stateModel.logout}
      onClose={() =>
        setStateModel(v => ({
          ...v,
          logout: false,
        }))
      }
      title="Sign out from all devices"
      className="max-w-md"
    >
      <p className="text-atherGray-300 mb-6 text-sm">
        Are you sure? By clicking AGREE button, this will sign you out of all sessions and all devices.
      </p>
      <div className={classNames("flex justify-end w-full space-x-2")}>
        <IconButton
          colorScheme="secondary"
          onClick={() =>
            setStateModel(v => ({
              ...v,
              logout: false,
            }))
          }
        >
          Cancel
        </IconButton>
        <IconButton
          onClick={() => {
            handleLogoutAll()
            googleAnalytics.event({
              action: "click",
              category: "settings_account",
              label: `logout all devices`,
              params: {
                user_id: userInfoQuery?.data?.uid ?? "",
                user_username: userInfoQuery?.data?.username ?? "",
              },
              value: 1,
            })
          }}
          isLoading={isLoadingLogoutAll}
          disabled={isLoadingLogoutAll}
        >
          Agree
        </IconButton>
      </div>
    </Modal>
  )
}

export default LogoutModal
