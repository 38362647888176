import CreateFolderModalV2 from "./CreateFolderModalV2"
import CreateStyleModal from "./CreateStyleModal"
import DeleteItemModal from "./DeleteItemModal"
import LeaveFolderModal from "./LeaveFolderModal"
import MoveItemModalV2 from "./MoveItemModalV2"
import RecoverItemModal from "./RecoverItemModal"
import RenameModal from "./RenameModal"
import SendRecipeModal from "./SendRecipeModal"
import ShortcutKeyBoardModal from "./ShortcutKeyBoardModal"
import UpdateRecipeModal from "./UpdateRecipeModal"

import dynamic from "next/dynamic"

const FullViewImageScreen = dynamic(async () => import("./FullViewImageScreen"))
const SignInModalV2 = dynamic(() => import("./SignInModalV2"))
const SignUpModalV2 = dynamic(() => import("./SignUpModalV2"))
const LinkWalletAccountModal = dynamic(async () => (await import("./LinkWalletAccountModal")).default)
const LinkWalletAccountModalEvm = dynamic(async () => (await import("./LinkWalletAccountModalEvm")).default)
const PermissionGuestModal = dynamic(() => import("./PermissionGuestModal"))
const NewUserRewardModal = dynamic(() => import("./NewUserRewardModal"))
const ForceUserChangedModal = dynamic(() => import("./ForceUserChangedModal"))
const PromptsLibraryModal = dynamic(async () => import("./PromptInput/PromptsLibraryModal"))
const SharingModal = dynamic(() => import("../Workspace/SharingModal/SharingModal"))
const SelectPrevTaskPromptModal = dynamic(
  () => import("../Workspace/Workflows/WorkflowsChaining/SelectPrevTaskPromptModal"),
)
const SuggestPromptModal = dynamic(() => import("./PromptInput/SuggestPromptModal"))
const ReactivateAccountModal = dynamic(() => import("./ReactivateAccountModal"))
const ReferralModalV2 = dynamic(() => import("./ReferralModalV2"))

interface ModalsPortalProps {}

const ModalsPortal = ({}: ModalsPortalProps) => {
  return (
    <>
      <SuggestPromptModal />
      <SelectPrevTaskPromptModal />
      <PromptsLibraryModal />
      <RenameModal />
      <SendRecipeModal />
      <FullViewImageScreen />
      <RecoverItemModal />
      <DeleteItemModal />
      <LeaveFolderModal />
      <CreateFolderModalV2 />
      <SharingModal />
      <ReferralModalV2 />
      <UpdateRecipeModal />
      <CreateStyleModal />
      <MoveItemModalV2 />
      <PermissionGuestModal />
      <LinkWalletAccountModal />
      <LinkWalletAccountModalEvm />
      <ReactivateAccountModal />
      <ShortcutKeyBoardModal />
      <NewUserRewardModal />
      <ForceUserChangedModal />
      <SignInModalV2 />
      <SignUpModalV2 />
    </>
  )
}

export default ModalsPortal
