import IconButton from "@/components/IconButton"
import LoadingLogo from "@/components/LoadingLogo"
import { useAuth } from "@/providers/authContext"
import { useGetBillingsQuery } from "@/queries"
import format from "date-fns/format"
import React from "react"
import Papa from "papaparse"
import { FileExportIcon } from "@/components/shared/icons"
import { PaymentMethod } from "@/api/sdk"
import { currency } from "@/utils/currency"

const ListBillings = () => {
  const { userInfoQuery } = useAuth()

  const { data: billings, isLoading } = useGetBillingsQuery({
    variables: {
      workspaceId: userInfoQuery?.data?.currentWorkspace?.id ?? "",
    },
  })

  if (isLoading) {
    return (
      <div className="flex items-center justify-center h-full flex-1 w-full">
        <LoadingLogo />
      </div>
    )
  }

  return (
    <div className="flex-1 flex flex-col">
      <div className="flex items-center justify-between">
        <p className="font-semibold">Billings</p>
        <IconButton
          isLoading={isLoading}
          onClick={() => {
            const data =
              billings?.orders.map(order => ({
                invoice: order.invoice?.id,
                date: order.createdAt ? format(new Date(order.createdAt), "MMM dd, yyyy") : "",
                amount: order.invoice
                  ? order.invoice?.payment?.["paymentMethod"] === PaymentMethod.CRYPTO
                    ? `${currency(parseFloat(order.invoice?.payment?.["amount"]) / 10 ** 6, {
                        maximumFractionDigits: 5,
                      })} ${order.invoice?.payment?.["currencyCode"]}`
                    : Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: order.invoice?.payment?.["currencyCode"] ?? "USD",
                      }).format(parseFloat(order.invoice?.payment?.["amount"]))
                  : order.totalAmount,
                paymentMethod: order.paymentMethod,
                plan: order.productDetail.name,
              })) ?? []

            const csv = Papa.unparse(data)

            const blob = new Blob([csv], { type: "text/csv" })
            const url = URL.createObjectURL(blob)
            const a = document.createElement("a")
            a.href = url
            a.download = `${"billings"}.csv`
            a.click()
          }}
          className="p-2"
          leftIcon={<FileExportIcon />}
        >
          Export
        </IconButton>
      </div>
      <div className="mt-4 flex-1">
        <table className="w-full border-separate border-spacing-y-2">
          <thead>
            <tr className="text-atherGray-500 text-xs font-semibold">
              <th className="py-2 px-4 text-left">INVOICE</th>
              <th className="py-2 px-4 text-left">DATE</th>
              <th className="py-2 px-4 text-left">AMOUNT</th>
              <th className="py-2 px-4 text-left">PAYMENT METHOD</th>
              <th className="py-2 px-4 text-left">PLAN</th>
            </tr>
          </thead>
          <tbody>
            {billings?.orders.map(order => (
              <tr key={order.id} className="w-full text-atherGray-100 text-sm bg-atherGray-900 rounded-2xl">
                <td className="p-4 text-left rounded-s-2xl">{order.invoice?.id}</td>
                <td className="p-4 text-left">
                  {order.createdAt ? format(new Date(order.createdAt), "MMM dd, yyyy") : ""}
                </td>
                <td className="p-4 text-left">
                  {order.invoice
                    ? order.invoice?.payment?.["paymentMethod"] === PaymentMethod.CRYPTO
                      ? `${currency(parseFloat(order.invoice?.payment?.["amount"]) / 10 ** 6, {
                          maximumFractionDigits: 5,
                        })} ${order.invoice?.payment?.["currencyCode"]}`
                      : Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: order.invoice?.payment?.["currencyCode"] ?? "USD",
                        }).format(parseFloat(order.invoice?.payment?.["amount"]))
                    : order.totalAmount}
                </td>
                <td className="p-4 text-left">{order.paymentMethod}</td>
                <td className="p-4 text-left rounded-e-2xl">{order.productDetail.name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default ListBillings
