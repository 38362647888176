import client from "@/api/client"
import { useAuthenticatedQuery, useBaseInfinityQuery, useToast } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import { useInfiniteQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import { useRef, useState } from "react"
import { useSetting } from "../SettingProvider"
import { useUserWorkspaceDetailQuery, useWorkspaceMembersInfiniteQuery } from "@/queries"
import { WorkspaceRole } from "@/api/sdk"
import { useWorkspaceContext } from "@/providers/WorkspaceProvider"

const useInfo = () => {
  const { tokenFormat } = useAuth()
  const [isOpenModal, setIsOpenModal] = useState(false)

  const [error, setError] = useState<Record<string, undefined | string>>({
    name: undefined,
    description: undefined,
  })
  const [activeEdit, setActiveEdit] = useState({
    name: false,
    description: false,
  })
  const [isLoading, setIsLoading] = useState<Record<string, boolean>>()
  const nameOwner = useRef("")
  const { user } = useAuth()
  const {
    currentWorkspaceQuery: { data: dataUserWorkspaceById },
  } = useWorkspaceContext()
  const [searchDebounce, setSearchDebounce] = useState("")

  const qc = useQueryClient()
  const { findMyWorkspace } = useSetting()
  const toast = useToast({
    position: "top-center",
  })

  const { mutate } = useMutation({
    mutationKey: ["patch-user-workspace"],
    mutationFn: (data: { name?: string; description?: string; photoId?: string }) =>
      client.api.workspaceControllerUpdateWorkspac(findMyWorkspace?.id ?? "", data),
    onMutate(variables) {
      const firstValue = Object.keys(variables)[0]
      if (firstValue)
        setIsLoading(v => ({
          ...v,
          [`${firstValue}`]: true,
        }))
    },
    onError(error: any, variables) {
      const firstValue = Object.keys(variables)[0]

      if (firstValue === "photoId") {
        toast({
          status: "error",
          title: error.message,
        })
      }

      if (firstValue) {
        setError(v => ({
          ...v,
          [`${firstValue}`]: error.message,
        }))
      }
    },
    onSuccess(data, variables) {
      const firstValue = Object.keys(variables)[0]

      if (firstValue) {
        setError(v => ({
          ...v,
          [`${firstValue}`]: undefined,
        }))
        setActiveEdit({
          ...activeEdit,
          [`${firstValue}`]: false,
        })
      }

      toast({
        status: "success",
        title: "Updated your workspace",
      })
    },
    onSettled(data, error, variables, context) {
      qc.invalidateQueries({ queryKey: ["user-workspaces-profile"] })
      const firstValue = Object.keys(variables)[0]

      if (firstValue) {
        setIsLoading(v => ({
          ...v,
          [`${firstValue}`]: false,
        }))
      }
    },
  })

  const mutateUpdateUserWorkspace = (data: { name?: string; description?: string; photoId?: string }) => {
    if (findMyWorkspace?.id) mutate(data)
    else {
      toast({
        status: "error",
        title: "WorkspaceID not found: " + findMyWorkspace?.id,
      })
    }
  }

  const {
    flattenData: dataWorkspaceMembers = [],
    isLoading: isLoadingWorkspaceMembers,
    isError,
    total,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useWorkspaceMembersInfiniteQuery({
    variables: {
      workspaceId: findMyWorkspace?.id ?? "",
      searchTerm: searchDebounce,
      roles: [WorkspaceRole.Member],
    },
  })

  const { mutate: mutateOwner, isPending: isLoadingChangeOwner } = useMutation({
    mutationKey: ["change-owner", findMyWorkspace?.id],
    mutationFn: (data: { newOwnerUid: string }) =>
      client.api
        .workspaceControllerTransferWorkspace(findMyWorkspace?.id ?? "", { newOwnerUid: data.newOwnerUid })
        .then(res => res.data),
    onSuccess() {
      toast({
        title: ``,
        closeButton: false,
        element: (
          <div className="py-2 px-4 rounded-xl bg-[#5E17EB]">
            <p className="text-[12px] text-[400] text-[#EFE8FD]">
              You have successfully transfer <span className="font-semibold">{findMyWorkspace?.name}</span> to{" "}
              <span className="font-semibold">{nameOwner.current}</span>
            </p>
          </div>
        ),
        style: { backgroundColor: "transparent" },
      })
      setIsOpenModal(false)
      window.location.href = "/workspace"
      // qc.invalidateQueries({ queryKey: ["user-workspaces-profile"] })
    },
    onError: (err: Error) => {
      toast({
        status: "error",
        title: err?.message,
      })
    },
  })

  const mutateChangeOwner = (data: { newOwnerUid: string; nameOwner: string }) => {
    if (data.newOwnerUid === user?.uid) {
      toast({
        status: "error",
        title: "Currently, you are the workspace's owner.",
        closeButton: true,
      })
      return
    }
    if (findMyWorkspace?.id) {
      mutateOwner({ newOwnerUid: data.newOwnerUid })
      nameOwner.current = data.nameOwner
    } else {
      toast({
        status: "error",
        title: "WorkspaceID not found: " + findMyWorkspace?.id,
      })
    }
  }

  return {
    members: {
      mappedWorkspaceMembers: dataWorkspaceMembers,
      isLoadingWorkspaceMembers,
      hasNextPage,
      fetchNextPage,
      isFetching,
      isError,
      total,
    },
    mutateUpdateUserWorkspace,
    mutateChangeOwner,
    error,
    setError,
    activeEdit,
    setActiveEdit,
    isLoading,
    isLoadingChangeOwner,
    dataUserWorkspaceById,
    searchDebounce,
    setSearchDebounce,
    isOpenModal,
    setIsOpenModal,
  }
}

export { useInfo }
