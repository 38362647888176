import { tipsAndTricksFolder } from "@/constants"
import { useScreen } from "@/hooks"
import classNames from "classnames"
import Link from "next/link"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useState } from "react"
import ModalPortalExplore from "../ModalsPortal/ModalPortalExplore"
import CommonNavbar from "../common/CommonNavbar"
import MobileNavigation from "../common/MobileNavigation"
import { ArrowRightIcon, OpenNewTabIcon } from "../shared/icons"
import { menuCommonPages } from "./CommonLayout"
import { withLazyProviders } from "./with-lazy-providers"

const TipsAndTricksLayout = ({ children, hiddenMenu }: { children: React.ReactNode; hiddenMenu?: boolean }) => {
  const router = useCustomRouter()
  const [isOpenSidebar, setIsOpenSidebar] = useState(false)
  const { width } = useScreen()

  return (
    <main id="layout" className="relative block min-h-screen dark:bg-black dark:text-atherGray-0">
      <div className="flex flex-row min-h-screen">
        <div className={classNames("flex-1 flex items-center flex-col pb-[5.15rem] md:pb-0")}>
          <CommonNavbar navItems={menuCommonPages} />
          <div className="flex-row justify-center w-full flex-1 px-4 lg:px-6 flex">
            {!hiddenMenu && (
              <div
                className={classNames(
                  "fixed  hidden lg:block mr-8 bg-black top-0 lg:sticky overflow-hidden lg:top-[5.5rem] left-0 height-full-screen z-[24] lg:h-[calc((var(--vh,1vh)*100)-5.5rem)]",
                  {
                    "w-[75vw] md:w-64 p-4 lg:px-0 lg:py-6": isOpenSidebar,
                    "w-0 lg:w-64 lg:px-0 lg:py-6": !isOpenSidebar,
                  },
                )}
              >
                <div className="flex flex-col">
                  {tipsAndTricksFolder.map((item, index) => (
                    <Link
                      shallow
                      scroll={false}
                      href={item.path}
                      key={index}
                      onClick={() => {
                        if (width <= 1024) setIsOpenSidebar(false)
                      }}
                      target={item.id.includes("promptbook") ? "_blank" : "_self"}
                      className={classNames("flex items-center justify-between py-2 px-4 rounded-2xl font-semibold", {
                        "bg-atherGray-850": item.path === router.pathname,
                        "text-atherGray-300": item.path !== router.pathname,
                      })}
                    >
                      {item.name}
                      <div className="ml-2">
                        {item.id.includes("promptbook") ? (
                          <OpenNewTabIcon width={14} height={14} />
                        ) : (
                          <ArrowRightIcon width={14} height={14} />
                        )}
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            )}

            {children}
          </div>
        </div>
      </div>
      <MobileNavigation />
      <ModalPortalExplore />
    </main>
  )
}

export default withLazyProviders(TipsAndTricksLayout)
