import { SearchIcon, XIcon } from "@/components/shared/icons/"
import { useCallbackOnHotKeys, useScreen } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import classNames from "classnames"
import useCustomRouter from "@/hooks/useCustomRouter"
import React, { Fragment, memo, useEffect, useRef, useState } from "react"
import { twMerge } from "tailwind-merge"

interface SearchInputProps {
  onFocus: () => void
  onClick: () => void
  onEnter: () => void
  searchDebounce: string
  setSearchDebounce: (query: string) => void
  className?: string
  placeholder?: string
  onClear?: () => void
}

const SearchInput = ({
  searchDebounce,
  onClick,
  onClear,
  onEnter,
  onFocus,
  setSearchDebounce,
  className,
  placeholder = "Search",
}: SearchInputProps) => {
  const [searchValue, setSearchValue] = useState("")
  const router = useCustomRouter()
  const { search } = router.query

  const inputRef = useRef<HTMLInputElement>(null)
  const { width } = useScreen()
  const isMobile = width < 768
  const [isFocused, setIsFocused] = useState(false)

  useCallbackOnHotKeys(
    "f",
    () => {
      if (document.getElementsByClassName("modal").length > 0) return

      if (isMobile) {
        inputRef.current?.focus()
      } else {
        inputRef.current?.focus()
      }
    },
    !isFocused,
  )

  useEffect(() => {
    if (searchDebounce) {
      setSearchValue(searchDebounce)
    }
  }, [searchDebounce])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSearchDebounce(searchValue)

      googleAnalytics.event({
        action: "search",
        category: "gallery",
        label: searchValue,
        value: 1,
      })
    }, 300)

    return () => clearTimeout(timeoutId)
  }, [searchValue, setSearchDebounce])

  useEffect(() => {
    if (router.isReady && search) {
      if (search === searchValue) return

      setSearchValue(search as string)
    }
  }, [router.isReady, search])

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }

  const handleEnter = () => {
    if (searchValue) {
      setSearchValue("")
      onClear?.()
      return
    }

    onEnter()
  }

  return (
    <Fragment>
      <input
        required
        ref={inputRef}
        onFocus={onFocus}
        onClick={onClick}
        onKeyUp={e => {
          if (e.key === "Enter") {
            e.currentTarget.blur()
          }
        }}
        onKeyDown={e => {
          if (e.key === "Enter") {
            onEnter()
          }
        }}
        onFocusCapture={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        className={twMerge(
          classNames(
            "bg-transparent w-full pl-2 md:pl-0 md:mx-2 outline-none text-atherGray-0 placeholder:text-atherGray-500 text-sm flex-1",
          ),
          className,
        )}
        placeholder={placeholder}
        value={searchValue}
        onChange={onChange}
      />
      <div
        onClick={() => {
          handleEnter()
        }}
        className={twMerge(classNames("md:hidden w-10 sm:w-auto justify-center items-center rounded-md flex"))}
      >
        {searchValue ? <XIcon /> : <SearchIcon className="text-atherGray-300" />}
      </div>
      <button className="hidden px-2 md:block" type="button" onClick={() => handleEnter()}>
        <div className="flex">
          <div className="text-xs mr-1 flex items-center justify-center">
            {searchValue ? (
              <span className="px-1">
                <XIcon width={12} height={12} />
              </span>
            ) : (
              <span className="bg-atherGray-800 border font-semibold border-atherGray-700 px-1">F</span>
            )}
          </div>
          <SearchIcon width={20} height={20} />
        </div>
      </button>
    </Fragment>
  )
}

export default memo(SearchInput)
