import { useEffect } from "react"
import ModalsPortal from "../ModalsPortal"
import SidebarDrawerMobile from "../Workspace/Sidebar/SidebarDrawerMobile"
import CommonNavbar from "../common/CommonNavbar"
import MobileNavigation from "../common/MobileNavigation"
import { menuCommonPages } from "./CommonLayout"
import { withLazyProviders } from "./with-lazy-providers"
import SafeAreaView from "@/providers/SafeAreaView"

const RoomLayout = ({
  children,
  hiddenNavigation,
  hiddenNavbar,
}: {
  children: React.ReactNode
  hiddenNavigation?: boolean
  hiddenNavbar?: boolean
}) => {
  useEffect(() => {
    const body = document.querySelector("body")

    if (body) {
      body.classList.add("overflow-hidden")
    }

    return () => {
      if (body) {
        body.classList.remove("overflow-hidden")
      }
    }
  }, [])

  return (
    <SafeAreaView
      as="main"
      id="layout"
      className="relative h-screen overflow-hidden flex flex-col w-full dark:bg-black dark:text-atherGray-0"
    >
      <div id="layout-inside" className="flex-col flex-1 flex relative w-full overflow-hidden">
        {!hiddenNavbar && (
          <CommonNavbar navItems={menuCommonPages} containerClassName="border-b border-atherGray-800" />
        )}
        <div className={"flex-1 flex flex-col overflow-hidden"}>
          {children}
          {!hiddenNavigation && (
            <>
              <MobileNavigation />
              <SidebarDrawerMobile />
            </>
          )}
        </div>
      </div>

      <ModalsPortal />
    </SafeAreaView>
  )
}

export default withLazyProviders(RoomLayout)
