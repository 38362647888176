import { SortOrder, WorkspaceMembersSortByOptions, WorkspaceRole } from "@/api/sdk"
import SearchInput from "@/components/Explore/Gallery/GalleryHeader/SearchInput"
import IconButton from "@/components/IconButton"
import { FilterIcon, SortIcon } from "@/components/shared/icons"
import { useOutsideClick } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { capitalize } from "@/utils"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { Dispatch, SetStateAction, useRef, useState } from "react"
import { FiltersProps } from "./useMembers"

interface FilterBarProps {
  searchDebounce: string
  setSearchDebounce: (v: string) => void
  filters: FiltersProps
  setFilters: Dispatch<SetStateAction<FiltersProps>>
}

const sortOptions = [
  {
    title: "Name: A-Z",
    value: {
      sortBy: WorkspaceMembersSortByOptions.NAME,
      sortOrder: SortOrder.ASC,
    },
  },
  {
    title: "Name: Z-A",
    value: {
      sortBy: WorkspaceMembersSortByOptions.NAME,
      sortOrder: SortOrder.DESC,
    },
  },
  {
    title: "Login: Newest",
    value: {
      sortBy: WorkspaceMembersSortByOptions.LOGIN_TIME,
      sortOrder: SortOrder.DESC,
    },
  },
  {
    title: "Login: Oldest",
    value: {
      sortBy: WorkspaceMembersSortByOptions.LOGIN_TIME,
      sortOrder: SortOrder.ASC,
    },
  },
]

const filterOptions = [
  {
    title: "Owner",
    value: WorkspaceRole.Owner,
  },
  {
    title: "Admin",
    value: WorkspaceRole.Admin,
  },
  {
    title: "Member",
    value: WorkspaceRole.Member,
  },
]

const FilterBar = ({ searchDebounce, setSearchDebounce, filters, setFilters }: FilterBarProps) => {
  const [open, setOpen] = useState<"Filter" | "Sort" | null>(null)

  const popupRef = useRef<HTMLDivElement>(null)
  useOutsideClick({
    enabled: !!open,
    ref: popupRef,
    handler: () => setOpen(null),
  })

  const renderFilter = () => {
    return (
      <div className="flex-1 px-2 py-4 w-[12rem]  ">
        <div className="flex items-center px-2">
          <h3 className="flex-1 mb-2 text-xs font-semibold">Filter</h3>
          <p
            onClick={() => {
              setFilters((v: FiltersProps) => ({
                ...v,
                filter: [],
              }))
            }}
            className={classNames("cursor-pointer text-xs text-red-500 font-semibold", {
              hidden: !filters.filter || filters.filter?.length === 0,
            })}
          >
            Clear
          </p>
        </div>
        <div className="w-full flex flex-wrap max-h-[14rem] lg:max-h-[16rem] 2xl:max-h-[20rem]">
          {filterOptions.map(f => (
            <div
              key={f.title}
              className="flex items-center m-0.5 cursor-pointer"
              onClick={() => {
                const findIndex = filters.filter?.findIndex(i => i === f.value)
                if (findIndex === -1) {
                  const temp = filters.filter
                  temp?.push(f.value)
                  setFilters((v: FiltersProps) => ({
                    ...v,
                    filter: temp,
                  }))
                } else {
                  const temp = filters.filter
                  temp?.splice(findIndex, 1)
                  setFilters((v: FiltersProps) => ({
                    ...v,
                    filter: temp,
                  }))
                }
              }}
            >
              <IconButton
                colorScheme="secondary"
                title={f.title}
                className={classNames(
                  "text-xs bg-atherGray-900 min-h-0 border-atherGray-800 border text-atherGray-300 rounded-2xl py-0.5 px-2 font-normal",
                  {
                    "bg-atherPurple-500 hover:bg-atherPurple-500 text-atherGray-0": filters.filter.includes(f.value),
                  },
                )}
              >
                {capitalize(f.title.toLowerCase())}
              </IconButton>
            </div>
          ))}
        </div>
      </div>
    )
  }
  const renderSort = () => {
    return (
      <div className="flex-1 p-2 w-[11rem]">
        <div className="space-y-2 text-sm">
          {sortOptions.map(s => (
            <IconButton
              key={s.title}
              onClick={() => {
                googleAnalytics.event({
                  action: "click",
                  category: "navbar",
                  value: 1,
                })

                if (filters.sort?.title === s.title) {
                  setFilters({
                    ...filters,
                    sort: undefined,
                  })
                  return
                }

                setFilters({
                  ...filters,
                  sort: s,
                })
              }}
              title="Sort by"
              className={classNames(
                "flex items-center text-left hover:bg-atherGray-700 bg-transparent w-full cursor-pointer rounded-xl",
                {
                  "bg-atherGray-800 text-atherGray-0": filters.sort?.title === s.title,
                  "text-atherGray-300": filters.sort?.title !== s.title,
                },
              )}
            >
              <p className="flex-1 ml-2">{capitalize(s.title.toLowerCase())}</p>
            </IconButton>
          ))}
        </div>
      </div>
    )
  }

  return (
    <div className="relative flex space-x-2">
      <div className="flex flex-1 w-full h-10 max-w-full px-0 py-2 border md:max-w-xs md:relative border-atherGray-800 sm:px-2 rounded-2xl">
        <SearchInput
          placeholder="Search names"
          searchDebounce={searchDebounce}
          setSearchDebounce={setSearchDebounce}
          onFocus={() => {}}
          onClick={() => {}}
          onEnter={() => {}}
        />
      </div>
      <div ref={popupRef}>
        <div
          className={classNames("flex space-x-0 md:space-x-0", {
            // hidden: isSearch,
          })}
        >
          <IconButton
            title="Filter"
            colorScheme="transparent"
            className={classNames("flex items-center font-semibold text-sm p-2")}
            onClick={() => {
              setOpen(prev => (prev === "Filter" ? null : "Filter"))
            }}
          >
            <FilterIcon
              className={classNames("text-xl", {
                "text-atherGray-0": open === "Filter",
                "text-atherGray-300": open !== "Filter",
              })}
            />
          </IconButton>
          <IconButton
            colorScheme="transparent"
            title="Sort"
            className={classNames("flex items-center font-semibold text-sm")}
            onClick={() => {
              setOpen(prev => (prev === "Sort" ? null : "Sort"))
            }}
          >
            <SortIcon
              className={classNames("text-xl", {
                "text-atherGray-0": open === "Sort",
                "text-atherGray-300": open !== "Sort",
              })}
            />
          </IconButton>
        </div>
        <AnimatePresence>
          {!!open && (
            <motion.div
              key={open}
              initial={{ opacity: 0.5, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0.5, height: 0 }}
              className="absolute top-full mt-1 overflow-hidden right-2 z-[20] shadow-lg"
            >
              <div className="flex flex-col w-full border rounded-2xl bg-atherGray-900 border-atherGray-800 md:flex-row">
                {open === "Filter" ? renderFilter() : open === "Sort" ? renderSort() : null}
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  )
}

export default FilterBar
