import React from "react"

export const RedditIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M10.75 13.04C10.75 12.47 10.28 12 9.70998 12C9.13998 12 8.66998 12.47 8.66998 13.04C8.66998 13.3158 8.77955 13.5804 8.97459 13.7754C9.16963 13.9704 9.43416 14.08 9.70998 14.08C9.98581 14.08 10.2503 13.9704 10.4454 13.7754C10.6404 13.5804 10.75 13.3158 10.75 13.04ZM14.09 15.41C13.64 15.86 12.68 16.02 12 16.02C11.32 16.02 10.36 15.86 9.90998 15.41C9.88566 15.384 9.85624 15.3632 9.82355 15.349C9.79087 15.3348 9.75562 15.3275 9.71998 15.3275C9.68435 15.3275 9.64909 15.3348 9.61641 15.349C9.58373 15.3632 9.55431 15.384 9.52998 15.41C9.50394 15.4343 9.48318 15.4637 9.46899 15.4964C9.45479 15.5291 9.44746 15.5644 9.44746 15.6C9.44746 15.6356 9.45479 15.6709 9.46899 15.7036C9.48318 15.7363 9.50394 15.7657 9.52998 15.79C10.24 16.5 11.6 16.56 12 16.56C12.4 16.56 13.76 16.5 14.47 15.79C14.496 15.7657 14.5168 15.7363 14.531 15.7036C14.5452 15.6709 14.5525 15.6356 14.5525 15.6C14.5525 15.5644 14.5452 15.5291 14.531 15.4964C14.5168 15.4637 14.496 15.4343 14.47 15.41C14.37 15.31 14.2 15.31 14.09 15.41ZM14.29 12C13.72 12 13.25 12.47 13.25 13.04C13.25 13.61 13.72 14.08 14.29 14.08C14.86 14.08 15.33 13.61 15.33 13.04C15.33 12.47 14.87 12 14.29 12Z"
        fill={"currentColor"}
      />
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM17.8 13.33C17.82 13.47 17.83 13.62 17.83 13.77C17.83 16.01 15.22 17.83 12 17.83C8.78 17.83 6.17 16.01 6.17 13.77C6.17 13.62 6.18 13.47 6.2 13.33C5.69 13.1 5.34 12.59 5.34 12C5.33852 11.7132 5.4218 11.4324 5.57939 11.1929C5.73698 10.9533 5.96185 10.7656 6.22576 10.6534C6.48966 10.5413 6.78083 10.5096 7.06269 10.5623C7.34456 10.6151 7.60454 10.75 7.81 10.95C8.82 10.22 10.22 9.76 11.77 9.71L12.51 6.22C12.52 6.15 12.56 6.09 12.62 6.06C12.68 6.02 12.75 6.01 12.82 6.02L15.24 6.54C15.3221 6.37358 15.4472 6.23215 15.6023 6.13038C15.7575 6.02861 15.9371 5.9702 16.1224 5.96122C16.3077 5.95224 16.4921 5.99301 16.6564 6.07931C16.8207 6.1656 16.9589 6.29428 17.0566 6.45199C17.1544 6.60969 17.2082 6.79069 17.2125 6.9762C17.2167 7.16171 17.1712 7.34498 17.0808 7.507C16.9903 7.66901 16.8582 7.80388 16.698 7.8976C16.5379 7.99132 16.3556 8.04049 16.17 8.04C15.61 8.04 15.16 7.6 15.13 7.05L12.96 6.59L12.3 9.71C13.83 9.76 15.2 10.23 16.2 10.95C16.3533 10.8036 16.5367 10.6925 16.7375 10.6244C16.9382 10.5563 17.1514 10.5329 17.3621 10.5558C17.5728 10.5787 17.776 10.6474 17.9574 10.757C18.1388 10.8667 18.2941 11.0146 18.4123 11.1905C18.5306 11.3664 18.609 11.5661 18.642 11.7754C18.6751 11.9848 18.662 12.1989 18.6037 12.4027C18.5454 12.6064 18.4432 12.795 18.3044 12.9552C18.1656 13.1154 17.9934 13.2433 17.8 13.33Z"
        fill={"currentColor"}
      />
    </svg>
  )
}
