import { ShareByWorkspaceMode, SharingRole } from "@/api/sdk"
import { ArrowDownIcon, GlobalIcon, LockIcon } from "@/components/shared/icons"
import { googleAnalytics } from "@/lib/gtag"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import React, { Fragment, useState } from "react"
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share"
import { cdnPublicFolderUrl } from "@/constants"
import Popover from "@/components/Popover"

export const SocialButtons = ({ urlImageDetails }: { urlImageDetails: string }) => {
  const handleSocialEvent = (social: string) => {
    googleAnalytics.event({
      action: "click",
      category: "modal",
      label: `share_${social}_${urlImageDetails}`,
      value: 1,
    })
  }

  return (
    <Fragment>
      <FacebookShareButton onClick={e => handleSocialEvent("facebook")} url={urlImageDetails}>
        <img className="h-8" src={`${cdnPublicFolderUrl}/images/alpha/fb.png`} alt="FB" />
      </FacebookShareButton>
      <TwitterShareButton onClick={e => handleSocialEvent("twitter")} url={urlImageDetails}>
        <img className="h-8" src={`${cdnPublicFolderUrl}/images/alpha/tw.png`} alt="FB" />
      </TwitterShareButton>
      <LinkedinShareButton
        onClick={e => handleSocialEvent("linkedin")}
        url={urlImageDetails}
        className="bg-red-400 p-4"
      >
        <img className="h-8" src={`${cdnPublicFolderUrl}/images/alpha/li.png`} alt="FB" />
      </LinkedinShareButton>
    </Fragment>
  )
}

interface AccessLinkProps {
  canShare?: boolean
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  onSharingModeChange: (sharingMode: ShareByWorkspaceMode) => void
  isLoading?: boolean
  value: string
  isDisabledPublic?: boolean
  workspaceRole: SharingRole | undefined
  onWorkspaceRoleChange: (role: SharingRole) => void
  workspaceRoleOptions?: {
    label: string
    value: string
  }[]
}

const AccessLink = ({
  isOpen,
  canShare,
  setIsOpen,
  onSharingModeChange,
  value,
  workspaceRole,
  onWorkspaceRoleChange,
  workspaceRoleOptions,
  isLoading,
}: AccessLinkProps) => {
  const [isOpenRole, setIsOpenRole] = useState(false)

  const description = value === "restricted" ? "Only invited people can access" : "Anyone in the workspace can access"

  return (
    <div
      className={classNames({
        "opacity-40 cursor-not-allowed": isLoading,
      })}
    >
      <p className="mb-2 text-xs font-semibold">General permission:</p>
      <div className={cn("flex items-center")}>
        <div className="flex items-start flex-1">
          <div className="bg-atherGray-700 p-2 rounded-full mt-1">
            {value !== "restricted" ? <GlobalIcon width={16} height={16} /> : <LockIcon width={16} height={16} />}
          </div>
          <div className="ml-1 mr-2">
            {canShare ? (
              <Popover
                align="start"
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                trigger={
                  <button
                    type="button"
                    className="relative flex items-center cursor-pointer py-0.5 px-1 rounded-md hover:bg-whiteAlpha-50 active:bg-whiteAlpha-100"
                  >
                    <p className="font-semibold capitalize text-sm">{value}</p>
                    <span className="ml-2 text-atherGray-500">
                      <ArrowDownIcon width={12} height={12} />
                    </span>
                  </button>
                }
              >
                <div className="flex flex-col space-y-2 bg-atherGray-900 border border-atherGray-850 rounded-2xl p-2 text-atherGray-0">
                  <button
                    className={cn(
                      "hover:bg-atherGray-850 hover:text-atherGray-0 font-semibold rounded-2xl text-atherGray-300 px-4 py-2.5 text-sm w-full text-left",
                      {
                        "text-atherGray-0 bg-atherGray-850": value === ShareByWorkspaceMode.Restricted,
                      },
                    )}
                    onClick={() => {
                      if (value === ShareByWorkspaceMode.Restricted) return
                      onSharingModeChange(ShareByWorkspaceMode.Restricted)
                    }}
                  >
                    Restricted
                  </button>
                  <button
                    type="button"
                    className="hover:bg-atherGray-850 hover:text-atherGray-0 font-semibold rounded-2xl text-atherGray-300 px-4 py-2.5 text-sm text-left"
                    onClick={() => {
                      if (value === ShareByWorkspaceMode.Workspace) return
                      onSharingModeChange(ShareByWorkspaceMode.Workspace)
                    }}
                  >
                    Everyone in the workspace
                  </button>
                </div>
              </Popover>
            ) : (
              <p className="relative flex items-center cursor-not-allowed py-0.5 px-1 rounded-md">
                <p className="font-semibold capitalize text-sm">{value}</p>
              </p>
            )}

            <p className="text-xs text-atherGray-300 font-normal ml-1">{description}</p>
          </div>
        </div>
        {workspaceRole &&
          (canShare ? (
            <Popover
              isOpen={isOpenRole}
              setIsOpen={setIsOpenRole}
              trigger={
                <button
                  className={cn(
                    "flex items-center py-0.5 px-1 hover:bg-atherGray-850 rounded-md cursor-pointer select-none",
                    {
                      "bg-atherGray-850": isOpen,
                    },
                  )}
                >
                  <span className="mr-1 text-xs font-normal capitalize">{workspaceRole}</span>
                  <span className="text-atherGray-500">
                    <ArrowDownIcon width={12} height={12} />
                  </span>
                </button>
              }
            >
              <div className="bg-atherGray-900 rounded-2xl p-2 border border-atherGray-850 space-y-2 w-48">
                {workspaceRoleOptions?.map(r => (
                  <button
                    key={r.value}
                    onClick={e => {
                      e.stopPropagation()
                      onWorkspaceRoleChange && onWorkspaceRoleChange(r.value as SharingRole)
                      setIsOpenRole(false)
                    }}
                    className={cn(
                      "group hover:bg-atherGray-850 rounded-2xl text-left px-4 py-2 text-atherGray-0 w-full",
                      {
                        "bg-atherGray-850": r.value === workspaceRole,
                      },
                    )}
                  >
                    <span
                      className={cn("text-sm capitalize text-atherGray-300 group-hover:text-atherGray-0", {
                        "text-atherGray-0": r.value === workspaceRole,
                      })}
                    >
                      {r.label}
                    </span>
                  </button>
                ))}
              </div>
            </Popover>
          ) : (
            <button
              className={cn("flex items-center py-0.5 px-1 rounded-md cursor-pointer select-none", {
                "bg-atherGray-850": isOpen,
              })}
            >
              <span className="text-xs font-normal capitalize">{workspaceRole}</span>
            </button>
          ))}
      </div>
    </div>
  )
}

export default AccessLink
