import { SubfolderView } from "@/api/sdk"
import { MyFoldersIcon, SharedWithMeIcon } from "@/components/shared/icons"
import { cn } from "@/utils/cn"
import { useEffect, useRef, useState } from "react"

export const tabs = [
  {
    label: "My folders",
    icon: <MyFoldersIcon width={16} height={16} />,
    view: SubfolderView.MyFolder,
  },
  {
    label: "Shared with me",
    icon: <SharedWithMeIcon width={16} height={16} />,
    view: SubfolderView.SharedWithMe,
  },
]

interface FolderTabsProps {
  selectedView: SubfolderView
  onSelectView: (view: SubfolderView) => void
}

const FolderTabs = ({ selectedView, onSelectView }: FolderTabsProps) => {
  const [[indicatorLeft, indicatorWidth], setIndicatorPosition] = useState([0, 0])

  const tabListRef = useRef<HTMLDivElement>(null)
  const tabRefs = useRef<Partial<Record<string, HTMLDivElement>>>({})

  useEffect(() => {
    const tabList = tabListRef.current!
    const selectedTab = tabRefs.current[selectedView]!

    if (!tabList || !selectedTab) return

    const tabListRect = tabList.getBoundingClientRect()
    const selectedTabRect = selectedTab?.getBoundingClientRect()

    const indicatorLeft = selectedTabRect.left - tabListRect.left
    const indicatorWidth = selectedTabRect.width

    setIndicatorPosition([indicatorLeft, indicatorWidth])
  }, [selectedView])

  return (
    <div className="flex border-b border-atherGray-850 relative" role="tablist" ref={tabListRef}>
      {tabs.map(tab => (
        <div
          key={tab.view}
          className={cn(
            "flex flex-1 items-center justify-center space-x-2 py-2.5 pb-3 font-semibold text-sm cursor-pointer text-atherGray-300 hover:text-atherGray-0 group",
            {
              "text-atherGray-0": tab.view === selectedView,
            },
          )}
          role="tab"
          onClick={() => onSelectView(tab.view)}
          ref={ref => {
            tabRefs.current[tab.view] = ref as HTMLDivElement
          }}
        >
          <span
            className={cn("text-atherGray-500 group-hover:text-atherGray-0", {
              "text-atherGray-0": tab.view === selectedView,
            })}
          >
            {tab.icon}
          </span>
          <span>{tab.label}</span>
        </div>
      ))}
      <div
        className="absolute bg-atherPurple-500 h-1 bottom-0 transition-all duration-300"
        style={{ left: indicatorLeft, width: indicatorWidth }}
      />
    </div>
  )
}

export default FolderTabs
