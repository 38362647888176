import { useAuth } from "@/providers/authContext"
import { useSetting } from "../SettingProvider"
import AfterDeleteAccountModal from "./modal/AfterDeleteAccountModal"
import ChangePasswordModal from "./modal/ChangePasswordModal"
import DeletetAccountModal from "./modal/DeleteAccoutModal"
import EnterCodeModal from "./modal/EnterCodeModal"
import LogoutModal from "./modal/LogoutModal"
import { useSecurity } from "./useSecurity"
import BackupData from "./BackupData"
import DeleteAccount from "./DeleteAccount"
import SignOutAllDevice from "./SignOutAllDevice"
import SetupPassword from "./SetupPassword"

const Security = () => {
  const {
    setStateModel,
    stateModel,
    isLoadingLogoutAll,
    handleLogoutAll,
    handleSendCode,
    isLoadingSendCode,
    stateValue,
    setStateValue,
    handleComplete,
    isLoadingComplete,
  } = useSecurity()

  const { user } = useAuth()
  const { currentWorkspaceDetail } = useSetting()

  return (
    <>
      <div className="flex flex-col max-w-[34rem]">
        <SetupPassword
          user={user}
          onSetPassword={() =>
            setStateModel(v => ({
              ...v,
              password: true,
            }))
          }
        />
        <hr className="my-4 border-atherGray-800" />
        <SignOutAllDevice
          onSignOutAllDevice={() =>
            setStateModel(v => ({
              ...v,
              logout: true,
            }))
          }
        />
        <hr className="my-4 border-atherGray-800" />
        <BackupData workspaceDetail={currentWorkspaceDetail} />
        <DeleteAccount
          onDelete={() =>
            setStateModel(v => ({
              ...v,
              delete: {
                ...v.delete,
                step1: true,
              },
            }))
          }
        />
      </div>

      <ChangePasswordModal
        isOpen={stateModel.password}
        onClose={() =>
          setStateModel(v => ({
            ...v,
            password: false,
          }))
        }
      />
      <LogoutModal
        stateModel={stateModel}
        setStateModel={setStateModel}
        isLoadingLogoutAll={isLoadingLogoutAll}
        handleLogoutAll={handleLogoutAll}
      />
      <DeletetAccountModal
        setValue={setStateValue}
        value={stateValue.reasonValue}
        stateModel={stateModel}
        setStateModel={setStateModel}
      />
      <EnterCodeModal
        setValue={setStateValue}
        value={stateValue.passcode}
        stateModel={stateModel}
        setStateModel={setStateModel}
        handleSendCode={handleSendCode}
        isLoadingSendCode={isLoadingSendCode}
        handleComplete={handleComplete}
        isLoadingComplete={isLoadingComplete}
      />

      <AfterDeleteAccountModal
        stateModel={stateModel}
        setStateModel={setStateModel}
        handleLogoutAll={handleLogoutAll}
      />
    </>
  )
}

export default Security
