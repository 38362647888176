import Input from "@/components/Input"
import Modal from "@/components/Modal"
import React, { useState } from "react"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import PasscodeInput from "../Security/PasscodeInput"
import IconButton from "@/components/IconButton"
import { useMutation } from "@tanstack/react-query"
import client from "@/api/client"
import { useToast } from "@/hooks"
import { ConfirmChangeEmailCodeRequestDto } from "@/api/sdk"
import { useAuth } from "@/providers/authContext"
import { googleAnalytics } from "@/lib/gtag"
import { isEmailAddress, isTelegramEmail } from "@/utils/is-email-address"

interface ChangeEmailModalProps {
  isOpen: boolean
  onClose: () => void
}

type ChangeEmailForm = {
  email: string
  code: string
}

const ChangeEmailModal = ({ isOpen, onClose }: ChangeEmailModalProps) => {
  const form = useForm<ChangeEmailForm>({
    mode: "onSubmit",
  })
  const toast = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const { user, userInfoQuery } = useAuth()

  const { email, code } = useWatch({
    control: form.control,
  })

  const {
    clearErrors,
    register,
    formState: { errors },
    setValue,
    getValues,
    reset,
    setError,
  } = form

  const handleClose = () => {
    clearErrors()
    reset()
    onClose()
  }

  const isSendToTelegram =
    isTelegramEmail(user?.email ?? "") &&
    userInfoQuery?.data?.telegram?.["id"] &&
    userInfoQuery?.data?.telegram?.["allowSendPM"] === true

  const { mutate: mutateSendChangeEmail, isPending: isSending } = useMutation({
    mutationFn: (email: string) =>
      client.api.authControllerRequestChangeEmail({
        email,
      }),
    onSuccess: () => {
      toast({
        status: "success",
        title: "Success",
        message: ["Passcode sent"],
      })
    },
    onError: (error: any) => {
      toast({
        status: "error",
        title: "Error",
        message: [error?.message ?? "Error sending passcode confirmation"],
      })
    },
  })

  const { mutate: mutateConfirm } = useMutation({
    mutationFn: ({ code }: { code: string }) =>
      client.api
        .authControllerConfirmChangeEmail({
          code,
        })
        .then(res => res.data),
    onSuccess: async data => {
      if (data.success) {
        setIsLoading(false)
        toast({
          status: "success",
          title: "Success",
          message: ["Please check your email to verify the change"],
        })
        handleClose()
      }
    },
    onError: (error: any) => {
      setIsLoading(false)
      toast({
        status: "error",
        title: "Error",
        message: [error?.message ?? "Error sending email confirmation"],
      })
    },
  })

  const handleResendPasscode = async e => {
    e.preventDefault()
    e.stopPropagation()

    if (!email) {
      toast({
        status: "error",
        title: "Error",
        message: ["Email is required"],
      })

      return
    }

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "profile",
        action: "Send code",
        user_name: userInfoQuery?.data?.name ?? "",
        user_id: userInfoQuery?.data?.username ?? "",
      },
    })

    try {
      mutateSendChangeEmail(email)
    } catch (error) {
      console.error("Error sending email confirmation", error)
    }
  }

  const submit = async e => {
    e.preventDefault()
    clearErrors()

    const data = getValues()
    let isValidate = true

    //regex email

    if (!isEmailAddress(data.email)) {
      setError("email", {
        message: "Invalid email address",
      })
      isValidate = false
    }

    if (!isValidate) return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "profile",
        action: "Change email",
        user_name: userInfoQuery?.data?.name ?? "",
        user_username: userInfoQuery?.data?.username ?? "",
        user_email: data.email,
        user_id: userInfoQuery?.data?.uid ?? "",
      },
    })

    setIsLoading(true)
    await mutateConfirm({
      code: data.code,
    })
  }

  const isDisabled = !code || code.length < 6 || !email

  return (
    <Modal isOpen={isOpen} onClose={handleClose} title="Change email address" className="max-w-md">
      <p className="text-atherGray-300 mb-4">
        You cannot change your email address again for 60 days after the change is saved.
      </p>
      <p className="text-atherGray-300 mb-4">This change will also affect Google sign-in.</p>
      <FormProvider {...form}>
        <form onSubmit={submit}>
          <div className="space-y-4 w-full">
            <div>
              <p className="mb-1 text-atherGray-300">Current email address</p>
              <Input
                value={user?.email ?? userInfoQuery?.data?.email ?? ""}
                disabled
                className="text-atherGray-500 rounded-lg bg-atherGray-850"
              />
            </div>
            <div>
              <p className="mb-1 text-atherGray-300">New email address</p>
              <Input
                {...register("email")}
                placeholder="Enter email"
                className="placeholder:text-atherGray-500 text-atherGray-0 rounded-lg"
              />
              {errors.email && <p className="text-[0.65rem] mt-1 leading-4 text-red-500">{errors.email.message}</p>}
            </div>
            <div className="min-h-[64px]">
              <p className="text-xs text-atherGray-500">
                {isSendToTelegram
                  ? "Because you are signing with Telegram. Please check telegram bot @Proto_GAIA and enter the 6-digit code we sent to you."
                  : "Enter the 6-digit code we sent to your email"}
              </p>
              <div className="flex flex-col h-full md:flex-row items-center">
                <PasscodeInput
                  value={code}
                  onChange={v => setValue("code", v)}
                  className="w-8 h-8 text-xl text-atherGray-0 bg-atherGray-900 rounded-none border-atherGray-800 border-b-[2px]"
                />
                <div className="flex items-center justify-center h-10 w-[7rem] mt-4 md:mt-0 md:ml-4 cursor-pointer">
                  <IconButton
                    isLoading={isSending}
                    colorScheme="transparent"
                    className="text-atherPurple-300 p-0 bg-atherGray-900 text-sm font-semibold"
                    onClick={handleResendPasscode}
                  >
                    Send Code
                  </IconButton>
                </div>
              </div>
              {errors.code && <p className="text-[0.65rem] mt-1 leading-4 text-red-500">{errors.code.message}</p>}
            </div>
            <p className="text-sm italic text-atherGray-300">
              **After entering your Passcode, we&apos;ll send a verification notification to your new email address.
              Please check and verify to complete this change request.
            </p>
            <div className="flex items-center justify-end space-x-2">
              <IconButton colorScheme="secondary" onClick={handleClose}>
                Cancel
              </IconButton>
              <IconButton isLoading={isLoading} disabled={isDisabled} type="submit">
                Save
              </IconButton>
            </div>
          </div>
        </form>
      </FormProvider>
    </Modal>
  )
}

export default ChangeEmailModal
