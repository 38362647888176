import client from "@/api/client"
import { useToast } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import { useMutation } from "@tanstack/react-query"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useState } from "react"

const useSecurity = () => {
  const [stateModel, setStateModel] = useState({
    logout: false,
    password: false,
    delete: {
      step1: false,
      step2: false,
      step3: false,
    },
  })
  const toast = useToast({
    position: "top-center",
  })

  const [stateValue, setStateValue] = useState({
    reasonValue: "",
    passcode: "",
  })

  const { bearerToken, logout } = useAuth()
  const { mutate, isPending: isLoadingLogoutAll } = useMutation({
    mutationKey: ["logout-all"],
    mutationFn: () => client.api.userControllerSignOutFromAllDevices(),
    onSettled: () => {
      setStateModel(v => ({
        ...v,
        logout: false,
      }))
    },
  })

  const handleLogoutAll = () => {
    if (!bearerToken) {
      toast({
        status: "error",
        title: "Something went wrong",
      })
      return
    }
    mutate()
    logout()
  }

  const { mutate: mutateSendCode, isPending: isLoadingSendCode } = useMutation({
    mutationKey: ["sendcode-delete-account", bearerToken],
    mutationFn: () => client.api.userControllerSendAccountDeletionCode(),
    onSuccess: () => {
      toast({
        status: "success",
        title: "The passcode had been sent to your email",
      })
    },
    onError(error: Error) {
      toast({
        status: "error",
        title: error.message,
      })
    },
  })

  const handleSendCode = () => {
    if (!bearerToken) {
      toast({
        status: "error",
        title: "Something went wrong",
      })
      return
    }
    mutateSendCode()
  }

  const { mutate: mutateComplete, isPending: isLoadingComplete } = useMutation({
    mutationKey: ["complete-delete-account", bearerToken],
    mutationFn: ({ reason, code }: { reason: string; code: string }) =>
      client.api.userControllerRequestDeleteAccount({
        reason,
        code,
      }),
    onSuccess: () => {
      setStateModel(v => ({
        ...v,
        delete: {
          step1: false,
          step2: false,
          step3: true,
        },
      }))
    },
    onError: error => {
      toast({
        status: "error",
        title: error.message,
      })
    },
  })

  const handleComplete = () => {
    if (!bearerToken || stateValue.passcode === "") {
      toast({
        status: "error",
        title: "Something went wrong",
      })
      return
    }

    mutateComplete({
      reason: stateValue.reasonValue,
      code: stateValue.passcode,
    })
  }

  return {
    stateModel,
    setStateModel,
    handleLogoutAll,
    isLoadingLogoutAll,
    isLoadingSendCode,
    stateValue,
    setStateValue,
    handleComplete,
    handleSendCode,
    isLoadingComplete,
  }
}

export { useSecurity }
