import CommonLayout from "./CommonLayout"
import OtherLayout from "./OtherLayout"
import SettingLayout from "./SettingLayout"
import AlphaLayout from "./AlphaLayout"
import WorkspaceLayout from "./WorkspaceLayout"
import DetailLayout from "./DetailLayout"
import ProfileLayout from "./ProfileLayout"
import TipsAndTricksLayout from "./TipsAndTricksLayout"
import RoomLayout from "./RoomLayout"

export {
  CommonLayout,
  OtherLayout,
  SettingLayout,
  TipsAndTricksLayout,
  ProfileLayout,
  AlphaLayout as DraftLayout,
  DetailLayout,
  WorkspaceLayout,
  RoomLayout,
}
