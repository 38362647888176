import AppLogo from "../Workspace/Sidebar/AppLogo"
import SidebarDrawerMobile from "../Workspace/Sidebar/SidebarDrawerMobile"
import MobileNavigation from "../common/MobileNavigation"
import { withLazyProviders } from "./with-lazy-providers";

const OtherLayout = ({ children }: { children: React.ReactNode }) => {
  return (
    <main id="layout" className="relative flex flex-col min-h-screen dark:bg-atherGray-900 dark:text-whiteAlpha-900">
      <div className="sticky top-0 bg-atherGray-900 z-10 left-0 w-full flex justify-start">
        <AppLogo className="h-[4rem] w-auto px-2 md:px-6" />
      </div>
      <div className="flex-1 flex items-center flex-col bg-atherGray-900 pb-[5.15rem] md:pb-0">{children}</div>
      <MobileNavigation />
      <SidebarDrawerMobile className="block md:hidden" />
    </main>
  )
}

export default withLazyProviders(OtherLayout)
