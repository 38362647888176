import { FileAssociatedResource } from "@/api/sdk"
import HiddenFileInput from "@/components/HiddenFileInput"
import IconButton from "@/components/IconButton"
import LoadingIcon from "@/components/LoadingIcon"
import { WorkspaceImage } from "@/components/Workspace/Sidebar/WorkspaceSelector"
import { LeaveIcon, Trash } from "@/components/shared/icons"
import { useFileUpload, useToast } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import getCroppedImg, { resizeImage } from "@/utils/resize-image"
import classNames from "classnames"
import { useCallback, useRef, useState } from "react"
import InputField from "../Profile/InputField"
import { useSetting } from "../SettingProvider"
import CropImageModal from "../shared/CropImageModal"
import ChangeOwnerModal from "./ChangeOwnerModal"
import { useInfo } from "./useInfo"
import { handleConvertHEICtoJPG } from "@/utils/convert"

const Info = () => {
  const { mutateAsync: mutateUpload } = useFileUpload()
  const { findMyWorkspace } = useSetting()

  //image crop
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const { userInfoQuery } = useAuth()

  const toast = useToast()

  const [preview, setPreview] = useState<string | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const {
    isOpenModal,
    setIsOpenModal,
    activeEdit,
    setActiveEdit,
    setError,
    isLoading,
    error,
    mutateUpdateUserWorkspace,
    members,
    dataUserWorkspaceById,
    mutateChangeOwner,
    isLoadingChangeOwner,
    searchDebounce,
    setSearchDebounce,
  } = useInfo()

  const { mappedWorkspaceMembers, fetchNextPage, hasNextPage, isFetching, isLoadingWorkspaceMembers } = members

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(preview, croppedAreaPixels)
      if (!croppedImage) return
      handleChangeWorkspacePhoto(croppedImage.file)
    } catch (error) {}

    setPreview(null)
  }, [croppedAreaPixels])

  const handleUploadFromDevice = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleChangeWorkspacePhoto = async (_file: File) => {
    if (!_file) return

    let newFile = _file

    if (newFile.type === "image/heic" || newFile.name.endsWith(".heic")) {
      newFile = (await handleConvertHEICtoJPG(newFile)) as File
    }

    newFile = await resizeImage(newFile, 80)
    const res = await mutateUpload({
      resourceType: FileAssociatedResource.WORKSPACE,
      files: [newFile],
      onProgress: () => {},
      onError: () => {
        toast({
          status: "error",
          title: "Error",
          message: ["Failed to upload image"],
        })
      },
    })

    if (res[0].id) {
      mutateUpdateUserWorkspace({
        photoId: res[0].id,
      })
      googleAnalytics.event({
        action: "upload",
        category: "settings_account",
        label: `workspaces's avatar`,
        params: {
          user_id: userInfoQuery?.data?.uid ?? "",
          user_username: userInfoQuery?.data?.username ?? "",
          workspace_id: findMyWorkspace?.id ?? "",
        },
        value: 1,
      })
    }
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  return (
    <>
      <ChangeOwnerModal
        totalMember={members.total}
        dataWorkspace={dataUserWorkspaceById}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetching={isFetching}
        dataMemberList={mappedWorkspaceMembers}
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        mutateChangeOwner={mutateChangeOwner}
        isLoadingChangeOwner={isLoadingChangeOwner}
        setSearchDebounce={setSearchDebounce}
        searchDebounce={searchDebounce}
        isLoadingWorkspaceMembers={isLoadingWorkspaceMembers}
      />
      <CropImageModal
        preview={preview}
        crop={crop}
        zoom={zoom}
        setZoom={setZoom}
        setCrop={setCrop}
        onCropComplete={onCropComplete}
        setPreview={setPreview}
        showCroppedImage={showCroppedImage}
      />
      <div className="flex flex-col">
        {!!findMyWorkspace && (
          <>
            <p className="font-semibold text-[#FFFBEE] mb-4">Workspace photo</p>
            <div className="flex items-center mb-6">
              <div className="rounded-full group overflow-hidden w-[68px] h-[68px] mr-6  relative">
                {isLoading?.photoId && (
                  <div
                    className={classNames("absolute inset-0 z-20 items-center justify-center bg-black flex opacity-60")}
                  >
                    <LoadingIcon width={20} height={20} />
                  </div>
                )}

                <WorkspaceImage
                  className="w-full h-full"
                  itemClassName="w-full h-full"
                  name={findMyWorkspace?.name ?? userInfoQuery?.data?.name ?? ""}
                  photoUrl={findMyWorkspace?.photoUrl ?? userInfoQuery?.data?.picture ?? ""}
                />
              </div>
              <div
                className={classNames("mr-6 cursor-pointer flex items-center space-x-2 pointer-events-auto")}
                // onClick={() => handleChangeWorkspacePhoto()}
                onClick={() => {
                  handleUploadFromDevice()
                }}
              >
                <div className="p-[9px] rounded-full bg-[#5E17EB]">
                  <div className="-rotate-90">
                    <LeaveIcon stroke="2" color="#EFE8FD" />
                  </div>
                </div>
                <p className="text-sm text-atherGray-300">Upload</p>

                <HiddenFileInput
                  multiple={false}
                  ref={fileInputRef}
                  onUpload={async files => {
                    if (files.length > 0) {
                      if (preview && files[0]) {
                        URL.revokeObjectURL(preview)
                      }

                      let newFile = files[0]

                      if (newFile.type === "image/heic" || newFile.name.endsWith(".heic")) {
                        newFile = (await handleConvertHEICtoJPG(newFile)) as File
                      }

                      newFile = await resizeImage(newFile)
                      setPreview(URL.createObjectURL(newFile))
                    }
                  }}
                />
              </div>
              <div
                className="mr-6 cursor-pointer"
                onClick={() => {
                  mutateUpdateUserWorkspace({
                    photoId: undefined,
                  })
                  googleAnalytics.event({
                    action: "upload",
                    category: "settings_account",
                    label: `del workspace's avatar`,
                    params: {
                      user_id: userInfoQuery?.data?.uid ?? "",
                      user_username: userInfoQuery?.data?.username ?? "",
                      workspace_id: findMyWorkspace.id ?? "",
                    },
                    value: 1,
                  })
                }}
              >
                <Trash />
              </div>
            </div>
            <div className="flex flex-col max-w-[516px]">
              <InputField
                title="Name"
                defaultValue={findMyWorkspace.name ?? ""}
                isActive={activeEdit.name}
                setActiveEdit={v => {
                  setActiveEdit({
                    ...activeEdit,
                    name: v,
                  })
                }}
                isLoading={isLoading?.name}
                onSubmit={v => {
                  if (!v) {
                    setError(v => ({
                      ...v,
                      name: "Invalid name",
                    }))
                    return
                  }
                  if (v === findMyWorkspace.name ?? "") {
                    setError(v => ({
                      ...v,
                      name: "The name is the same as the one from earlier.",
                    }))
                    return
                  }
                  mutateUpdateUserWorkspace({
                    name: v,
                  })

                  googleAnalytics.event({
                    action: "click",
                    category: "settings_account",
                    label: `edit workspace's name`,
                    params: {
                      user_id: userInfoQuery?.data?.uid ?? "",
                      user_username: userInfoQuery?.data?.username ?? "",
                      workspace_id: findMyWorkspace.id ?? "",
                    },
                    value: 1,
                  })
                }}
                onClickClose={() => {
                  setError(v => ({
                    ...v,
                    name: "",
                  }))
                }}
                css="mb-6"
                error={error.name}
              />

              <InputField
                title="Description"
                defaultValue={findMyWorkspace?.description}
                isActive={activeEdit.description}
                setActiveEdit={v =>
                  setActiveEdit({
                    ...activeEdit,
                    description: v,
                  })
                }
                isLoading={isLoading?.description}
                onSubmit={v => {
                  mutateUpdateUserWorkspace({
                    description: v,
                  })
                  googleAnalytics.event({
                    action: "click",
                    category: "settings_account",
                    label: `edit workspace's description`,
                    params: {
                      user_id: userInfoQuery?.data?.uid ?? "",
                      user_username: userInfoQuery?.data?.username ?? "",
                      workspace_id: findMyWorkspace.id ?? "",
                    },
                    value: 1,
                  })
                }}
                onClickClose={() => {
                  setError(v => ({
                    ...v,
                    description: "",
                  }))
                }}
                css="mb-6"
                error={error.description}
              />

              <div className="w-full">
                <p className="mb-1 font-semibold">Change team owner</p>
                <p className="text-[#C8C4B5] text-[14px] font-[400] leading-[17.5px]">
                  Remove yourself as the current owner, and nominate another member to replace you. Note: There can only
                  be one owner per team.
                </p>
                <IconButton
                  colorScheme="secondary"
                  className="mt-4 py-2 px-3 w-fit rounded-lg cursor-pointer"
                  onClick={() => setIsOpenModal(true)}
                >
                  <p className="font-semibold text-[#FFF8EE]">Change owner</p>
                </IconButton>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Info
