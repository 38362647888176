import IconButton from "@/components/IconButton"
import { useScreen } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { useState } from "react"
import { MdClose } from "react-icons/md"
import { RemoveScroll } from "react-remove-scroll"
import { boolean } from "yup"
import SettingSidebar from "../SettingSidebar"
interface DrawerSidebarProps {
  isOpen: boolean
  setIsOpen: (v: boolean) => void
}

const DrawerSidebar = ({ isOpen, setIsOpen }: DrawerSidebarProps) => {
  const { user } = useAuth()
  const { width } = useScreen()

  if (!user) return null

  return (
    <AnimatePresence mode="wait">
      {isOpen && (
        <>
          <motion.div
            initial={{ width: 0 }}
            animate={{ width: "auto" }}
            exit={{ width: 0 }}
            transition={{
              ease: "easeInOut",
            }}
            className={classNames(
              "fixed top-0 lg:top-[5.5rem] left-0 bg-atherGray-900 lg:sticky z-[24] overflow-hidden h-[calc(var(--vh,1vh)*100)] lg:height-full-screen",
            )}
          >
            <RemoveScroll
              enabled={width < 768 && isOpen}
              style={{
                animationDelay: "0s",
                height: "100%",
              }}
              removeScrollBar
            >
              <IconButton
                colorScheme="secondary"
                className="absolute top-2 px-2 right-2 z-[24] flex lg:hidden"
                onClick={() => setIsOpen(false)}
              >
                <MdClose className="w-5 h-5" />
              </IconButton>
              <SettingSidebar onClose={() => setIsOpen(false)} className="top-0 w-[85vw] md:w-[19.5rem]" />
            </RemoveScroll>
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { duration: 0.2 },
            }}
            className={classNames("fixed lg:hidden inset-0 bg-blackAlpha-600 z-[23]")}
            onClick={() => setIsOpen(false)}
          />
        </>
      )}
    </AnimatePresence>
  )
}

export default DrawerSidebar
