import { BackupRequestStatus, WorkspaceDetail } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import { FileIcon } from "@/components/shared/icons"
import { useToast } from "@/hooks"
import useCustomRouter from "@/hooks/useCustomRouter"
import { useGetBackupStatusQuery, useRequestBackupMutation } from "@/queries"
import { format } from "date-fns"
import React from "react"

interface BackupDataProps {
  workspaceDetail?: WorkspaceDetail
}

const BackupData = ({ workspaceDetail }: BackupDataProps) => {
  const { openNewTab } = useCustomRouter()
  const toast = useToast()

  const { mutate: mutateRequestBackup, isPending: isMutatingRequest } = useRequestBackupMutation()
  const { data: backupData } = useGetBackupStatusQuery({
    refetchOnWindowFocus: true,
    enabled: !!workspaceDetail && workspaceDetail.subscriptionPackageId !== "free",
  })

  const renderBackupUIComplete = () => {
    if (!backupData) return null

    return (
      <div>
        <div
          onClick={() => {
            setTimeout(() => {
              if (!backupData?.downloadUrl) return

              openNewTab(backupData?.downloadUrl)
            }, 1)
          }}
          className="flex items-center mb-2 cursor-pointer space-x-2 text-sm"
        >
          <FileIcon width={16} height={16} />
          <p className="border-b border-atherPurple-500">backup-file</p>
        </div>
        <p className="text-[0.65rem] text-atherGray-100">
          Your backup data file will be expired on{" "}
          {backupData.expireAt ? format(new Date(backupData.expireAt), "dd MMM yyyy") : ""}. Please download soon.
        </p>
      </div>
    )
  }

  if (!workspaceDetail || workspaceDetail?.subscriptionPackageId === "free") return null

  return (
    <>
      <div className="flex flex-col">
        <p className="text-[#FFF8EE] font-semibold mb-3">Backup data</p>
        <p className="text-atherGray-300 text-sm mb-3">
          You can request to download your data (uploads and designs) on GAIA every 24hrs.
        </p>
        {backupData && backupData.status === BackupRequestStatus.Available ? (
          renderBackupUIComplete()
        ) : (
          <IconButton
            colorScheme="secondary"
            className="p-2 rounded-lg max-w-[8rem]"
            isLoading={isMutatingRequest || backupData?.status === BackupRequestStatus.Processing}
            onClick={() =>
              mutateRequestBackup(
                {},
                {
                  onError: err => {
                    toast({
                      title: "Backup failed",
                      message: [err.message],
                      status: "error",
                    })
                  },
                },
              )
            }
          >
            Backup data
          </IconButton>
        )}
      </div>
      <hr className="my-4 border-atherGray-800" />
    </>
  )
}

export default BackupData
