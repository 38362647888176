import { useReferralStore } from "@/stores"
import IconButton from "../IconButton"
import Modal from "../Modal"
import { useAuth } from "@/providers/authContext"
import { clientUrl } from "@/constants"
import { useTempState } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { AnimatePresence, motion } from "framer-motion"
import { FaCopy, FaCheck } from "react-icons/fa"
import { SocialButtons } from "../Workspace/SharingModal/AccessLink"

const ReferralModal = () => {
  const { user } = useAuth()

  const { referralModal, setReferralModal } = useReferralStore()
  const [isCopying, setIsCopying] = useTempState(false, 500)
  const link = `${clientUrl}/ai-recipes?referralCode=${user?.uid}`

  const handleCopy = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    e.preventDefault()
    setIsCopying(true)

    googleAnalytics.event({
      action: "click",
      category: "referral",
      label: "copy_referral_link",
      params: {
        referral_link: link,
      },
    })

    navigator.clipboard.writeText(link)
  }

  return (
    <Modal
      title={"Referral"}
      className="overflow-visible max-w-md text-[#EAE4D4]"
      isOpen={referralModal}
      onClose={() => setReferralModal(false)}
    >
      <p className="text-sm mb-2 text-[#EAE4D4]">
        Invite your friends to join us and earn 5 CREDITS for every successful referral
      </p>
      <p className="text-sm mb-2 text-[#858175]">Referral link</p>
      <div className="border border-[#313131] rounded-md p-2 flex items-center overflow-hidden mb-4">
        <p className="truncate mr-4 text-[#EAE4D4]">{link}</p>
        <AnimatePresence initial={false} mode="popLayout">
          {!isCopying ? (
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="btn-copy"
              onClick={e => handleCopy(e)}
            >
              <FaCopy size="0.8rem" className="fill-atherGray-400" />
            </motion.button>
          ) : (
            <motion.button initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <FaCheck key="btn-check" size="0.8rem" className="fill-green-500" />
            </motion.button>
          )}
        </AnimatePresence>
      </div>
      <div>
        <p className="text-sm text-[#858175] mb-2">Share via</p>
        <div className="flex space-x-4">
          <SocialButtons urlImageDetails={link} />
        </div>
      </div>
      <div className="flex items-center justify-end mt-6 space-x-2">
        <IconButton onClick={() => setReferralModal(false)} className="px-4 font-semibold">
          Cancel
        </IconButton>
      </div>
    </Modal>
  )
}

export default ReferralModal
