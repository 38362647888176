import client from "@/api/client"
import IconButton from "@/components/IconButton"
import Modal from "@/components/Modal"
import { useToast } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { useQueryClient } from "@tanstack/react-query"
import { getAuth } from "firebase/auth"
import React from "react"

interface RemoveTelegramModalProps {
  isOpen: boolean
  onClose: () => void
}

const RemoveTelegramModal = ({ isOpen, onClose }: RemoveTelegramModalProps) => {
  const toast = useToast()
  const qc = useQueryClient()
  const { user, userInfoQuery } = useAuth()

  const handleAgree = async () => {
    try {
      const auth = getAuth()

      if (auth.currentUser && auth.currentUser.email) {
        googleAnalytics.handleCategoryEvent({
          action: "click",
          params: {
            tab_name: "profile",
            action: "Remove telegram account",
            user_name: userInfoQuery?.data?.name ?? "",
            user_username: userInfoQuery?.data?.username ?? "",
            user_id: userInfoQuery?.data?.uid ?? "",
            user_email: auth.currentUser.email ?? "",
          },
        })
      }

      await client.api.userControllerUnlinkTelegram()
      qc.invalidateQueries({ queryKey: ["user-info", user?.uid] })
      toast({
        status: "success",
        title: "Remove social account successfully",
        message: ["Your GAIA account has been unlinked with your Telegram account"],
      })

      onClose()
    } catch (error: any) {
      console.error(error)
      toast({
        status: "error",
        title: "Remove social account failed",
        message: [error?.message ?? "Something went wrong, please try again"],
      })
    }
  }

  return (
    <Modal className="max-w-md" title="Remove your Social account" isOpen={isOpen} onClose={onClose}>
      <p className="text-atherGray-300">
        By clicking <span className="text-atherGray-0 font-semibold">Agree</span> button, your GAIA account will be
        unlinked with your <span className="text-atherGray-0 font-semibold">Telegram account</span>
      </p>
      <div className="mt-6 flex items-center space-x-2 justify-end">
        <IconButton onClick={onClose} colorScheme="secondary">
          Cancel
        </IconButton>
        <IconButton onClick={() => handleAgree()}>Agree</IconButton>
      </div>
    </Modal>
  )
}

export default RemoveTelegramModal
