import { useToast } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useRecoverItemStore } from "@/stores"
import { useQueryClient } from "@tanstack/react-query"
import useCustomRouter from "@/hooks/useCustomRouter"
import IconButton from "../IconButton"
import Modal from "../Modal"
import { useRecoverImageMutation, useRecoverTaskMutation, useRecoverWildcardMutation } from "@/queries"
import { useRecoverFolderMutation } from "@/queries/workspace/folder/useRecoverFolderMutation"
import { useRecoverStyleMutation } from "@/queries/tools/style"
import { useRecoverComfyUIMutation } from "@/queries/tools/comfyui-recipe/useRecoverComfyUIMutation"
import { useRecoverRecipeMutation } from "@/queries/workspace/recipe"
import { useCallback } from "react"
import { useRecoverModelMutation } from "@/queries/tools/model/useRecoverModelMutation"
import { useRecoverTrainingModelMutation } from "@/queries/tools/model/useRecoverTrainingModelMutation"

const RecoverItemModal = () => {
  const qc = useQueryClient()
  const router = useCustomRouter()
  const toast = useToast()

  const { recoverItem, setRecoverItem } = useRecoverItemStore()

  const handleCloseCallback = useCallback(() => {
    setRecoverItem(null)

    if (recoverItem?.onClose) {
      setTimeout(() => recoverItem.onClose?.(), 500)

      return
    }
  }, [recoverItem, setRecoverItem])

  const { mutate: mutateRecoverImages, isPending: isPendingRecoverImages } = useRecoverImageMutation({
    onSuccess: data => {
      toast({
        status: "success",
        title: "Recover image successfully!",
      })

      handleCloseCallback()

      if (recoverItem?.isRedirect) {
        router.push("/workspace")
      }
    },
    onError: (err, variables, context) => {
      toast({
        status: "error",
        title: "Recover image failed!",
      })
    },
  })

  const { mutate: mutateRecoverFolder, isPending: isRecoverFolder } = useRecoverFolderMutation({
    onSuccess: () => {
      toast({
        status: "success",
        title: "Recover folder successfully!",
      })
      qc.invalidateQueries({ queryKey: ["folder-info", recoverItem?.folderId] })

      handleCloseCallback()

      if (recoverItem?.isRedirect) {
        router.push("/workspace")
      }
    },
    onError: (err, variables, context) => {
      toast({
        status: "error",
        title: "Recover folder failed!",
      })
    },
  })

  const { mutate: mutateRecoverWorkflow, isPending: isRecoverWorkflow } = useRecoverTaskMutation({
    onSuccess: data => {
      toast({
        status: "success",
        title: "Recover macro successfully!",
      })

      handleCloseCallback()

      if (recoverItem?.isRedirect) {
        router.push("/workspace/macros")
      }
    },
    onError: (err, variables, context) => {
      toast({
        status: "error",
        title: "Recover macro failed!",
      })
    },
  })

  const { mutate: mutateRecoverStyle, isPending: isRecoverStyle } = useRecoverStyleMutation({
    onSuccess: data => {
      toast({
        status: "success",
        title: "Recover style successfully!",
      })

      handleCloseCallback()

      if (recoverItem?.isRedirect) {
        router.push("/workspace/styles")
      }
    },
    onError: (err, variables, context) => {
      toast({
        status: "error",
        title: "Recover style failed!",
      })
    },
  })

  const { mutate: mutateRecoverWildcard, isPending: isRecoverWildcard } = useRecoverWildcardMutation({
    onSuccess: data => {
      toast({
        status: "success",
        title: "Recover wildcard successfully!",
      })

      handleCloseCallback()

      if (recoverItem?.isRedirect) {
        router.push("/workspace/tools/wildcards")
      }
    },
    onError: (err, variables, context) => {
      toast({
        status: "error",
        title: "Recover wildcard failed!",
      })
    },
  })

  const { mutate: mutateRecoverComfyUI, isPending: isRecoverComfyUI } = useRecoverComfyUIMutation({
    onSuccess: data => {
      toast({
        status: "success",
        title: "Recover comfyUI successfully!",
      })

      handleCloseCallback()

      if (recoverItem?.isRedirect) {
        router.push("/workspace/tools/comfyui")
      }
    },
    onError: (err, variables, context) => {
      toast({
        status: "error",
        title: "Recover comfyUI failed!",
      })
    },
  })

  const { mutate: mutateRecoverRecipe, isPending: isRecoverRecipe } = useRecoverRecipeMutation({
    onSuccess: data => {
      toast({
        status: "success",
        title: "Recover recipe successfully!",
      })

      handleCloseCallback()

      if (recoverItem?.isRedirect) {
        router.push("/workspace/tools/recipes")
      }
    },
    onError: (err, variables, context) => {
      toast({
        status: "error",
        title: "Recover recipe failed!",
      })
    },
  })

  const { mutate: mutateRecoverModel, isPending: isRecoverModel } = useRecoverModelMutation({
    onSuccess: data => {
      toast({
        status: "success",
        title: "Recover model successfully!",
      })

      handleCloseCallback()

      if (recoverItem?.isRedirect) {
        router.push("/workspace/tools/models")
      }
    },
    onError: (err, variables, context) => {
      toast({
        status: "error",
        title: "Recover model failed!",
      })
    },
  })

  const { mutate: mutateRecoverTrainingModel, isPending: isRecoverTrainingModel } = useRecoverTrainingModelMutation({
    onSuccess: data => {
      toast({
        status: "success",
        title: "Recover training model successfully!",
      })

      handleCloseCallback()

      if (recoverItem?.isRedirect) {
        router.push("/workspace/tools/models/training")
      }
    },
  })

  const isLoading =
    isPendingRecoverImages ||
    isRecoverFolder ||
    isRecoverWorkflow ||
    isRecoverStyle ||
    isRecoverWildcard ||
    isRecoverComfyUI ||
    isRecoverRecipe ||
    isRecoverModel ||
    isRecoverTrainingModel

  const handleSubmit = () => {
    if (!recoverItem) return

    googleAnalytics.event({
      action: "click",
      category: "modal",
      label: `recover_${recoverItem.type}_${recoverItem?.id}`,
      value: 1,
    })

    if (recoverItem.type === "image") {
      mutateRecoverImages({
        ids: recoverItem.ids,
        folderId: recoverItem.parentFolderId,
      })
    }

    if (recoverItem.type === "folder") {
      mutateRecoverFolder({
        ids: recoverItem.ids,
        parentFolderId: recoverItem.parentFolderId,
      })
    }

    if (recoverItem.type === "macro") {
      mutateRecoverWorkflow(recoverItem.ids)
    }

    if (recoverItem.type === "style") {
      mutateRecoverStyle({
        ids: recoverItem.ids,
      })
    }

    if (recoverItem.type === "wildcard") {
      mutateRecoverWildcard({
        ids: recoverItem.ids,
      })
    }

    if (recoverItem.type === "comfyui-workflow") {
      mutateRecoverComfyUI({
        ids: recoverItem.ids,
      })
    }
    if (recoverItem.type === "recipe") {
      mutateRecoverRecipe({
        ids: recoverItem.ids,
      })
    }

    if (recoverItem.type === "model") {
      mutateRecoverModel({
        ids: recoverItem.ids,
      })
    }

    if (recoverItem.type === "model training") {
      mutateRecoverTrainingModel({
        ids: recoverItem.ids,
      })
    }
  }

  const handleClose = () => {
    recoverItem?.onRemoveFocus?.()
    setRecoverItem(null)
  }

  return (
    <Modal
      title={`Recover ${recoverItem?.type}`}
      className="overflow-visible max-w-md modal-content-child"
      isOpen={!!recoverItem}
      onClose={handleClose}
    >
      <p className="text-sm mb-2 text-atherGray-300">{`Are you sure to recover this ${recoverItem?.type}?`}</p>
      <div className="flex items-center justify-end mt-6 space-x-2">
        <IconButton onClick={handleClose} colorScheme="secondary" className="px-4 font-semibold">
          Cancel
        </IconButton>
        <IconButton onClick={handleSubmit} isLoading={isLoading} className="px-4 font-semibold">
          Confirm
        </IconButton>
      </div>
    </Modal>
  )
}

export default RecoverItemModal
