import { useRenameFolderMutation, useUpdateTaskMutation, useUpdateWildcardMutation } from "@/queries"
import { useRenameStyleMutation } from "@/queries/tools/style/useUpdateStyleMutation"
import { useRenameItemStore } from "@/stores"
import React, { useEffect, useRef, useState } from "react"
import IconButton from "../IconButton"
import Input from "../Input"
import Modal from "../Modal"
import { useRenameComfyUiWorkflowMutation } from "@/queries/tools/comfyui-recipe/updateComfyUiRecipeMutations"
import { useToast } from "@/hooks"

const RenameModal = () => {
  const { renamingItem, setRenamingItem } = useRenameItemStore()
  const toast = useToast()

  const [input, setInput] = useState("")
  const [error, setError] = useState("")
  const inputRef = useRef<HTMLInputElement>(null)

  const { mutate: mutateRenameFolder, isPending: isRenamingFolder } = useRenameFolderMutation({
    onError: (e: any) => {
      const errorMessage = e?.response?.data?.message || "Something went wrong"
      setError(errorMessage)
    },
    onSuccess: () => {
      setRenamingItem(null)
      toast({
        title: "Folder renamed",
        status: "success",
      })
    },
  })

  const { mutate: mutateRenameWorkflow, isPending: isRenamingWorkflow } = useUpdateTaskMutation({
    onSuccess: () => {
      setRenamingItem(null)
      toast({
        title: "Macro renamed",
        status: "success",
      })
    },
    onError: (e: any) => {
      const errorMessage = e?.response?.data?.message || "Something went wrong"
      setError(errorMessage)
    },
  })

  const { mutate: mutateRenameStyle, isPending: isRenamingStyle } = useRenameStyleMutation({
    onSuccess() {
      setRenamingItem(null)
      toast({
        title: "Style renamed",
        status: "success",
      })
    },
    onError: (e: any) => {
      const errorMessage = e?.response?.data?.message || "Something went wrong"
      setError(errorMessage)
    },
  })

  const { mutate: mutateRenameWildcard, isPending: isRenamingWildcard } = useUpdateWildcardMutation({
    onSuccess() {
      setRenamingItem(null)
      toast({
        title: "Wildcard renamed",
        status: "success",
      })
    },
    onError: (e: any) => {
      const errorMessage = e?.response?.data?.message || "Something went wrong"
      setError(errorMessage)
    },
  })

  const { mutate: mutateRenameComfyUI, isPending: isRenamingComfyUI } = useRenameComfyUiWorkflowMutation({
    onSuccess() {
      setRenamingItem(null)
      toast({
        title: "ComfyUI renamed",
        status: "success",
      })
    },
    onError: (e: any) => {
      const errorMessage = e?.response?.data?.message || "Something went wrong"
      setError(errorMessage)
    },
  })

  const isMutating =
    isRenamingFolder || isRenamingWorkflow || isRenamingStyle || isRenamingWildcard || isRenamingComfyUI

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!input) return setError("Name is required")

    if (renamingItem?.type === "folder") {
      mutateRenameFolder({
        folderId: renamingItem!.id,
        newName: input,
        parentFolderId: renamingItem!.parentFolderId!,
      })
    }

    if (renamingItem?.type === "macro") {
      mutateRenameWorkflow({
        taskId: renamingItem!.id,
        data: {
          name: input,
        },
      })
    }

    if (renamingItem?.type === "style") {
      mutateRenameStyle({
        styleId: renamingItem!.id,
        data: {
          name: input,
        },
      })
    }

    if (renamingItem?.type === "wildcard") {
      mutateRenameWildcard({
        wildcardId: renamingItem!.id,
        data: {
          name: input.replace(/[^a-zA-Z-\d]/g, "-"),
        },
      })
    }

    if (renamingItem?.type === "comfyui") {
      mutateRenameComfyUI({
        workflowId: renamingItem!.id,
        data: {
          name: input,
        },
      })
    }
  }

  const isOpen = !!renamingItem

  const onClose = () => {
    setRenamingItem(null)
  }

  useEffect(() => {
    if (isOpen) {
      setInput(renamingItem?.name ?? "")
      setError("")
      setTimeout(() => {
        inputRef.current?.focus()
      }, 0)
    }
  }, [isOpen, renamingItem?.name])

  return (
    <Modal title={`Rename "${renamingItem?.name}"`} className="overflow-visible" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleSubmit}>
        <div>
          <label className="block text-atherGray-300 text-sm mb-1">New name</label>
          <Input ref={inputRef} value={input} onChange={e => setInput(e.target.value)} />
          {error && <p className="mt-1 text-xs text-red-500">{error}</p>}
        </div>
        <div className="flex justify-end mt-6 space-x-2">
          <IconButton colorScheme="secondary" className="text-xs" onClick={onClose} type="button">
            Cancel
          </IconButton>
          <IconButton className="text-xs" isLoading={isMutating} type="submit">
            Confirm
          </IconButton>
        </div>
      </form>
    </Modal>
  )
}

export default RenameModal
