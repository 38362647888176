import client from "@/api/client"
import { SearchSDWorkflowResponse } from "@/api/sdk"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { InfiniteData } from "@tanstack/react-query"
import { createMutation } from "react-query-kit"
import { useComfyUiWorkflowInfiniteQuery } from "./getComfyUiRecipeQueries"

interface RecoverComfyUIMutationVariables {
  ids: string[]
}

export const useRecoverComfyUIMutation = createMutation({
  mutationFn: async ({ ids }: RecoverComfyUIMutationVariables) => {
    return client.api
      .sdWorkflowControllerRecoverWorkflows({
        workflowIds: ids,
      })
      .then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        // Update comfyUI list
        const comfyUIListKey = useComfyUiWorkflowInfiniteQuery.getKey()
        const comfyUIListQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<SearchSDWorkflowResponse, number>
        >({
          queryKey: comfyUIListKey,
        })

        comfyUIListQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            pages: data.pages.map(page => {
              const updatedPage = {
                ...page,
                workflows: page.workflows.filter(style => {
                  return !variables.ids.includes(style.id)
                }),
              }
              updatedPage.total = updatedPage.workflows.length
              return updatedPage
            }),
          }
          queryClient.setQueryData(key, updatedData)
        })
      },
    }),
  ],
})
