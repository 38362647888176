import IconButton from "@/components/IconButton"
import LoadingIcon from "@/components/LoadingIcon"
import Tooltip from "@/components/Tooltip"
import { googleAnalytics } from "@/lib/gtag"
import { AnimatePresence, motion } from "framer-motion"

interface SelectedModalProps {
  selectedBulk: string[]
  onAction: () => void
  iconAction?: React.ReactNode
  isLoading: boolean
  placeHolder: string
}

const SelectedModal = ({ selectedBulk, onAction, isLoading, iconAction, placeHolder }: SelectedModalProps) => {
  return (
    <AnimatePresence>
      {selectedBulk.length > 0 && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          className="fixed left-[50%] z-[20] top-[6rem]  w-fit  h-fit -translate-x-1/2"
        >
          <div className="rounded-2xl bg-atherGray-850 py-2 px-4 text-xs font-semibold text-atherGray-300 h-12 flex items-center space-x-4">
            {isLoading ? (
              <div className="flex items-center justify-center w-full">
                <LoadingIcon width={20} height={20} />
              </div>
            ) : (
              <>
                <span>{selectedBulk.length} selected</span>
                <hr className="w-[1px] h-6 border border-atherGray-800" />
                <Tooltip
                  trigger={
                    <IconButton
                      className="w-8 h-8 p-0 hover:bg-atherGray-700 active:bg-atherGray-800 text-red-500"
                      colorScheme="transparent"
                      onClick={() => {
                        googleAnalytics.event({
                          action: "click",
                          category: "my_account",
                          label: "delete_selected_items",
                          params: {
                            item_id: selectedBulk.join(","),
                            pathname: window.location.pathname,
                          },
                        })

                        onAction()
                      }}
                    >
                      {iconAction}
                    </IconButton>
                  }
                >
                  {placeHolder}
                </Tooltip>
              </>
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default SelectedModal
