import React from "react"

export const YoutubeIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        d="M15.8406 4.87527C15.8406 4.87527 15.6844 3.77214 15.2031 3.28777C14.5938 2.65027 13.9125 2.64714 13.6 2.60964C11.3625 2.44714 8.00313 2.44714 8.00313 2.44714H7.99687C7.99687 2.44714 4.6375 2.44714 2.4 2.60964C2.0875 2.64714 1.40625 2.65027 0.796875 3.28777C0.315625 3.77214 0.1625 4.87527 0.1625 4.87527C0.1625 4.87527 0 6.17214 0 7.46589V8.67839C0 9.97214 0.159375 11.269 0.159375 11.269C0.159375 11.269 0.315625 12.3721 0.79375 12.8565C1.40313 13.494 2.20313 13.4721 2.55938 13.5409C3.84063 13.6628 8 13.7003 8 13.7003C8 13.7003 11.3625 13.694 13.6 13.5346C13.9125 13.4971 14.5938 13.494 15.2031 12.8565C15.6844 12.3721 15.8406 11.269 15.8406 11.269C15.8406 11.269 16 9.97527 16 8.67839V7.46589C16 6.17214 15.8406 4.87527 15.8406 4.87527ZM6.34688 10.1503V5.65339L10.6687 7.90964L6.34688 10.1503Z"
        fill={"currentColor"}
      />
    </svg>
  )
}
