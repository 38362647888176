import { create } from "zustand"

interface BeMissUniverseStore {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

export const useBeMissUniverseStore = create<BeMissUniverseStore>(set => ({
  isOpen: false,
  setIsOpen: isOpen => set({ isOpen })
}))
