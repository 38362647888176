import IconButton from "@/components/IconButton"
import Modal from "@/components/Modal"
import { cdnPublicFolderUrl } from "@/constants"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { useEffect } from "react"
import { useSecurity } from "../useSecurity"

interface AfterDeleteAccountModalProps {
  stateModel: ReturnType<typeof useSecurity>["stateModel"]
  setStateModel: ReturnType<typeof useSecurity>["setStateModel"]
  handleLogoutAll: () => void
}

const AfterDeleteAccountModal = ({ stateModel, setStateModel, handleLogoutAll }: AfterDeleteAccountModalProps) => {
  const { bearerToken, userInfoQuery } = useAuth()

  useEffect(() => {
    let id: NodeJS.Timeout
    if (stateModel.delete.step3 === true && !!bearerToken) {
      id = setTimeout(() => {
        handleLogoutAll()
        googleAnalytics.event({
          action: "click",
          category: "settings_account",
          label: `logout all devices`,
          params: {
            user_id: userInfoQuery?.data?.uid ?? "",
            user_username: userInfoQuery?.data?.username ?? "",
          },
          value: 1,
        })
      }, 15 * 1000)
    }

    return () => {
      clearTimeout(id)
    }
  }, [stateModel.delete.step3])

  return (
    <Modal
      isOpen={stateModel.delete.step3}
      showCloseButton={false}
      closeOnOutsideClick
      onClose={() => {}}
      className="h-full max-w-full"
      containerClassName="p-0 rounded-none"
    >
      <div className="relative flex flex-col items-center justify-center w-full h-full">
        <div className="-translate-y-[12%] w-full h-full flex justify-center flex-col items-center">
          <div className="max-w-[200px] mb-16">
            <img src={`${cdnPublicFolderUrl}/logo.png`} alt="Ather Logo" width={276 * 1.5} height={74 * 1.5} />
          </div>

          <div className="max-w-[500px] text-center mb-12">
            <p className="text-xl font-semibold text-[#FFF8EE] mb-5">Account deletion request sent</p>
            <p className="font-[500] text-[#C8C4B5] mb-5">
              You&apos;ve successfully entered account deletion request. You will be logged out in 15 sec.
            </p>
            <p className="font-semibold text-[#C8C4B5]">Please note: Account deletion time may vary</p>
          </div>
          <IconButton
            onClick={() => {
              handleLogoutAll()
              googleAnalytics.event({
                action: "click",
                category: "settings_account",
                label: `logout all devices`,
                params: {
                  user_id: userInfoQuery?.data?.uid ?? "",
                  user_username: userInfoQuery?.data?.username ?? "",
                },
                value: 1,
              })
            }}
          >
            Log out
          </IconButton>
        </div>

        <div className="absolute bottom-0 translate-y-[48%]">
          <img src={`${cdnPublicFolderUrl}/images/setting/footer.webp`} alt="" />
        </div>
      </div>
    </Modal>
  )
}

export default AfterDeleteAccountModal
