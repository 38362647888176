import { WorkspaceWithRole } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import LoadingIcon from "@/components/LoadingIcon"
import Modal from "@/components/Modal"
import { useAuth } from "@/providers/authContext"
import { useSharedWorkspace } from "./useSharedWorkspace"
interface LeaveModalProps {
  selectedWorkspace?: WorkspaceWithRole[]
  onClose: () => void
  handleLeaveWorkspaces: ReturnType<typeof useSharedWorkspace>["handleLeaveWorkspaces"]
  isLoadingMutateLeaveWorkspace: boolean
}

const LeaveModal = ({
  selectedWorkspace,
  onClose,
  handleLeaveWorkspaces,
  isLoadingMutateLeaveWorkspace,
}: LeaveModalProps) => {
  const { userInfoQuery } = useAuth()

  return (
    <Modal isOpen={!!selectedWorkspace} onClose={onClose} title="Leave workspace">
      <p className="text-atherGray-300">
        Are you sure to leave{" "}
        <span className="font-semibold text-atherGray-0">
          {selectedWorkspace && selectedWorkspace?.length === 1
            ? selectedWorkspace?.[0].name
            : `${selectedWorkspace?.length} workspaces`}
        </span>
        ?
      </p>
      {userInfoQuery?.data?.currentWorkspace && (
        <p className="mt-2 text-red-500 text-xs italic">
          {selectedWorkspace?.map(i => i.id).includes(userInfoQuery?.data?.currentWorkspace?.id)
            ? "You are currently in the workspace you are trying to leave."
            : ""}
        </p>
      )}
      <div className="flex justify-end w-full space-x-2 mt-6">
        <IconButton colorScheme="secondary" onClick={onClose}>
          <p className="font-semibold text-atherGray-0">Cancel</p>
        </IconButton>
        <IconButton
          onClick={() => {
            const ids = selectedWorkspace?.map(_i => _i.id)

            if (!ids) return

            handleLeaveWorkspaces(ids)
          }}
        >
          {isLoadingMutateLeaveWorkspace ? (
            <LoadingIcon width={20} height={20} />
          ) : (
            <p className="font-semibold text-atherGray-0 ">Leave</p>
          )}
        </IconButton>
      </div>
    </Modal>
  )
}

export default LeaveModal
