import React from "react"
import ListBillings from "./ListBillings"
import UserPlan from "./UserPlan"

const BillingUI = () => {
  return (
    <div className="w-full relative flex flex-col flex-1">
      <UserPlan />
      <ListBillings />
    </div>
  )
}

export default BillingUI
