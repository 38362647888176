import IconButton from "@/components/IconButton"
import { DeleteIcon, PlusIcon } from "@/components/shared/icons"
import { useAuth } from "@/providers/authContext"
import { googleAnalytics } from "@/lib/gtag"
import { useEffect } from "react"
import { useSetting } from "../SettingProvider"
import SelectedModal from "../shared/SelectedModal"
import AddMember from "./AddMember"
import DeleteModal from "./DeleteModal"
import FilterBar from "./Filter"
import { useMembers } from "./useMembers"
import useCustomRouter from "@/hooks/useCustomRouter"
import { WorkspaceRole } from "@/api/sdk"
import MemberList from "./MemberList"

const Members = () => {
  const {
    setSelectedBulkUser,
    selectedUser,
    selectedBulkUser,
    setSelectedUser,
    mutateDeleteAccount,
    isLoadingDeleteAccount,
    handleSelectedAll,
    isOpenAddMember,
    setIsOpenAddMember,
    handleClearAll,
    members,
    search,
    selectedBulkAddUser,
    setSelectedBulkAddUser,
    mutateAdd,
    isLoadingAddAccount,
    filters,
    isLimit,
    setIsLimit,
    mutateUpdateUserRole,
    isLoadingUpdateUserRole,
    setFilters,
  } = useMembers()

  const { searchDebounce, setSearchDebounce, setSearchDebounceAddMember } = search
  const { mappedWorkspaceMembers, fetchNextPage, hasNextPage, isFetching, isLoadingWorkspaceMembers } = members

  const { userInfoQuery } = useAuth()
  const { findMyWorkspace } = useSetting()
  const router = useCustomRouter()

  useEffect(() => {
    const handleQueryPayment = () => {
      const workspaceId = router.query.workspaceId as string
      const emails = router.query.emails as string
      const userWorkspaceId = router.query.userWorkspaceId as string

      if (!workspaceId || (!emails && !userWorkspaceId)) return

      try {
        if (userWorkspaceId) {
          mutateUpdateUserRole({
            id: parseInt(userWorkspaceId),
            role: WorkspaceRole.Member,
          })
          router.replace("/settings/account?tab=Members", undefined, { shallow: true })

          return
        }
        mutateAdd({
          workspaceId,
          emails: emails.split(","),
          role: WorkspaceRole.Member,
        })
        router.replace("/settings/account?tab=Members", undefined, { shallow: true })
      } catch (error) {
        router.replace("/settings/account?tab=Members", undefined, { shallow: true })
      }
    }

    if (!router.isReady || !userInfoQuery?.data) return

    handleQueryPayment()
  }, [router.isReady, userInfoQuery])

  return (
    <>
      <DeleteModal
        onClose={() => setSelectedUser(undefined)}
        isLoading={isLoadingDeleteAccount}
        mutateDeleteAccount={mutateDeleteAccount}
        selectedDelete={selectedUser}
      />
      <SelectedModal
        selectedBulk={selectedBulkUser}
        onAction={() => {
          mutateDeleteAccount(selectedBulkUser)
          googleAnalytics.event({
            action: "click",
            category: "settings_account",
            label: `del multi workspace's member`,
            params: {
              user_id: userInfoQuery?.data?.uid ?? "",
              user_username: userInfoQuery?.data?.username ?? "",
              workspace_id: findMyWorkspace?.id ?? "",
              user_deleted_id: selectedBulkUser.toString(),
            },
            value: 1,
          })
        }}
        placeHolder="Delete"
        isLoading={isLoadingDeleteAccount}
        iconAction={<DeleteIcon width={16} height={16} />}
      />
      <AddMember
        isOpen={isOpenAddMember}
        onClose={() => {
          setSelectedBulkAddUser([])
          setIsOpenAddMember(false)
        }}
        selectedBulk={selectedBulkAddUser}
        setSelectedBulk={setSelectedBulkAddUser}
        setSearchDebounce={setSearchDebounceAddMember}
        mutateAdd={mutateAdd}
        isLimit={isLimit}
        setIsLimit={setIsLimit}
        mutateUpdateUserRole={mutateUpdateUserRole}
        isLoadingAddAccount={isLoadingAddAccount || isLoadingUpdateUserRole}
      />
      <div className="flex flex-col w-full max-w-[calc(100vw-2rem)]">
        <div className="flex flex-col justify-between w-full mb-8 space-y-4 md:space-y-0 md:flex-row">
          <div className="flex flex-col flex-wrap space-x-0 space-y-2 md:space-x-2 md:flex-row md:space-y-0">
            <IconButton className="w-[9rem]" onClick={() => setIsOpenAddMember(true)}>
              <PlusIcon className="w-3 mr-1" />
              <p className="text-[14px] font-semibold">New member</p>
            </IconButton>
          </div>
          <FilterBar
            filters={filters}
            setFilters={setFilters}
            searchDebounce={searchDebounce}
            setSearchDebounce={setSearchDebounce}
          />
        </div>

        <MemberList
          fetchNextPage={fetchNextPage}
          handleClearAll={handleClearAll}
          handleSelectedAll={handleSelectedAll}
          hasNextPage={hasNextPage}
          isFetching={isFetching}
          isLoadingWorkspaceMembers={isLoadingWorkspaceMembers}
          mappedWorkspaceMembers={mappedWorkspaceMembers}
          selectedBulkUser={selectedBulkUser}
          setIsOpenAddMember={setIsOpenAddMember}
          setSelectedBulkAddUser={setSelectedBulkAddUser}
          setSelectedBulkUser={setSelectedBulkUser}
          setSelectedUser={setSelectedUser}
        />
      </div>
    </>
  )
}

export default Members
