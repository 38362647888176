import { FileAssociatedResource } from "@/api/sdk"
import HiddenFileInput from "@/components/HiddenFileInput"
import LoadingIcon from "@/components/LoadingIcon"
import { DeleteIcon, LeaveIcon, UserSquareIcon } from "@/components/shared/icons"
import { useFileUpload, useToast } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import getCroppedImg, { resizeImage } from "@/utils/resize-image"
import classNames from "classnames"
import { useCallback, useRef, useState } from "react"
import CropImageModal from "../shared/CropImageModal"
import { useProfile } from "./useProfile"
import UserCreated from "@/components/Explore/Gallery/UserCreated"
import { handleConvertHEICtoJPG } from "@/utils/convert"

const UploadAvatarProfile = () => {
  const { mutateAsync: mutateUpload } = useFileUpload()
  const { mutateUpdateUserProfile, isLoading } = useProfile()
  const { userInfoQuery } = useAuth()
  const toast = useToast()

  //image crop
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const [preview, setPreview] = useState<string | null>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(preview, croppedAreaPixels)
      if (!croppedImage) return
      handleChangeProfilePhoto(croppedImage.file)
    } catch (error) {}

    setPreview(null)
  }, [croppedAreaPixels])

  const handleUploadFromDevice = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
  }

  const handleChangeProfilePhoto = async (_file: File) => {
    if (!_file) return

    let newFile = _file

    if (newFile.type === "image/heic" || newFile.name.endsWith(".heic")) {
      newFile = (await handleConvertHEICtoJPG(newFile)) as File
    }

    newFile = await resizeImage(newFile, 256)
    const res = await mutateUpload({
      resourceType: FileAssociatedResource.USER_AVATAR,
      files: [newFile],
      onProgress: () => {},
      onError: () => {
        toast({
          status: "error",
          title: "Error",
          message: ["Failed to upload image"],
        })
      },
    })

    if (res[0].id) {
      mutateUpdateUserProfile({
        avatarFileId: res[0].id,
      })
      googleAnalytics.event({
        action: "upload",
        category: "settings_account",
        label: `user's avatar`,
        params: {
          user_id: userInfoQuery?.data?.uid ?? "",
          user_username: userInfoQuery?.data?.username ?? "",
        },
        value: 1,
      })
    }
  }

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  if (!userInfoQuery?.data) return null

  return (
    <div className="flex items-center mb-6">
      <div className="rounded-full group overflow-hidden w-[68px] h-[68px] mr-6 relative">
        {isLoading?.avatarFileId && (
          <div className={classNames("absolute inset-0 z-20 items-center justify-center bg-black flex opacity-60")}>
            <LoadingIcon width={20} height={20} />
          </div>
        )}
        {userInfoQuery.data.picture ? (
          <UserCreated
            imageClassName="h-full w-full"
            className="h-full w-full"
            picture={userInfoQuery.data.picture}
            name={userInfoQuery.data.name}
            hiddenName
          />
        ) : (
          <div className="bg-atherGray-700 p-2 h-full w-full flex items-center justify-center rounded-full">
            {<UserSquareIcon width={24} height={24} />}
          </div>
        )}
      </div>
      <div
        className={classNames("mr-6 cursor-pointer flex items-center space-x-2 pointer-events-auto")}
        // onClick={() => handleChangeProfilePhoto()}
        onClick={() => {
          handleUploadFromDevice()
        }}
      >
        <div className="p-[9px] rounded-full bg-[#5E17EB]">
          <div className="-rotate-90">
            <LeaveIcon stroke="2" color="#EFE8FD" />
          </div>
        </div>
        <p className="text-sm text-atherGray-300">Upload</p>

        <HiddenFileInput
          multiple={false}
          onUpload={async files => {
            if (files.length > 0) {
              if (files[0].size > 10 * 2024 * 2024) {
                toast({
                  status: "error",
                  title: "File size too large",
                  message: ["Input image exceeds 10MB, please scale down the image and try again"],
                })
                return
              }

              let newFile = files[0]

              if (newFile.type === "image/heic" || newFile.name.endsWith(".heic")) {
                newFile = (await handleConvertHEICtoJPG(newFile)) as File
              }

              newFile = await resizeImage(newFile)
              setPreview(URL.createObjectURL(newFile))
            }
          }}
          ref={fileInputRef}
        />
      </div>
      <div
        className="mr-6 cursor-pointer"
        onClick={() => {
          mutateUpdateUserProfile({
            avatarFileId: null,
          })
          googleAnalytics.event({
            action: "upload",
            category: "settings_account",
            label: `del user's avatar`,
            params: {
              user_id: userInfoQuery?.data?.uid ?? "",
              user_username: userInfoQuery?.data?.username ?? "",
            },
            value: 1,
          })
        }}
      >
        <DeleteIcon width={20} height={20} className="text-red-500" />
      </div>

      <CropImageModal
        preview={preview}
        crop={crop}
        zoom={zoom}
        setCrop={setCrop}
        onCropComplete={onCropComplete}
        setZoom={setZoom}
        setPreview={setPreview}
        showCroppedImage={showCroppedImage}
      />
    </div>
  )
}

export default UploadAvatarProfile
