import client from "@/api/client"
import { useToast } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { isMobileOnly } from "react-device-detect"

const useBeMiss = ({ onSuccess }: { onSuccess?: () => void }) => {
  const toast = useToast({
    position: !isMobileOnly ? "top-center" : "bottom-center",
  })
  const qc = useQueryClient()

  const mutate = useMutation({
    mutationFn: (imageId: string) => {
      googleAnalytics.event({
        action: "click",
        category: "miss_universe_alpha",
        label: "submit_join_miss_universe",
        params: {
          image_id: imageId,
        },
      })

      return client.api.missUniverseControllerCreateMissUniverseContestant({
        imageId,
      })
    },
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: ["vote-miss-universe"] })
      qc.invalidateQueries({ queryKey: ["check-participation"] })
      toast({
        status: "success",
        title: "Thanks for participating!",
        message: ["Hope you’ll get the crown! Check your votes at the voting page"],
      })
      onSuccess?.()
    },
    onError: (error: any) => {
      toast({
        status: "error",
        title: "Error",
        message: [error?.message || "Something went wrong."],
      })
    },
  })

  return mutate
}

export default useBeMiss
