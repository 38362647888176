import LoadingIcon from "@/components/LoadingIcon"
import { TickIcon, XIcon } from "@/components/shared/icons"
import { EditIcon } from "@/components/shared/icons/EditIcon"
import classNames from "classnames"
import { HTMLInputTypeAttribute, useEffect, useLayoutEffect, useRef, useState } from "react"
import { IoCheckmarkOutline } from "react-icons/io5"
import { MdClose } from "react-icons/md"

interface InputFieldProps {
  title: string
  defaultValue: string
  onSubmit: (v?: string) => void
  isActive: boolean
  setActiveEdit: (v: boolean) => void
  css?: string
  type?: HTMLInputTypeAttribute
  error?: string
  onClickClose?: () => void
  isLoading?: boolean
  disabled?: boolean
}

const InputField = ({
  defaultValue,
  isActive,
  setActiveEdit,
  css,
  onSubmit,
  disabled,
  title,
  type = "text",
  error,
  onClickClose,
  isLoading = false,
}: InputFieldProps) => {
  const [inputValue, setInputValue] = useState<string>(defaultValue)
  const inputRef = useRef<HTMLInputElement>(null)
  const textareaRef = useRef<HTMLTextAreaElement>(null)

  useLayoutEffect(() => {
    // Reset height - important to shrink on delete
    if (!textareaRef.current) return
    textareaRef.current.style.height = "inherit"
    // Set height
    textareaRef.current.style.height = `${Math.max(textareaRef.current.scrollHeight, 32)}px`
  }, [inputValue])

  return (
    <div className={classNames("w-full select-none", css)}>
      <p className="mb-1 font-semibold">{title}</p>
      <div className="flex">
        {title !== "About me" &&
          (isActive ? (
            <input
              ref={inputRef}
              autoFocus
              type={type}
              disabled={!isActive}
              onChange={v => setInputValue(v.target.value)}
              value={inputValue}
              className={classNames(
                "w-full bg-transparent border-b-[1px] text-sm min-h-[2rem] border-[#4C4B47] text-[#C8C4B5] font-[300] pb-1",
                {
                  "border-[#DD3A38]": !!error,
                },
              )}
            />
          ) : (
            <p
              className={classNames(
                "w-full bg-transparent border-b-[1px] flex items-center min-h-[2rem] text-sm border-[#4C4B47] text-[#C8C4B5] font-[300] pb-1",
                {
                  "min-h-[2.5rem]": title === "About me",
                },
              )}
            >
              {inputValue}
            </p>
          ))}

        {title === "About me" && (
          <div className="w-full">
            <textarea
              ref={textareaRef}
              disabled={!isActive}
              readOnly={!isActive}
              autoFocus
              onFocus={e =>
                e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length)
              }
              onChange={v => setInputValue(v.target.value)}
              value={inputValue}
              className={classNames("w-full bg-transparent resize-none text-sm text-[#C8C4B5] font-[300] pb-1")}
            />
            <hr
              className={classNames("border-[#4C4B47]", {
                "border-[#DD3A38]": !!error,
              })}
            />
          </div>
        )}
        <div
          className={classNames("pl-4 opacity-100 cursor-pointer border-b-[1px] border-[#4C4B47] ", {
            "border-[#DD3A38]": !!error,
            hidden: disabled,
          })}
        >
          {isLoading ? (
            <div className="p-[6px]">
              <LoadingIcon width={16} height={16} />
            </div>
          ) : (
            <>
              {isActive === true ? (
                <div className="flex items-center space-x-2">
                  <div onClick={() => onSubmit(inputValue)}>
                    <TickIcon width={16} height={16} />
                  </div>
                  <div
                    onClick={() => {
                      setInputValue(defaultValue)
                      setActiveEdit(false)
                      onClickClose?.()
                    }}
                  >
                    <XIcon width={16} height={16} className="text-red-500" />
                  </div>
                </div>
              ) : (
                <div
                  onClick={() => {
                    setActiveEdit(true)
                    setTimeout(() => {
                      inputRef.current?.focus()
                      textareaRef.current?.focus()
                    }, 0)
                  }}
                  className="p-[6px]"
                >
                  <EditIcon className="text-atherGray-300" width={16} height={16} />
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <div>
        <p className="text-[12px] text-[#DD3A38]">{error}</p>
      </div>
    </div>
  )
}

export default InputField
