import React from "react"

export const FacebookIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 17" fill="none" {...props}>
      <g clipPath="url(#clip0_524_16755)">
        <path
          d="M16 8.0752C16 3.65691 12.4183 0.0751953 8 0.0751953C3.58172 0.0751953 0 3.65691 0 8.0752C0 12.0682 2.92547 15.3779 6.75 15.978V10.3877H4.71875V8.0752H6.75V6.3127C6.75 4.3077 7.94438 3.2002 9.77172 3.2002C10.6467 3.2002 11.5625 3.35645 11.5625 3.35645V5.3252H10.5538C9.56 5.3252 9.25 5.94191 9.25 6.5752V8.0752H11.4688L11.1141 10.3877H9.25V15.978C13.0745 15.3779 16 12.0682 16 8.0752Z"
          fill={"currentColor"}
        />
      </g>
      <defs>
        <clipPath id="clip0_524_16755">
          <rect width="16" height="16" fill="white" transform="translate(0 0.0751953)" />
        </clipPath>
      </defs>
    </svg>
  )
}
