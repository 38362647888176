import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { PinnedItems, PinnedItemType, RecentModelItem, SearchModelResults } from "@/api/sdk"
import { InfiniteData } from "@tanstack/react-query"
import { useWorkspaceModelsInfiniteQuery } from "./useWorkspaceModelsInfiniteQuery"
import { useRecentModelsInfiniteQuery } from "@/components/Workspace/RecentV2/RecentModels"
import { useWorkspacePinInfiniteQuery } from "@/queries/workspace"

export const useDeleteModelMutation = createMutation({
  mutationFn: async ({ ids, deletedAt }: { ids: string[]; deletedAt: string | null }) => {
    if (deletedAt) {
      return await client.api.sdModelControllerFullyDeleteModels({ modelIds: ids }).then(res => res.data)
    }

    return await client.api.sdModelControllerSoftDeleteModels({ modelIds: ids }).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables: { ids, deletedAt } }) => {
        // Update models list
        const workspaceModelsKey = useWorkspaceModelsInfiniteQuery.getKey()
        const workspaceModelsQueriesDataEntries = queryClient.getQueriesData<InfiniteData<SearchModelResults, number>>({
          queryKey: workspaceModelsKey,
        })
        if (workspaceModelsQueriesDataEntries) {
          workspaceModelsQueriesDataEntries.forEach(([key, queryData]) => {
            if (queryData) {
              const updatedData = {
                ...queryData,
                pages: queryData.pages.map(page => {
                  return {
                    ...page,
                    total: page.total - ids.length,
                    data: page.data.filter(model => !ids.includes(model.id)),
                  }
                }),
              }
              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update models recent list
        const modelsRecentListKey = useRecentModelsInfiniteQuery.getKey()
        const modelsRecentListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<RecentModelItem[], number>>({
          queryKey: modelsRecentListKey,
        })

        if (modelsRecentListQueriesDataEntries) {
          modelsRecentListQueriesDataEntries.forEach(([key, modelsRecentListData]) => {
            if (modelsRecentListData) {
              const updatedData = {
                ...modelsRecentListData,
                pages: modelsRecentListData.pages.map(page => {
                  return [...page.filter(recent => !ids.includes(recent.model.id))]
                }),
              }
              queryClient.setQueryData(key, updatedData)
            }
          })
        }

        // Update models pinned list
        const modelsPinnedListKey = useWorkspacePinInfiniteQuery.getKey({
          type: PinnedItemType.MODEL,
        })
        const modelsPinnedListQueriesDataEntries = queryClient.getQueriesData<InfiniteData<PinnedItems[], number>>({
          queryKey: modelsPinnedListKey,
        })

        if (modelsPinnedListQueriesDataEntries) {
          modelsPinnedListQueriesDataEntries.forEach(([key, modelsPinnedListData]) => {
            if (modelsPinnedListData) {
              const updatedData = {
                ...modelsPinnedListData,
                pages: modelsPinnedListData.pages.map(page => {
                  return [...page.filter(item => !ids.includes(item.sdModel?.id ?? ""))]
                }),
              }
              queryClient.setQueryData(key, updatedData)
            }
          })
        }
      },
    }),
  ],
})
