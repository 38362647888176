import { useToast } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useDeleteItemStore } from "@/stores"
import { useQueryClient } from "@tanstack/react-query"
import useCustomRouter from "@/hooks/useCustomRouter"
import IconButton from "../IconButton"
import Modal from "../Modal"
import {
  useDeleteWorkflowsMutation,
  useDeleteImageMutation,
  useDeleteFolderMutation,
  useDeleteStyleMutation,
  useDeleteTaskMutation,
  useDeletePromptMutation,
} from "@/queries"
import { useDeleteRecipeMutation } from "@/queries/workspace/recipe"
import { ModalShareType } from "@/lib/store"
import { useDeleteComfyUiWorkflowMutation } from "@/queries/tools/comfyui-recipe"
import { useCallback } from "react"
import { useDeleteRoomMutation } from "@/queries/workspace/room"
import { useDeleteModelTrainingMutation } from "@/queries/tools/model/useDeleteModelTrainingMutation"
import { useDeleteModelMutation } from "@/queries/tools/model/useDeleteModelMutation"

const DeleteItemModal = () => {
  const qc = useQueryClient()
  const router = useCustomRouter()
  const toast = useToast()

  const { deletingItem, setDeletingItem } = useDeleteItemStore()

  const handleCloseCallback = useCallback(() => {
    setDeletingItem(null)

    if (deletingItem?.onClose) {
      setTimeout(() => deletingItem.onClose?.(), 500)

      return
    }
  }, [deletingItem, setDeletingItem])

  const { mutate: mutateDeleteImage, isPending: isDeletingImage } = useDeleteImageMutation({
    onSuccess: data => {
      toast({
        status: "success",
        title: "Delete image successfully!",
      })

      handleCloseCallback()

      if (deletingItem?.isRedirect) {
        router.push("/workspace")
      }
    },
    onError: (err, variables, context) => {
      toast({
        status: "error",
        title: "Delete image failed!",
      })
    },
  })

  const { mutate: mutateDeleteFolder, isPending: isDeletingFolder } = useDeleteFolderMutation({
    onSuccess: () => {
      toast({
        status: "success",
        title: "Delete folder successfully!",
      })
      handleCloseCallback()

      if (deletingItem?.isRedirect) {
        router.push("/workspace")
      }
      qc.invalidateQueries({ queryKey: ["folder-info", deletingItem?.folderId] })
    },
    onError: (err, variables, context) => {
      toast({
        status: "error",
        title: "Delete folder failed!",
      })
    },
  })

  const { mutate: mutateDeleteWorkflow, isPending: isSoftDeletingWorkflow } = useDeleteWorkflowsMutation({
    onSuccess: data => {
      toast({
        status: "success",
        title: "Delete workflow successfully!",
      })

      handleCloseCallback()

      if (deletingItem?.isRedirect) {
        router.push("/workspace/macros")
      }
    },
    onError: (err, variables, context) => {
      toast({
        status: "error",
        title: "Delete workflow failed!",
      })
    },
  })

  const { mutate: mutateDeleteStyle, isPending: isDeleteStyle } = useDeleteStyleMutation({
    onSuccess() {
      toast({
        status: "success",
        title: "Delete style successfully!",
      })

      handleCloseCallback()

      if (deletingItem?.isRedirect) {
        router.push("/workspace/styles")
      }
    },
    onError: (err, variables, context) => {
      toast({
        status: "error",
        title: "Delete style failed!",
      })
    },
  })

  const { mutate: mutateDeleteWildcard, isPending: isDeleteWildcard } = useDeletePromptMutation({
    onSuccess() {
      toast({
        status: "success",
        title: "Delete wildcard successfully!",
      })

      handleCloseCallback()

      if (deletingItem?.isRedirect) {
        router.push("/workspace/tools/wildcards")
      }
    },
    onError: (err, variables, context) => {
      toast({
        status: "error",
        title: "Delete wildcard failed!",
      })
    },
  })

  const { mutate: mutateDeleteRecipe, isPending: isDeleteRecipe } = useDeleteRecipeMutation({
    onSuccess() {
      toast({
        status: "success",
        title: "Delete recipe successfully!",
      })

      handleCloseCallback()

      if (deletingItem?.isRedirect) {
        router.push("/workspace/tools/recipes")
      }
    },
    onError: (err, variables, context) => {
      toast({
        status: "error",
        title: "Delete recipe failed!",
      })
    },
  })

  const { mutate: mutateDeleteComfyUi, isPending: isDeleteComfyUI } = useDeleteComfyUiWorkflowMutation({
    onSuccess() {
      toast({
        status: "success",
        title: "Delete comfyui recipe successfully!",
      })

      handleCloseCallback()

      if (deletingItem?.isRedirect) {
        router.push("/workspace/tools/comfyui")
      }
    },
    onError: (err, variables, context) => {
      toast({
        status: "error",
        title: "Delete comfyui recipe failed!",
      })
    },
  })

  const { mutate: mutateDeleteTask, isPending: isDeleteTask } = useDeleteTaskMutation({
    onSuccess() {
      toast({
        status: "success",
        title: "Delete task successfully!",
      })

      handleCloseCallback()

      if (deletingItem?.isRedirect) {
        router.push("/workspace/tools/recipes")
      }
    },
    onError: (err, variables, context) => {
      toast({
        status: "error",
        title: "Delete task failed!",
      })
    },
  })

  const { mutate: mutateDeletePrompt, isPending: isDeletePrompt } = useDeletePromptMutation({
    onSuccess() {
      toast({
        status: "success",
        title: "Delete prompt successfully!",
      })

      handleCloseCallback()

      if (deletingItem?.isRedirect) {
        router.push("/workspace/tools/prompts")
      }
    },
    onError: (err, variables, context) => {
      toast({
        status: "error",
        title: "Delete prompt failed!",
      })
    },
  })

  const { mutate: mutateDeleteRoom, isPending: isDeleteRoom } = useDeleteRoomMutation({
    onSuccess() {
      toast({
        status: "success",
        title: `${deletingItem?.deletedAt === "leave" ? "Leave" : "Delete"} room successfully!`,
      })

      handleCloseCallback()

      if (deletingItem?.isRedirect) {
        router.push("/rooms")
      }
    },
    onError: (err, variables, context) => {
      toast({
        status: "error",
        title: `${deletingItem?.deletedAt === "leave" ? "Leave" : "Delete"} room failed!`,
      })
    },
  })

  const { mutate: mutateDeleteModel, isPending: isDeleteModel } = useDeleteModelMutation({
    onSuccess: () => {
      toast({
        status: "success",
        title: `${deletingItem?.deletedAt === "leave" ? "Leave" : "Delete"} model successfully!`,
      })

      handleCloseCallback()

      if (deletingItem?.isRedirect) {
        router.push("/workspace/tools/models")
      }
    },
    onError: () => {
      toast({
        status: "error",
        title: `${deletingItem?.deletedAt === "leave" ? "Leave" : "Delete"} model failed!`,
      })
    },
  })

  const { mutate: mutateDeleteModelTraining, isPending: isDeleteModelTraining } = useDeleteModelTrainingMutation({
    onSuccess: () => {
      toast({
        status: "success",
        title: `${deletingItem?.deletedAt === "leave" ? "Leave" : "Delete"} model training successfully!`,
      })

      handleCloseCallback()
    },
    onError: () => {
      toast({
        status: "error",
        title: `${deletingItem?.deletedAt === "leave" ? "Leave" : "Delete"} model training failed!`,
      })
    },
  })

  const isLoading =
    isDeletingImage ||
    isDeletingFolder ||
    isSoftDeletingWorkflow ||
    isDeleteStyle ||
    isDeleteRecipe ||
    isDeleteTask ||
    isDeleteWildcard ||
    isDeleteComfyUI ||
    isDeletePrompt ||
    isDeleteRoom ||
    isDeleteModel ||
    isDeleteModelTraining

  const handleSubmit = async () => {
    if (!deletingItem) return

    googleAnalytics.event({
      action: "click",
      category: "modal",
      label: `deleted_${deletingItem.type}_${deletingItem?.id}`,
      value: 1,
    })

    if (deletingItem.type === "image") {
      mutateDeleteImage({
        ids: deletingItem.ids ?? [deletingItem.id],
        folderId: deletingItem?.folderId,
        deletedAt: deletingItem.deletedAt,
        recipeId: deletingItem.recipeParams?.recipeId,
        recipeType: deletingItem.recipeParams?.recipeType,
      })
    }

    if (deletingItem.type === "folder") {
      mutateDeleteFolder({
        ids: deletingItem.ids ?? [deletingItem.id],
        parentFolderId: deletingItem.parentFolderId,
        deletedAt: deletingItem.deletedAt,
      })
    }

    if (deletingItem.type === "macro") {
      mutateDeleteWorkflow({
        ids: deletingItem.ids ?? [deletingItem.id],
        deletedAt: deletingItem.deletedAt,
      })
    }

    if (deletingItem.type === "style") {
      mutateDeleteStyle({
        ids: deletingItem.ids ?? [deletingItem.id],
        deleteAt: deletingItem.deletedAt,
      })
    }

    if (deletingItem.type === "comfyui-workflow") {
      mutateDeleteComfyUi({
        ids: deletingItem.ids ?? [deletingItem.id],
        deletedAt: deletingItem.deletedAt,
      })
    }
    if (deletingItem.type === "task") {
      mutateDeleteTask({
        recipeId: deletingItem.recipeParams?.recipeId,
        roomId: deletingItem.recipeParams?.roomId,
        ids: deletingItem.ids ?? [deletingItem.id],
        type: deletingItem.recipeParams?.recipeType,
      })
    }

    if (deletingItem.type === "wildcard") {
      mutateDeleteWildcard({
        ids: deletingItem.ids ?? [deletingItem.id],
        deleteAt: deletingItem.deletedAt,
      })
    }

    if (deletingItem.type === "recipe") {
      mutateDeleteRecipe({
        ids: deletingItem.ids ?? [deletingItem.id],
        deletedAt: deletingItem.deletedAt,
      })
    }
    if (deletingItem.type === "prompt") {
      mutateDeletePrompt({
        ids: deletingItem.ids ?? [deletingItem.id],
        deleteAt: "123",
      })
    }
    if (deletingItem.type === "room") {
      mutateDeleteRoom({
        roomId: deletingItem.id,
        deleteAt: deletingItem.deletedAt,
      })
    }
    if (deletingItem.type === "model") {
      mutateDeleteModel({
        ids: deletingItem.ids ?? [deletingItem.id],
        deletedAt: deletingItem.deletedAt,
      })
    }
    if (deletingItem.type === "model training") {
      mutateDeleteModelTraining({
        ids: deletingItem.ids ?? [deletingItem.id],
        deletedAt: deletingItem.deletedAt,
      })
    }
  }

  const handleClose = () => {
    deletingItem?.onRemoveFocus?.()
    setDeletingItem(null)
  }

  const renderTypeName = (type?: ModalShareType) => {
    if (type === "comfyui-workflow") return "comfyUI"

    return type
  }

  return (
    <Modal
      title={`${deletingItem?.deletedAt === "leave" ? "Leave" : "Delete"} ${renderTypeName(deletingItem?.type)}`}
      className="overflow-visible max-w-md text-atherGray-100 modal-content-child"
      isOpen={!!deletingItem}
      onClose={handleClose}
    >
      <p className="text-sm mb-2 text-atherGray-300">{`Are you sure to ${
        deletingItem?.deletedAt === "leave" ? "leave" : "delete"
      } this ${renderTypeName(deletingItem?.type)}?`}</p>
      {!deletingItem?.deletedAt ? (
        deletingItem?.type !== "task" &&
        deletingItem?.type !== "room" &&
        deletingItem?.type !== "prompt" && (
          <p className="text-atherGray-300">It will be moved the Trash and deleted permanently after 30 days.</p>
        )
      ) : (
        <p className="text-atherGray-300">
          It will be {deletingItem?.deletedAt === "leave" ? "leaved" : "deleted"} permanently and cannot be restored.
        </p>
      )}
      <div className="flex items-center justify-end mt-6 space-x-2">
        <IconButton onClick={handleClose} colorScheme="secondary" className="px-4 font-semibold">
          Cancel
        </IconButton>
        <IconButton onClick={handleSubmit} isLoading={isLoading} className="px-4 font-semibold">
          Confirm
        </IconButton>
      </div>
    </Modal>
  )
}

export default DeleteItemModal
