import client from "@/api/client"
import { actionMiddleware } from "@/queries/middlewares/actionMiddleware"
import { createMutation } from "react-query-kit"
import { InfiniteData } from "@tanstack/react-query"
import { SearchModelTrainingResults } from "@/api/sdk"
import { useWorkspaceModelTrainingInfiniteQuery } from "./useWorkspaceModelTrainingInfiniteQuery"

interface RecoverTrainingModelMutationVariables {
  ids: string[]
}

export const useRecoverTrainingModelMutation = createMutation({
  mutationFn: async ({ ids }: RecoverTrainingModelMutationVariables) => {
    return client.api.sdModelTrainingControllerRecoverModels({ trainingIds: ids }).then(res => res.data)
  },
  use: [
    actionMiddleware({
      onSuccess: ({ queryClient, variables }) => {
        // Update model list
        const modelListKey = useWorkspaceModelTrainingInfiniteQuery.getKey()
        const modelListQueriesDataEntries = queryClient.getQueriesData<
          InfiniteData<SearchModelTrainingResults, number>
        >({
          queryKey: modelListKey,
        })

        modelListQueriesDataEntries?.forEach(([key, data]) => {
          if (!data) return

          const updatedData = {
            ...data,
            pages: data.pages.map(page => {
              const updatedPage = {
                ...page,
                data: page.data.filter(model => {
                  return !variables.ids.includes(model.id)
                }),
              }
              updatedPage.total = updatedPage.data.length
              return updatedPage
            }),
          }

          queryClient.setQueryData(key, updatedData)
        })
      },
    }),
  ],
})
