import LoadingIcon from "@/components/LoadingIcon"
import Modal from "@/components/Modal"
import { googleAnalytics } from "@/lib/gtag"
import classNames from "classnames"
import { useAuth } from "../../../providers/authContext"
import { useSetting } from "../SettingProvider"
import { useMembers } from "./useMembers"

interface DeleteModalProps {
  onClose: () => void
  isLoading: boolean
  selectedDelete?: ReturnType<typeof useMembers>["selectedUser"]
  mutateDeleteAccount: ReturnType<typeof useMembers>["mutateDeleteAccount"]
}

const DeleteModal = ({ isLoading, onClose, selectedDelete, mutateDeleteAccount }: DeleteModalProps) => {
  const { userInfoQuery } = useAuth()
  const { findMyWorkspace } = useSetting()
  return (
    <Modal isOpen={!!selectedDelete} onClose={onClose} title="Remove member" className="max-w-[450px]">
      <p className="text-atherGray-300 text-sm font-[400] leading-[17.5px] mb-8">
        Are you sure to remove{" "}
        <span className="font-semibold">
          {selectedDelete?.name} ({selectedDelete?.email})
        </span>
        ?
      </p>
      <div
        className={classNames(
          "flex justify-end w-full",
          isLoading ? "!opacity-60 pointer-events-none" : "!opacity-100 !pointer-events-auto",
        )}
      >
        <div
          className="py-2 bg-[#323230] rounded-lg mr-[9px] min-w-[75px] flex justify-center  cursor-pointer"
          onClick={onClose}
        >
          <p className="text-[#fff8ee] font-semibold text-xs">Cancel</p>
        </div>
        <div
          className={classNames(
            "px-3 py-2 bg-[#5E17EB] rounded-lg min-w-[75px] flex justify-center items-center cursor-pointer",
            {
              "pointer-events-auto": isLoading,
            },
          )}
          onClick={() => {
            if (selectedDelete?.uid) {
              mutateDeleteAccount([selectedDelete?.uid])
              googleAnalytics.event({
                action: "click",
                category: "settings_account",
                label: `del workspace's member`,
                params: {
                  user_id: userInfoQuery?.data?.uid ?? "",
                  user_username: userInfoQuery?.data?.username ?? "",
                  workspace_id: findMyWorkspace?.id ?? "",
                  user_deleted_id: selectedDelete?.uid,
                },
                value: 1,
              })
            }
          }}
        >
          {isLoading ? (
            <LoadingIcon width={20} height={20} />
          ) : (
            <p className="text-[#fff8ee] font-semibold text-xs">Remove</p>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default DeleteModal
