import ModalsPortal from "../ModalsPortal"
import SidebarDrawerMobile from "../Workspace/Sidebar/SidebarDrawerMobile"
import CommonNavbar from "../common/CommonNavbar"
import MobileNavigation from "../common/MobileNavigation"
import { menuCommonPages } from "./CommonLayout"
import { withLazyProviders } from "./with-lazy-providers"

const DetailLayout = ({
  children,
  hiddenNavigation,
  hiddenNavbar,
}: {
  children: React.ReactNode
  hiddenNavigation?: boolean
  hiddenNavbar?: boolean
}) => {
  return (
    <main id="layout" className="relative min-h-screen flex flex-col w-full dark:bg-black dark:text-atherGray-0">
      <div id="layout-inside" className="flex-col flex-1 flex relative w-full">
        {!hiddenNavbar && (
          <CommonNavbar navItems={menuCommonPages} containerClassName="border-b border-atherGray-800" />
        )}
        <div className={"flex-1 flex flex-col pb-[5.15rem] md:pb-0 w-full"}>
          {children}
          {!hiddenNavigation && (
            <>
              <MobileNavigation />
              <SidebarDrawerMobile />
            </>
          )}
        </div>
      </div>

      <ModalsPortal />
    </main>
  )
}

export default withLazyProviders(DetailLayout)
