import client from "@/api/client"
import TogglePublic from "@/components/Profile/shared/TogglePublic"
import { useAuth } from "@/providers/authContext"
import { googleAnalytics } from "@/lib/gtag"
import { useMutation, useQueryClient } from "@tanstack/react-query"

const Privacy = () => {
  const qc = useQueryClient()
  const { userInfoQuery } = useAuth()

  const { mutate: mutateToggle, isPending } = useMutation({
    mutationFn: () => client.api.creatorProfileControllerToggleCreatorProfile(),
    onMutate: () => {
      googleAnalytics.event({
        action: "click",
        category: "settings_account",
        label: `toggle: user privacy mode`,
        params: {
          user_id: userInfoQuery?.data?.uid ?? "",
          user_username: userInfoQuery?.data?.username ?? "",
        },
        value: 1,
      })
    },
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: ["user-info"] })
    },
  })
  return (
    <div className="flex flex-col max-w-[520px] space-y-4">
      <p className="font-semibold text-[#FFFBEE] ">Privacy</p>
      <TogglePublic
        isLoadingMutateToggle={isPending}
        mutateToggle={mutateToggle}
        isPublic={!userInfoQuery?.data?.isPrivate ?? false}
        setModalState={v => {}}
        className="flex w-full"
        boxClassName="flex-1  flex-row-reverse justify-between"
      />
      <p className="text-sm text-atherGray-300">
        When your account is public, your profile and posts can be seen by anyone, on or off GAIA, even if they don’t
        have an GAIA account.
      </p>
      <p className="text-sm text-atherGray-300">
        When your account is private, only the followers you approve can see what you share, including your images,
        macros, articles
      </p>
    </div>
  )
}

export default Privacy
