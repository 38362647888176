import IconButton from "@/components/IconButton"
import { format } from "date-fns"
import { User } from "firebase/auth"
import React from "react"

interface SetupPasswordProps {
  user: User | null
  onSetPassword: () => void
}

const SetupPassword = ({ user, onSetPassword }: SetupPasswordProps) => {
  const isHavePasswordProvider = user?.providerData.some(provider => provider.providerId === "password")

  return (
    <div className="flex flex-col">
      <p className="text-[#FFF8EE] font-semibold mb-3">Password</p>
      {isHavePasswordProvider && (user as any)?.reloadUserInfo?.passwordUpdatedAt && (
        <p className={"text-atherGray-300 text-sm mb-3"}>
          Password last updated: {format(new Date((user as any)?.reloadUserInfo?.passwordUpdatedAt), "dd MMM yyyy")}
        </p>
      )}

      <IconButton colorScheme="secondary" className="p-2 rounded-lg w-fit" onClick={() => onSetPassword()}>
        <p className="text-[#fff8ee] text-sm font-semibold">
          {isHavePasswordProvider ? "Change password" : "Set password"}
        </p>
      </IconButton>
    </div>
  )
}

export default SetupPassword
