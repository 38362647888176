import React from "react"

export const TiktokIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 17" fill="none" {...props}>
      <g clipPath="url(#clip0_524_16753)">
        <path
          d="M11.3817 0.0751953H8.6852V10.9737C8.6852 12.2723 7.64812 13.339 6.3575 13.339C5.06689 13.339 4.02979 12.2723 4.02979 10.9737C4.02979 9.6984 5.04384 8.65488 6.28838 8.60853V5.87231C3.54581 5.91866 1.33333 8.16795 1.33333 10.9737C1.33333 13.8028 3.5919 16.0752 6.38056 16.0752C9.16918 16.0752 11.4277 13.7796 11.4277 10.9737V5.38533C12.4418 6.12737 13.6863 6.56795 15 6.59115V3.85491C12.9719 3.78534 11.3817 2.11577 11.3817 0.0751953Z"
          fill={"currentColor"}
        />
      </g>
      <defs>
        <clipPath id="clip0_524_16753">
          <rect width="16" height="16" fill="white" transform="translate(0 0.0751953)" />
        </clipPath>
      </defs>
    </svg>
  )
}
