export const socials = [
  {
    name: "facebook",
    url: "https://www.facebook.com/beginGAIA",
  },
  {
    name: "instagram",
    url: "https://www.instagram.com/begingaia",
  },
  {
    name: "youtube",
    url: "https://www.youtube.com/@protogaia",
  },
  {
    name: "twitter",
    url: "https://x.com/beginGAIA",
  },
  // {
  //   name: "reddit",
  //   url: "https://www.reddit.com/user/protogaia",
  // },
  {
    name: "linkedin",
    url: "https://www.linkedin.com/company/protogaia",
  },
  //   {
  //     name: "tiktok",
  //     url: "http://tiktok.com/@ArtVentureX",
  //   },
]
