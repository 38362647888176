import IconButton from "../IconButton"
import Modal from "../Modal"

interface SubmitModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  isLoadingSubmit?: boolean
}

const SubmitModal = ({ isOpen, onClose, onSubmit, isLoadingSubmit }: SubmitModalProps) => {
  return (
    <Modal className="max-w-md" isOpen={isOpen} onClose={onClose}>
      <p className="text-sm text-atherGray-100 text-center">
        You can only submit 1 photo to Miss Universe Contest.
        <br /> You really want to use this photo?
      </p>
      <div className="flex justify-center items-center space-x-4 mt-4">
        <IconButton className="px-4" onClick={onClose}>
          Let me check again
        </IconButton>
        <IconButton
          isLoading={isLoadingSubmit}
          onClick={() => {
            onSubmit()
          }}
          className="bg-atherPurple-500 px-4 hover:bg-atherPurple-500 active:bg-atherPurple-500"
        >
          Submit Photo
        </IconButton>
      </div>
    </Modal>
  )
}

export default SubmitModal
