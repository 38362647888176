import { FolderDetail, SubfolderView } from "@/api/sdk"
import { SharpChevronRightIcon } from "@/components/shared/icons"
import { tabs } from "./FolderTabs"

interface BreadcrumbsProps {
  breadcrumbs: FolderDetail[]
  selectedView: SubfolderView
  onSelectView: (view: SubfolderView) => void
  onBreadcrumbClick: (index: number) => void
}

const Breadcrumbs = ({ selectedView, breadcrumbs, onSelectView, onBreadcrumbClick }: BreadcrumbsProps) => {
  if (breadcrumbs.length === 0) return null
  const tab = tabs.find(t => t.view === selectedView)
  return (
    <div className="sticky top-0 text-atherGray-300 py-1.5 px-3 flex items-center space-x-1">
      <button
        className="flex items-center space-x-1 group text-atherGray-300 hover:text-atherGray-0"
        onClick={() => onSelectView(tab!.view)}
      >
        <span className="text-xs">{tab?.label}</span>
        <span>
          <SharpChevronRightIcon width={16} height={16} className="text-atherGray-500" />
        </span>
      </button>
      {breadcrumbs.map((breadcrumb, index) => {
        const isFirst = index === 0
        const isLast = index === breadcrumbs.length - 1
        const isNextToLast = index === breadcrumbs.length - 2

        if (isFirst || isLast || isNextToLast)
          return (
            <button
              key={breadcrumb.id}
              className="flex items-center space-x-1 group text-atherGray-300 hover:text-atherGray-0"
              onClick={() => onBreadcrumbClick(index)}
            >
              <span className="text-xs">{breadcrumb.name}</span>
              {index !== breadcrumbs.length - 1 && (
                <span>
                  <SharpChevronRightIcon width={16} height={16} className="text-atherGray-500" />
                </span>
              )}
            </button>
          )
        if (index === 1 && breadcrumbs.length > 3)
          return (
            <button
              key={breadcrumb.id}
              className="flex items-center space-x-1 group text-atherGray-300 hover:text-atherGray-0"
              onClick={() => onBreadcrumbClick(index)}
            >
              <span className="text-xs">...</span>
              <span>
                <SharpChevronRightIcon width={16} height={16} className="text-atherGray-500" />
              </span>
            </button>
          )

        return null
      })}
    </div>
  )
}

export default Breadcrumbs
