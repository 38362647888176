import client from "@/api/client"
import { SharingPlatform } from "@/api/sdk"
import { useTempState } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import { googleAnalytics } from "@/lib/gtag"
import useModalStore from "@/lib/store"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { Fragment, useEffect, useMemo, useState } from "react"
import { FaCheck, FaCopy } from "react-icons/fa"
import { FacebookShareButton, LinkedinShareButton, TwitterShareButton } from "react-share"
import IconButton from "../IconButton"
import Modal from "../Modal"
import { cdnPublicFolderUrl } from "@/constants"

export const SocialAlphaButtons = ({
  imageId,
  urlImageDetails,
  ownerId,
  mutateShareSocial,
}: {
  imageId?: string
  urlImageDetails: string
  ownerId?: string
  mutateShareSocial: (data: { platform: SharingPlatform; imageId: string }) => void
}) => {
  const { user } = useAuth()
  const [isEnable, setIsEnable] = useState<SharingPlatform | null>(null)

  const handleSocialEvent = (social: string) => {
    googleAnalytics.event({
      action: "click",
      category: "modal",
      label: `share_social`,
      params: {
        image_id: imageId ?? "",
        share_public_link: urlImageDetails,
        platform: social,
      },
    })
  }

  const handleCloseWindowShare = (platform: SharingPlatform) => {
    if (!user || user.uid !== ownerId) return
    setIsEnable(platform)
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isEnable) return

      mutateShareSocial({
        platform: isEnable,
        imageId: imageId ?? "",
      })
    }, 5000)

    return () => {
      clearTimeout(timeout)
    }
  }, [isEnable])

  return (
    <Fragment>
      <FacebookShareButton
        onShareWindowClose={() => handleCloseWindowShare(SharingPlatform.FACEBOOK)}
        onClick={e => handleSocialEvent("facebook")}
        url={urlImageDetails}
      >
        <img className="h-6" src={`${cdnPublicFolderUrl}/images/alpha/facebook.png`} alt="FB" />
      </FacebookShareButton>
      <TwitterShareButton
        onShareWindowClose={() => handleCloseWindowShare(SharingPlatform.TWITTER)}
        onClick={e => handleSocialEvent("twitter")}
        url={urlImageDetails}
      >
        <img className="h-6" src={`${cdnPublicFolderUrl}/images/alpha/twitter.png`} alt="FB" />
      </TwitterShareButton>
      <LinkedinShareButton
        onShareWindowClose={() => handleCloseWindowShare(SharingPlatform.LINKEDIN)}
        onClick={e => handleSocialEvent("linkedin")}
        url={urlImageDetails}
        className="bg-red-400 p-4"
      >
        <img className="h-6" src={`${cdnPublicFolderUrl}/images/alpha/li.png`} alt="FB" />
      </LinkedinShareButton>
    </Fragment>
  )
}

const SharePublicModal = () => {
  const [sharingData, setSharingData] = useModalStore(state => [state.sharingData, state.setSharingData])
  const [isCopying, setIsCopying] = useTempState(false, 500)
  const [count, setCount] = useState(0)
  const { user } = useAuth()
  const qc = useQueryClient()

  const urlImageDetails = useMemo(() => {
    if (typeof window !== "undefined") {
      return `${window.location.origin}/ai-recipes/${sharingData?.id}`
    }
    return ""
  }, [sharingData])

  const [link, setLink] = useState("")

  const { mutate: mutateCreateLink, isPending: isLoading } = useMutation({
    mutationFn: () => client.api.shortUrlControllerCreateShortUrl({ longUrl: urlImageDetails }).then(res => res.data),
    onSuccess: data => {
      const linkData =
        `${
          sharingData?.shortLink
            ? `${window.location.origin}${sharingData.shortLink}/${data?.short}`
            : `${window.location.origin}/r/${data?.short}`
        }` ?? urlImageDetails
      setLink(`${linkData}`)
    },
    mutationKey: ["create_short_url", sharingData?.id],
  })

  const { mutate: mutateShareSocial } = useMutation({
    mutationFn: ({ platform, imageId }: { platform: SharingPlatform; imageId: string }) =>
      client.api.generationTrackerControllerIncreaseGenerationBySharing({
        imageId: imageId ?? "",
        platform,
      }),
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: ["user-info"] })
    },
  })

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!sharingData) return
      mutateCreateLink()
    }, 250)

    return () => {
      clearTimeout(timeout)
    }
  }, [sharingData])

  const handleCopy = () => {
    setIsCopying(true)
    setCount(prev => prev + 1)

    googleAnalytics.event({
      action: "click",
      category: "share_image",
      label: "share_link_image",
      params: {
        share_public_link: link,
      },
    })

    navigator.clipboard.writeText(link)
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!user || user.uid !== sharingData?.creator?.uid || count > 3) return

      mutateShareSocial({ platform: SharingPlatform.LINK_COPY, imageId: sharingData?.id ?? "" })
    }, 15000)

    return () => {
      clearTimeout(timer)
    }
  }, [count])

  const handleClose = () => {
    setSharingData(null)
    setCount(0)
    sharingData?.onClose?.()
  }

  return (
    <Modal
      title={"Flex to socials"}
      className="overflow-visible max-w-md text-[#EAE4D4] modal-content-child"
      isOpen={!!sharingData}
      onClose={handleClose}
    >
      {user && user.uid === sharingData?.creator?.uid && (
        <Fragment>
          {sharingData?.firstContent ? (
            sharingData?.firstContent?.map((item, index) => (
              <p className="text-sm mb-2 text-[#EAE4D4]" key={index}>
                {item}
              </p>
            ))
          ) : (
            <p className="text-sm mb-4 text-[#EAE4D4]">
              Share your image to social to earn <span className="font-semibold">1 FREE CREDIT</span> each time (upto 3
              times per image)
            </p>
          )}
        </Fragment>
      )}
      <p className="text-sm mb-2 text-[#858175]">URL</p>
      <div
        className={classNames("border border-[#313131] rounded-md p-2 flex items-center overflow-hidden mb-4", {
          "opacity-50 pointer-events-none": isLoading,
        })}
      >
        <p
          className="truncate flex-1 mr-4 text-[#EAE4D4] select-none cursor-pointer"
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
            handleCopy()
          }}
        >
          {link}
        </p>
        <AnimatePresence initial={false} mode="popLayout">
          {!isCopying ? (
            <motion.button
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              key="btn-copy"
              onClick={e => {
                e.stopPropagation()
                e.preventDefault()
                handleCopy()
              }}
            >
              <FaCopy size="0.8rem" className="fill-atherGray-400" />
            </motion.button>
          ) : (
            <motion.button initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
              <FaCheck key="btn-check" size="0.8rem" className="fill-green-500" />
            </motion.button>
          )}
        </AnimatePresence>
      </div>
      <div className="mb-4">
        <p className="text-sm text-[#858175] mb-2">Share via</p>
        <div
          className={classNames("flex space-x-4", {
            "opacity-50 pointer-events-none": isLoading,
          })}
        >
          <SocialAlphaButtons
            mutateShareSocial={mutateShareSocial}
            ownerId={sharingData?.creator?.uid}
            imageId={sharingData?.id}
            urlImageDetails={link}
          />
        </div>
      </div>
      <div>
        <p className="text-sm text-[#858175] mb-2">Preview</p>
        <div className="bg-[#313131] h-[13.5rem] overflow-hidden rounded-md">
          <img src={sharingData?.url} alt={sharingData?.name} className="max-w-[25rem] h-full object-contain m-auto" />
        </div>
      </div>
      <div className="flex items-center justify-end mt-6 space-x-2">
        <IconButton onClick={handleClose} className="px-4 font-semibold">
          Close
        </IconButton>
      </div>
    </Modal>
  )
}

export default SharePublicModal
