import IconButton from "@/components/IconButton"
import Modal from "@/components/Modal"
import { CloseIcon } from "@/components/shared/icons"
import Cropper from "react-easy-crop"
import { FaCheck } from "react-icons/fa"
import { MdClose } from "react-icons/md"

interface CropImageModalProps {
  preview: string | null
  crop: { x: number; y: number }
  zoom: number
  setCrop: ({ x, y }: { x: number; y: number }) => void
  onCropComplete: (croppedArea: any, croppedAreaPixels: any) => void
  setZoom: (v: number) => void
  showCroppedImage: () => Promise<void>
  setPreview: (v: string | null) => void
}

const CropImageModal = ({
  preview,
  crop,
  zoom,
  setCrop,
  onCropComplete,
  setZoom,
  setPreview,
  showCroppedImage,
}: CropImageModalProps) => {
  return (
    <Modal showCloseButton={false} containerClassName="" isOpen={!!preview} onClose={() => {}}>
      <div className="flex items-center justify-between w-full mb-6">
        <p className="font-semibold text-atherGray-0 text-[18px]">Upload image</p>
        <div onClick={() => setPreview(null)}>
          <CloseIcon />
        </div>
      </div>
      <div className="relative flex min-h-[380px] mb-6">
        <Cropper
          classes={{
            containerClassName: "w-full h-full",
          }}
          image={preview ?? ""}
          crop={crop}
          zoom={zoom}
          aspect={1 / 1}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <div className="flex items-center justify-end w-full spacing-x-2">
        <div className="p-2 px-4 mr-3 rounded-lg cursor-pointer bg-atherGray-800" onClick={() => setPreview(null)}>
          <p className="font-semibold text-atherGray-0">Cancel</p>
        </div>
        <div className="p-2 px-4 rounded-lg bg-[#5E17EB] cursor-pointer" onClick={showCroppedImage}>
          <p className="font-semibold text-atherGray-0">Upload</p>
        </div>
      </div>
    </Modal>
  )
}
export default CropImageModal
