import client from "@/api/client"
import { SortOrder, WorkspaceMembersSortByOptions, WorkspaceRole, WorkspaceUser } from "@/api/sdk"
import { useToast } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import { googleAnalytics } from "@/lib/gtag"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { useSetting } from "../SettingProvider"
import { useUserWorkspaceDetailQuery, useWorkspaceMembersInfiniteQuery } from "@/queries"

export type FiltersProps = {
  sort:
    | {
        title: string
        value: {
          sortBy: WorkspaceMembersSortByOptions
          sortOrder: SortOrder
        }
      }
    | undefined
  filter: WorkspaceRole[]
}

const useMembers = () => {
  const { findMyWorkspace, currentWorkspaceDetail } = useSetting()
  const [selectedBulkUser, setSelectedBulkUser] = useState<string[]>([])
  const [selectedUser, setSelectedUser] = useState<WorkspaceUser>()
  const [isOpenAddMember, setIsOpenAddMember] = useState(false)

  const [searchDebounce, setSearchDebounce] = useState("")
  const [searchDebounceAddMember, setSearchDebounceAddMember] = useState("")
  const [selectedBulkAddUser, setSelectedBulkAddUser] = useState<{ id?: number; email: string; role: string }[]>([])
  const [isLimit, setIsLimit] = useState(false)

  const [filters, setFilters] = useState<FiltersProps>({
    sort: undefined,
    filter: [],
  })

  const [selectedInviteMember, setSelectedInviteMember] = useState([])
  const toast = useToast({
    position: "top-center",
  })

  const qc = useQueryClient()
  const { user, userInfoQuery } = useAuth()

  const { mutate, isPending: isLoadingDeleteAccount } = useMutation({
    mutationKey: ["delete-account", findMyWorkspace?.id],
    mutationFn: (data: string[]) =>
      client.api
        .workspaceControllerRemoveMember(findMyWorkspace?.id ?? "", {
          memberUids: data,
        })
        .then(res => res.data),
    onSuccess: () => {
      toast({
        title: "Deleted user(s)",
      })
    },
    onError: (err: Error) => {
      toast({
        status: "error",
        title: err?.message,
      })
    },
    onSettled: () => {
      setSelectedUser(undefined)
      setSelectedBulkUser([])
      qc.invalidateQueries({ queryKey: ["members-list-workspace"] })
    },
  })

  const { mutate: mutateAdd, isPending: isLoadingAddAccount } = useMutation({
    mutationKey: ["add-account", findMyWorkspace?.id],
    mutationFn: (dto: { workspaceId: string; emails: string[]; role: WorkspaceRole }) =>
      client.api
        .workspaceControllerAddMember(dto.workspaceId, {
          emails: dto.emails,
          role: dto.role,
        })
        .then(res => res.data),
    onSuccess: () => {
      setSelectedBulkAddUser([])
      setIsOpenAddMember(false)
      toast({
        title: "Added user(s)",
      })
    },
    onError: (err: Error) => {
      if (
        (err.message?.startsWith("Unable to invite") || err.message?.startsWith("Unable to add member")) &&
        (currentWorkspaceDetail?.subscriptionPackageId?.toLowerCase() === "free" ||
          currentWorkspaceDetail?.subscriptionPackageId?.toLowerCase() === "newbie")
      ) {
        setIsLimit(true)
        return
      }

      toast({
        status: "error",
        title: "Error",
        message: [err?.message],
      })
    },
    onSettled: () => {
      setSelectedUser(undefined)
      qc.invalidateQueries({ queryKey: ["members-list-workspace"] })
    },
  })

  const { mutate: mutateUpdateUserRole, isPending: isLoadingUpdateUserRole } = useMutation({
    mutationFn: ({ id, role }: { id: number; role: WorkspaceRole }) =>
      client.api.workspaceControllerUpdateWorkspaceMemberRole(id, {
        role,
      }),
    onSuccess: () => {
      setSelectedBulkAddUser([])
      toast({
        title: "Updated user",
      })
    },
    onError: (err: Error) => {
      if (
        (err.message?.startsWith("Unable to invite") || err.message?.startsWith("Unable to add member")) &&
        (currentWorkspaceDetail?.subscriptionPackageId?.toLowerCase() === "free" ||
          currentWorkspaceDetail?.subscriptionPackageId?.toLowerCase() === "newbie")
      ) {
        setIsLimit(true)
        return
      }

      toast({
        status: "error",
        title: "Error",
        message: [err?.message],
      })
    },
    onSettled: () => {
      setSelectedUser(undefined)
      qc.invalidateQueries({ queryKey: ["members-list-workspace"] })
    },
  })

  useEffect(() => {
    googleAnalytics.event({
      action: "filter",
      category: "settings_account",
      label: `filter change`,
      params: {
        search_debounce: searchDebounce.toString(),
        filters: JSON.stringify(filters),
        workspace_id: findMyWorkspace?.id ?? "",
        user_id: userInfoQuery?.data?.uid ?? "",
        user_username: userInfoQuery?.data?.username ?? "",
      },
      value: 1,
    })
  }, [searchDebounce, filters])

  const {
    flattenData: mappedWorkspaceMembers = [],
    isLoading: isLoadingWorkspaceMembers,
    isError,
    total,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useWorkspaceMembersInfiniteQuery({
    variables: {
      workspaceId: findMyWorkspace?.id ?? "",
      searchTerm: searchDebounce,
      roles: filters.filter.length === 0 ? undefined : filters.filter,
      sortBy: filters.sort?.value.sortBy,
      sortOrder: filters.sort?.value.sortOrder,
    },
  })

  const mutateDeleteAccount = (uuids: string[]) => {
    if (!!findMyWorkspace?.id) {
      mutate(uuids)
    }
  }

  const handleSelectedAll = () => {
    if (mappedWorkspaceMembers) {
      const filter = mappedWorkspaceMembers?.filter(i => {
        if (user?.uid !== i.user.uid) return true
        return false
      })

      setSelectedBulkUser(filter.map(i => i.user.uid) ?? [])
    }
  }

  const handleClearAll = () => {
    setSelectedBulkUser([])
  }

  return {
    members: {
      mappedWorkspaceMembers,
      isLoadingWorkspaceMembers,
      hasNextPage,
      fetchNextPage,
      isFetching,
      isError,
      total,
    },
    dataUserWorkspaceById: currentWorkspaceDetail,
    selectedBulkUser,
    setSelectedBulkUser,
    isLimit,
    setIsLimit,
    selectedUser,
    setSelectedUser,
    mutateDeleteAccount,
    isLoadingDeleteAccount,
    handleSelectedAll,
    isOpenAddMember,
    setIsOpenAddMember,
    handleClearAll,
    selectedInviteMember,
    setSelectedInviteMember,
    search: {
      searchDebounce,
      setSearchDebounce,
      searchDebounceAddMember,
      setSearchDebounceAddMember,
    },
    selectedBulkAddUser,
    setSelectedBulkAddUser,
    mutateAdd,
    isLoadingAddAccount,
    filters,
    setFilters,
    mutateUpdateUserRole,
    isLoadingUpdateUserRole,
  }
}

export { useMembers }
