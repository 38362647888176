import client from "@/api/client"
import { LeaveWorkspacesDto, WorkspaceWithRole } from "@/api/sdk"
import { useToast } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import { googleAnalytics } from "@/lib/gtag"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useState } from "react"
import { useSetting } from "../SettingProvider"

const useSharedWorkspace = () => {
  const { findMySharedWorkspace } = useSetting()
  const { userInfoQuery } = useAuth()
  const [selectedBulkWorkspace, setSelectedBulkWorkspace] = useState<string[]>([])
  const [selectedWorkspaces, setSelectedWorkspaces] = useState<WorkspaceWithRole[] | undefined>(undefined)
  const toast = useToast({
    position: "top-center",
  })
  const { tokenFormat } = useAuth()
  const qc = useQueryClient()
  const { queryKey } = useSetting()

  const handleRefresh = (workSpaceIds: string[]) => {
    if (!userInfoQuery?.data?.currentWorkspace) return

    if (workSpaceIds.includes(userInfoQuery?.data?.currentWorkspace.id)) {
      window.location.href = "/workspace"
    }
    setSelectedWorkspaces(undefined)
  }

  const { mutate: mutateLeaveWorkspace, isPending: isLoadingMutateLeaveWorkspace } = useMutation({
    mutationKey: ["leave-workspace"],
    mutationFn: (data: { workSpaceId: string }) =>
      client.api.workspaceControllerLeaveWorkspace(data.workSpaceId).then(res => res.data),
    onSuccess: (_, { workSpaceId }) => {
      toast({
        title: "Leaved workspace",
      })
      handleRefresh([workSpaceId])
    },
    onError: (err: Error) => {
      toast({
        status: "error",
        title: err?.message,
      })
    },
    onSettled: (_data, _err) => {
      setSelectedBulkWorkspace([])
      qc.invalidateQueries({ queryKey })
    },
  })

  const { mutate: mutateLeaveWorkspaces, isPending: isLoadingMutateLeaveWorkspaces } = useMutation({
    mutationKey: ["leave-workspaces"],
    mutationFn: (data: LeaveWorkspacesDto) => client.api.workspaceControllerLeaveWorkspaces(data).then(res => res.data),
    onSuccess: (_, { workspaceIds }) => {
      toast({
        title: "Leaved workspace successfully!",
      })
      handleRefresh(workspaceIds)
    },
    onError: (err: Error) => {
      toast({
        status: "error",
        title: err?.message,
      })
    },
    onSettled: () => {
      setSelectedWorkspaces(undefined)
      setSelectedBulkWorkspace([])
      qc.invalidateQueries({ queryKey })
    },
  })

  const handleSelectedAll = () => {
    if (findMySharedWorkspace) {
      setSelectedBulkWorkspace(findMySharedWorkspace.map(i => i.id) ?? [])
    }
  }

  const handleLeaveWorkspaces = (workspaceIds: string[]) => {
    googleAnalytics.event({
      action: "click",
      category: "settings_account",
      label: `delete workspace's member`,
      params: {
        user_id: userInfoQuery?.data?.uid ?? "",
        user_username: userInfoQuery?.data?.username ?? "",
        workspace_ids: workspaceIds.join(", "),
        user_deleted_id: selectedBulkWorkspace.toString(),
      },
      value: 1,
    })

    if (!!tokenFormat && !!selectedWorkspaces && selectedWorkspaces.length > 0)
      if (selectedWorkspaces.length === 1) {
        mutateLeaveWorkspace({
          workSpaceId: workspaceIds[0],
        })
      } else {
        mutateLeaveWorkspaces({
          workspaceIds,
        })
      }
    else {
      toast({
        status: "error",
        title: "TokenFormat: " + tokenFormat + "workspaceId: " + selectedWorkspaces?.join(", "),
      })
    }
  }

  return {
    selectedBulkWorkspace,
    setSelectedBulkWorkspace,
    handleSelectedAll,
    findMySharedWorkspace,
    selectedWorkspaces,
    setSelectedWorkspaces,
    handleLeaveWorkspaces,
    isLoadingMutateLeaveWorkspace,
    mutateLeaveWorkspaces,
    isLoadingMutateLeaveWorkspaces,
  }
}

export { useSharedWorkspace }
