import React from "react"

export const CurveTopVector = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="39" height="43" viewBox="0 0 39 43" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        id="Vector 2985"
        d="M0.202243 0V9.25146C2.60007 35.3055 25.7349 42.6996 38.2022 43H0.202243V9.25146C-0.0604853 6.39674 -0.0742556 3.31799 0.202243 0Z"
        fill="#1E1E1E"
      />
    </svg>
  )
}
