import { cdnUrl } from "@/constants"
import { useOutsideClick } from "@/hooks"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { useReferralStore } from "@/stores"
import { useQueryClient } from "@tanstack/react-query"
import { AnimatePresence, motion } from "framer-motion"
import Link from "next/link"
import useCustomRouter from "@/hooks/useCustomRouter"
import { Fragment, useRef, useState } from "react"
import { FaChevronDown } from "react-icons/fa"
import {
  MdAutoFixHigh,
  MdLogout,
  MdOutlineInsertDriveFile,
  MdOutlinePersonAddAlt,
  MdSupportAgent,
} from "react-icons/md"
import SignInRequiredButton from "../Explore/SignInRequiredButton"
import UserBadge from "../UserBadge"

interface UserBadgeProps {
  isDisabledInfo?: boolean
}

const UserInfo = ({ isDisabledInfo = true }: UserBadgeProps) => {
  const { user, logout, userInfoQuery } = useAuth()
  const [isOpen, setIsOpen] = useState(false)
  const ref = useRef(null)
  const setReferralModal = useReferralStore(state => state.setReferralModal)
  const router = useCustomRouter()
  const qc = useQueryClient()
  const referralCode = router.query.referralCode as string

  useOutsideClick({
    enabled: isOpen,
    ref: ref,
    handler: () => {
      setIsOpen(false)
    },
  })

  const totalCredits = userInfoQuery?.data?.availableGeneration ?? 0

  if (!userInfoQuery?.data || !user)
    return (
      <SignInRequiredButton
        className="max-w-[7rem] rounded-xl bg-atherPurple-500 p-2 mr-4 font-semibold text-[#EFE8FD]"
        text="Try it free"
      />
    )

  return (
    <Fragment>
      <div className="bg-[#1E1E1E] py-1 px-2 flex justify-center items-center font-semibold text-[#EAE4D4] rounded-lg relative">
        <div
          className="bg-[#1E1E1E] absolute -right-[0.45rem] top-1/2 -translate-y-1/2 w-2 h-2"
          style={{
            clipPath: " polygon(80% 50%, 0 0, 0 100%)",
          }}
        />
        <p className="text-xs xl:text-base">
          {totalCredits} {totalCredits > 1 ? "CREDITS" : "CREDIT"}
        </p>
      </div>
      <div ref={ref} className="relative px-4">
        <div
          onClick={() => {
            googleAnalytics.event({
              action: "click",
              category: "navbar_alpha",
              label: "user_info",
              value: 1,
            })

            setIsOpen(!isOpen)
          }}
          className="flex items-center justify-between w-full cursor-pointer"
        >
          <UserBadge
            isDisabledInfo={isDisabledInfo}
            picture={userInfoQuery.data?.picture}
            name={userInfoQuery.data?.name}
            email={userInfoQuery.data?.email}
            size="sm"
          />
          <FaChevronDown size="0.8rem" />
        </div>
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0.5, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0.5, height: 0 }}
              className="absolute top-full translate-y-6 mt-1 right-0 sm:right-0 w-[14rem] z-20 overflow-hidden shadow-xl"
            >
              <div className="py-4 text-sm bg-[#1E1E1E] border border-[#313131] rounded-xl">
                <div className="px-4">
                  <div className="pb-2 mb-2 border-b  border-atherGray-600">
                    <p className="font-semibold truncate">{userInfoQuery.data.name}</p>
                    <p className="text-sm truncate text-whiteAlpha-700">{userInfoQuery.data.email}</p>
                  </div>
                </div>
                <div className="flex flex-col items-start px-3 space-y-2">
                  <button
                    className="flex items-center p-1 hover:bg-[#313131] w-full rounded-md"
                    onClick={() => {
                      googleAnalytics.event({
                        action: "click",
                        category: "navbar_alpha",
                        label: "/settings/account",
                        value: 1,
                      })
                      setReferralModal(true)
                      setIsOpen(false)
                    }}
                  >
                    <MdOutlinePersonAddAlt className="mr-2" />
                    Referral
                  </button>
                  <Link
                    className="flex items-center p-1 hover:bg-[#313131] w-full rounded-md"
                    onClick={() => {
                      googleAnalytics.event({
                        action: "click",
                        category: "navbar_alpha",
                        label: "/ai-recipes/view-all",
                        value: 1,
                      })
                    }}
                    href={`/ai-recipes/view-all${referralCode ? `?referralCode=${referralCode}` : ""}`}
                    rel="noopener noreferrer"
                  >
                    <MdAutoFixHigh className="mr-2" />
                    Generated Images
                  </Link>
                  <Link
                    className="flex items-center p-1 hover:bg-[#313131] w-full rounded-md"
                    onClick={() => {
                      googleAnalytics.event({
                        action: "click",
                        category: "navbar_alpha",
                        label: "/terms-of-service",
                        value: 1,
                      })
                    }}
                    href="/terms-of-service"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <MdOutlineInsertDriveFile className="mr-2" />
                    Term & Conditions
                  </Link>
                  <Link
                    onClick={() => {
                      googleAnalytics.event({
                        action: "click",
                        category: "navbar_alpha",
                        label: `mailto:hello@${cdnUrl}`,
                        value: 1,
                      })
                    }}
                    href={`mailto:hello@${cdnUrl}`}
                    target="_blank"
                    className="flex items-center p-1 hover:bg-[#313131] w-full rounded-md"
                    rel="noopener noreferrer"
                  >
                    <MdSupportAgent className="mr-2" />
                    Contact Us
                  </Link>
                  <div className="border-t border-[#313131] w-full" />
                  <button
                    onClick={() => {
                      googleAnalytics.event({
                        action: "click",
                        category: "navbar_alpha",
                        label: "logout",
                        value: 1,
                      })
                      qc.clear()
                      logout()
                      setIsOpen(false)
                    }}
                    className="text-atherError-200 flex items-center w-full pl-1.5 rounded-md p-1 hover:bg-[#313131]"
                  >
                    <MdLogout className="mr-2" />
                    Log Out
                  </button>
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </Fragment>
  )
}

export default UserInfo
