export const ComputerIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M23 4H20.0077C13.4461 4 10.1653 4 7.83966 5.62763C6.97925 6.22981 6.23088 6.97781 5.62842 7.83781C4 10.1623 4 13.4415 4 20C4 26.5585 4 29.8377 5.62842 32.1622C6.23088 33.0222 6.97925 33.7702 7.83966 34.3724C10.1653 36 13.4461 36 20.0077 36H28.0116C34.5732 36 37.854 36 40.1796 34.3724C41.04 33.7702 41.7884 33.0222 42.3909 32.1622C43.6482 30.3674 43.9347 28.0033 44 24"
        stroke="#D5D0C1"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path d="M30 4L37 11M37 11L44 18M37 11L44 4M37 11L30 18" stroke="#9E74F3" strokeWidth="3" strokeLinecap="round" />
      <path d="M22 30H26" stroke="#D5D0C1" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M24 36V44" stroke="#D5D0C1" strokeWidth="3" />
      <path d="M16 44H32" stroke="#D5D0C1" strokeWidth="3" strokeLinecap="round" />
    </svg>
  )
}
