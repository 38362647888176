// SafeAreaView.tsx
import React from "react"

interface SafeAreaViewProps {
  children: React.ReactNode
  style?: React.CSSProperties
  className?: string
  as?: React.ElementType
  id?: string
}

const SafeAreaView: React.FC<SafeAreaViewProps> = ({ children, style, className, as, id }) => {
  const Component = as || "div"

  return (
    <Component id={id} className={className} style={{ ...defaultStyle, ...style }}>
      {children}
    </Component>
  )
}

const defaultStyle: React.CSSProperties = {
  paddingTop: "env(safe-area-inset-top)",
  paddingRight: "env(safe-area-inset-right)",
  paddingBottom: "env(safe-area-inset-bottom)",
  paddingLeft: "env(safe-area-inset-left)",
  boxSizing: "border-box",
}

export default SafeAreaView
