import client from "@/api/client"
import { FeedbackResponse, ImageSimpleDetail, ImageSortByColumn, RateDto, SharingPlatform } from "@/api/sdk"
import { cdnPublicFolderUrl, imageExtensionRegex } from "@/constants"
import { useAuthenticatedInfinityQuery, useAuthenticatedQuery, useTempState } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import { googleAnalytics } from "@/lib/gtag"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import classNames from "classnames"
import { AnimatePresence, motion } from "framer-motion"
import { Fragment, memo, useEffect, useState } from "react"
import { FaCheck, FaCopy } from "react-icons/fa"
import { MdClose } from "react-icons/md"
import IconButton from "../IconButton"
import { SocialAlphaButtons } from "../ModalsPortal/SharePublicModal"
import { options } from "./Recipes/FormAnimeRecipe"
import { Radio } from "./View/GalleryFilter"

const dataFeedback = [
  {
    id: FeedbackResponse.HAPPY,
    url: "/images/alpha/hearts.png",
  },
  {
    id: FeedbackResponse.NEUTRAL,
    url: "/images/alpha/smiling.png",
  },
  {
    id: FeedbackResponse.SAD,
    url: "/images/alpha/frowning.png",
  },
].map(i => ({ ...i, url: `${cdnPublicFolderUrl}${i.url}` }))

const recipeOptions = [
  {
    display: "Anime",
    value: "anime-style-transformer",
  },
  {
    display: "Art Toy",
    value: "reskin-art-toy",
  },
  {
    display: "QR Code",
    value: "qrcode-art",
  },
  {
    display: "Photoshoot",
    value: "commercial-photoshoot",
  },
  {
    display: "Logo Art",
    value: "logo-art",
  },
  {
    display: "SpookifyMe",
    value: "spookifyme",
  },
  {
    display: "Miss Universe",
    value: "miss-universe",
  },
]

const FeedBack = () => {
  const { user } = useAuth()
  const qc = useQueryClient()

  const take = 10

  const [isCopying, setIsCopying] = useTempState(false, 500)
  const [isOpen, setIsOpen] = useState(false)
  const [step, setStep] = useState(0)
  const [count, setCount] = useState(0)
  const [links, setLinks] = useState<{ id: string; link: string; name: string; url: string }[]>([])
  const [link, setLink] = useState("")
  const [historyParams, setHistoryParams] = useState<ImageSimpleDetail | null>(null)
  const [isFetched, setIsFetched] = useState(false)
  const [selectedRecipe, setSelectedRecipe] = useState<string | null>(null)
  const [selectedStyle, setSelectedStyle] = useState<string | null>(null)

  const { data: initialImages } = useAuthenticatedInfinityQuery(
    ["recipe-images", user?.uid],
    ({ pageParam = 0 }) =>
      client.api
        .imageControllerSearchImages({
          folderId: null,
          recipeIds: [
            "anime-style-transformer",
            "reskin-art-toy",
            "qrcode-art",
            "commercial-photoshoot",
            "logo-art",
            "spookifyme",
            "miss-universe",
          ],
          skip: pageParam * take,
          take,
          sortBy: ImageSortByColumn.CreatedAt,
          sortOrder: "DESC",
          onlyMine: true,
        })
        .then(res => res.data),
    {
      getNextPageParam: (lastPage, pages) => (lastPage && lastPage.images.length < take ? undefined : pages.length),
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      enabled: !!user,
    },
  )

  const { data: userStylesSuccess, refetch: refetchFeedback } = useAuthenticatedQuery(
    ["feedback-alpha", user],
    () => client.api.feedbackControllerGetFeedback().then(res => res.data),
    {
      enabled: !!user && !isFetched,
    },
  )

  const { mutate: mutateShareSocial } = useMutation({
    mutationFn: ({ platform, imageId }: { platform: SharingPlatform; imageId: string }) =>
      client.api.generationTrackerControllerIncreaseGenerationBySharing({
        imageId: imageId ?? "",
        platform,
      }),
    onSuccess: () => {
      qc.invalidateQueries({ queryKey: ["user-info"] })
    },
  })

  const { mutate: mutateFeedback, isPending: isLoadingMutate } = useMutation({
    mutationFn: (data: RateDto) => client.api.feedbackControllerRate(data),
    onSuccess: (data, { response }) => {
      if (response === FeedbackResponse.HAPPY) {
        setStepTimeout(2)

        if (userStylesSuccess && userStylesSuccess.length > 0) {
          setTimeout(() => {
            handleClose()
          }, 5000)
        }

        return
      }

      if (response === FeedbackResponse.NEUTRAL) {
        setStepTimeout(1)
        setTimeout(() => {
          handleClose()
        }, 5000)

        return
      }

      if (response === FeedbackResponse.SAD) {
        setStepTimeout(3)
        setTimeout(() => {
          handleClose()
        }, 5000)

        return
      }
    },
    onSettled: () => {
      qc.invalidateQueries({ queryKey: ["user-info"] })
      refetchFeedback()
    },
    onError: () => {
      setStepTimeout(1)
      setTimeout(() => {
        handleClose()
      }, 5000)
    },
  })

  const isDisabled = userStylesSuccess && userStylesSuccess?.length > 1
  const metadataHistory = historyParams && Array.isArray(historyParams.metadata) ? historyParams.metadata : []
  const mappedFlat = initialImages?.pages.reduce((acc: ImageSimpleDetail[], page) => [...acc, ...page.images], [])

  useEffect(() => {
    setIsOpen(false)
    setHistoryParams(null)

    if (!user) return

    if (!initialImages || !userStylesSuccess || !mappedFlat) return

    const filterCreated = initialImages?.pages[0].images.find(i => i.recipe?.id)
    if (!filterCreated) return

    if (
      localStorage
        .getItem("skip-feedback")
        ?.split(",")
        .includes(filterCreated.recipeTask?.id ?? "")
    )
      return

    const finalMeta = Array.isArray(filterCreated.metadata) ? filterCreated.metadata : []

    const style = finalMeta.find(i => i.key === "style")?.value ?? ""
    const recipeId = filterCreated.recipe?.id
    const recipeTaskId = filterCreated.recipeTask?.id

    if ((!style && !recipeId) || userStylesSuccess.map(i => i.recipeTaskId).includes(recipeTaskId ?? "")) return

    if (
      recipeId === "anime"
        ? userStylesSuccess?.find(i => i.style === style)
        : userStylesSuccess?.find(i => i.style === recipeId)
    )
      return

    setSelectedRecipe(recipeId ?? null)
    setSelectedStyle(style)

    setIsOpen(true)

    if (mappedFlat.filter(i => i.recipeTask?.id === recipeTaskId).length > 1) {
      setLinks(
        mappedFlat
          .filter(i => i.recipeTask?.id === recipeTaskId)
          .map(i => ({
            id: i.id,
            link: `${window.location.origin}/ai-recipes/${i.id}`,
            name: i.name ?? i.id,
            url: i.url ?? "",
          })),
      )
    }

    setHistoryParams(prev => {
      if (prev?.recipeTask?.id === recipeTaskId) return prev

      setStepTimeout(0)
      return filterCreated
    })
  }, [initialImages, userStylesSuccess, user])

  const handleCopy = () => {
    navigator.clipboard.writeText(link)
    setIsCopying(true)
    setCount(prev => prev + 1)

    googleAnalytics.event({
      action: "click",
      category: "share_image_alpha",
      label: "share_link_image",
      params: {
        share_public_link: link,
      },
    })
  }

  const setStepTimeout = (step: number) => {
    setIsOpen(false)

    setTimeout(() => {
      setStep(step)
      setIsOpen(true)
    }, 1000)
  }

  const handleClose = (isSkip?: boolean) => {
    setIsOpen(false)
    setStep(0)
    setLink("")
    setSelectedRecipe(null)
    setSelectedStyle(null)
    setLinks([])
    setHistoryParams(null)

    googleAnalytics.event({
      action: "click",
      category: "feedback_alpha",
      label: "feedback",
      params: {
        email: user?.email ?? "",
        style: metadataHistory?.find(i => i.key === "style")?.value ?? "",
        recipe_id: historyParams?.recipe?.id ?? "",
        task_id: historyParams?.id ?? "",
        step: step,
        response: "CLOSE",
      },
    })

    if (isSkip) {
      let oldData = localStorage.getItem("skip-feedback")?.split(",") ?? []

      if (oldData.includes(historyParams?.recipeTask?.id ?? "")) return

      oldData.push(historyParams?.recipeTask?.id ?? "")

      localStorage.setItem("skip-feedback", oldData.join(","))
    }
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!user || (historyParams?.owner && user.uid !== historyParams?.owner.uid) || count > 5 || !link) return

      mutateShareSocial({ platform: SharingPlatform.LINK_COPY, imageId: links?.find(i => i.link === link)?.id ?? "" })
    }, 5000)

    return () => {
      clearTimeout(timer)
    }
  }, [count])

  const handleClickIcon = (id: FeedbackResponse) => {
    mutateFeedback({
      recipeTaskId: historyParams?.recipeTask?.id ?? "",
      response: id,
      style: historyParams?.recipe?.id === "anime" ? selectedStyle ?? "anime" : selectedRecipe ?? "art-toy",
    })

    googleAnalytics.event({
      action: "click",
      category: "feedback_alpha",
      label: "feedback",
      params: {
        email: user?.email ?? "",
        style: metadataHistory?.find(i => i.key === "style")?.value ?? "",
        recipe_id: historyParams?.recipe?.id ?? "",
        task_id: historyParams?.id ?? "",
        creative_intensity: metadataHistory?.find(i => i.key === "creative_intensity")?.value ?? "",
        step: step,
        response: id,
      },
    })
  }

  useEffect(() => {
    if (isOpen && links.length > 0 && userStylesSuccess && userStylesSuccess.length <= 1) {
      Promise.all(
        links.map(link =>
          client.api
            .shortUrlControllerCreateShortUrl({ longUrl: link.link })
            .then(res => res.data)
            .then(data => ({
              id: link.id,
              link: `${window.location.origin}/r/${data?.short}` ?? link.link,
            })),
        ),
      )
      setLink(links[0].link)
    }
  }, [isOpen, links, userStylesSuccess])

  const renderStep = () => {
    if (isLoadingMutate) return null

    if (step === 1) {
      return (
        <Fragment>
          <IconButton
            onClick={e => {
              e.stopPropagation()
              handleClose()
            }}
            className="absolute top-0 right-0 bg-transparent hover:bg-transparent"
          >
            <MdClose size="1.2rem" />
          </IconButton>
          <p className="font-semibold mb-1">Thank you, mate!</p>
          <p className="text-sm font-[300]">We value your feedback</p>
        </Fragment>
      )
    }

    if (step === 2) {
      return (
        <Fragment>
          <IconButton
            onClick={e => {
              e.stopPropagation()
              handleClose()
            }}
            className="absolute top-0 right-0 bg-transparent hover:bg-transparent"
          >
            <MdClose size="1.2rem" />
          </IconButton>
          {!isDisabled ? (
            <Fragment>
              <p className="font-semibold mb-1">Flex to social</p>
              <p className="text-sm font-[300]">
                Share your favourite image to social to earn <span className="font-semibold">1 FREE CREDIT</span> each
                time (up to 3 times per image)
              </p>

              <div className="grid grid-cols-2 mt-2 mb-4">
                {links?.map(image => (
                  <div onClick={() => setLink(image.link)} className="p-1 cursor-pointer" key={image.id}>
                    <img
                      className={classNames("rounded-xl border-[2px]", {
                        "border-transparent": link !== image.link,
                        "border-[#7053FF]": link === image.link,
                      })}
                      src={`${image.url?.replace(imageExtensionRegex, "")}.w400.webp`}
                      srcSet={image.url}
                      alt=""
                    />
                  </div>
                ))}
              </div>
              <p className="text-sm mb-2">URL</p>
              <div
                className={classNames("border border-[#717171] rounded-md p-2 flex items-center overflow-hidden mb-4")}
              >
                <p
                  className="truncate text-sm flex-1 mr-4 text-[#EAE4D4] select-none cursor-pointer"
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    handleCopy()
                  }}
                >
                  {link}
                </p>
                <AnimatePresence initial={false} mode="popLayout">
                  {!isCopying ? (
                    <motion.button
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      exit={{ opacity: 0 }}
                      key="btn-copy"
                      onClick={e => {
                        e.stopPropagation()
                        e.preventDefault()
                        handleCopy()
                      }}
                    >
                      <FaCopy size="0.8rem" className="fill-atherGray-400" />
                    </motion.button>
                  ) : (
                    <motion.button initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
                      <FaCheck key="btn-check" size="0.8rem" className="fill-green-500" />
                    </motion.button>
                  )}
                </AnimatePresence>
              </div>
              <div className="">
                <p className="text-sm text-[#858175] mb-2">Share via</p>
                <div className={classNames("flex space-x-4")}>
                  <SocialAlphaButtons
                    mutateShareSocial={mutateShareSocial}
                    ownerId={historyParams?.owner?.uid}
                    imageId={links.find(i => i.link === link)?.id ?? ""}
                    urlImageDetails={link}
                  />
                </div>
              </div>
            </Fragment>
          ) : (
            <Fragment>
              <div className="p-4 max-w-[14rem]">
                <img src={`${cdnPublicFolderUrl}/images/alpha/love.png`} alt="love" />
              </div>
              <p className="text-base font-semibold text-center">
                OMG! You love it? <br />
                Let&apos;s go Flex!!!
              </p>
            </Fragment>
          )}
        </Fragment>
      )
    }

    if (step === 3) {
      return (
        <Fragment>
          <IconButton
            onClick={e => {
              e.stopPropagation()
              handleClose()
            }}
            className="absolute top-0 right-0 bg-transparent hover:bg-transparent"
          >
            <MdClose size="1.2rem" />
          </IconButton>
          <div className="p-4 max-w-[14rem]">
            <img src={`${cdnPublicFolderUrl}/images/alpha/sad.png`} alt="sad " />
          </div>
          <p className="text-base font-semibold text-center">
            We&apos;re improving <br /> Please stay with us!
          </p>
        </Fragment>
      )
    }

    return (
      <Fragment>
        <IconButton
          onClick={e => {
            e.stopPropagation()
            handleClose(true)
          }}
          className="absolute top-0 right-0 bg-transparent hover:bg-transparent"
        >
          <MdClose size="1.2rem" />
        </IconButton>
        <p className="font-semibold mb-1">Help us improve!</p>
        <p className="text-sm font-[300] mb-2">
          Share your feedback & Earn <span className="font-semibold">1 CREDIT</span>
        </p>
        <div className="border-t border-[#EAE4D4] mb-2" />
        <p className="text-sm font-semibold mb-2">Have you tried?</p>
        <div className="grid grid-cols-2 gap-2 text-sm mb-2">
          {recipeOptions
            .filter(i =>
              i.value === "anime"
                ? userStylesSuccess && userStylesSuccess?.filter(i => i.style === "anime").length < 5
                : !userStylesSuccess?.map(s => s.style).includes(i.value),
            )
            .map(i => (
              <Radio
                key={i.display}
                checked={selectedRecipe?.toLocaleLowerCase() === i.value.toLowerCase()}
                onChange={() => {
                  setSelectedRecipe(i.value)
                  if (i.value === "anime") {
                    setSelectedStyle(
                      options.filter(i => !userStylesSuccess?.map(o => o.style).includes(i.value))[0].value,
                    )
                  }
                }}
                name={i.display}
                value={i.value}
              />
            ))}
        </div>
        {selectedRecipe === "anime" && (
          <div className="flex flex-wrap my-2 -ml-0.5">
            {options
              .filter(i => !userStylesSuccess?.map(o => o.style).includes(i.value))
              .map(i => (
                <div
                  className={classNames("cursor-pointer m-0.5 flex-shrink-0  border py-1 px-2 rounded-lg select-none", {
                    "bg-[#718096] border-[#4A5568]": selectedStyle === i.value,
                    "border-[#4A5568] bg-[#171923]": selectedStyle !== i.value,
                  })}
                  key={i.display}
                  onClick={() => {
                    setSelectedStyle(i.value)
                  }}
                >
                  <p className={classNames("text-sm font-[300] text-center")}>{i.display}</p>
                </div>
              ))}
          </div>
        )}
        <div className="flex items-center space-x-2 justify-center">
          {dataFeedback.map(item => (
            <div
              key={item.id}
              onClick={() => handleClickIcon(item.id)}
              className="flex-1 flex justify-center cursor-pointer rounded-md p-0.5 hover:bg-[#313131] select-none"
            >
              <img src={item.url} className="w-8 h-8" alt={item.id.toString()} />
            </div>
          ))}
        </div>
      </Fragment>
    )
  }

  if (!userStylesSuccess || userStylesSuccess.length >= 6) return null

  return (
    <AnimatePresence mode="wait">
      {isOpen && (
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={{ duration: 0.5 }}
          key={step}
          className={classNames(
            "fixed bottom-4 right-4 z-50 bg-[#464646] shadow-lg rounded-xl overflow-hidden max-w-[18.5rem]",
            {
              "p-4": !isLoadingMutate,
            },
          )}
        >
          {renderStep()}
        </motion.div>
      )}
    </AnimatePresence>
  )
}

export default memo(FeedBack)
