import IconButton from "@/components/IconButton"
import Modal from "@/components/Modal"
import { cdnUrl } from "@/constants"
import { googleAnalytics } from "@/lib/gtag"
import { useAuth } from "@/providers/authContext"
import { formatEmail } from "@/utils/parser"
import classNames from "classnames"
import PasscodeInput from "../PasscodeInput"
import { useSecurity } from "../useSecurity"
import { isTelegramEmail } from "@/utils/is-email-address"

interface EnterCodeModalProps {
  stateModel: ReturnType<typeof useSecurity>["stateModel"]
  setStateModel: ReturnType<typeof useSecurity>["setStateModel"]
  handleSendCode: ReturnType<typeof useSecurity>["handleSendCode"]
  isLoadingSendCode: boolean
  setValue: ReturnType<typeof useSecurity>["setStateValue"]
  value: ReturnType<typeof useSecurity>["stateValue"]["passcode"]
  handleComplete: () => void
  isLoadingComplete: boolean
}

const EnterCodeModal = ({
  stateModel,
  setStateModel,
  handleSendCode,
  isLoadingSendCode,
  handleComplete,
  isLoadingComplete,
  value,
  setValue,
}: EnterCodeModalProps) => {
  const { userInfoQuery } = useAuth()
  const { user } = useAuth()
  const isSendToTelegram =
    isTelegramEmail(user?.email ?? "") &&
    userInfoQuery?.data?.telegram?.["id"] &&
    userInfoQuery?.data?.telegram?.["allowSendPM"] === true

  return (
    <Modal
      isOpen={stateModel.delete.step2}
      onClose={() =>
        setStateModel(v => ({
          ...v,
          delete: {
            ...v.delete,
            step2: false,
          },
        }))
      }
      title="Enter Security Code"
      className="max-w-[520px] overflow-visible"
    >
      <p className="text-[#C8C4B5] text-[14px] font-[400] leading-[17.5px] mb-6 ">
        To confirm your account deletion request, please enter the security code that has been sent to your email
        address at <span className="font-bold">{formatEmail(userInfoQuery?.data?.email)}</span>. Please note that after
        14 days account deletion is irreversible. If you are having problems, please contact hello@{cdnUrl} for
        help deleting your account.
      </p>

      <p className="text-[#C8C4B5] text-[14px] font-[400] leading-[17.5px] mb-6">
        If you are having problems, please contact
        <a href={`mailto:hello@${cdnUrl}`} className="inline-block mx-1 cursor-pointer">
          <p className="font-bold">hello@{cdnUrl}</p>
        </a>
        for help deleting your account..
      </p>

      <div className="flex flex-col w-full mb-6">
        <p className="text-[#C8C4B5] text-[14px] font-[400] leading-[17.5px]">Passcode</p>
        <div>
          <p className="text-xs text-atherGray-500">
            {isSendToTelegram
              ? "Because you are signing with Telegram. Please check telegram bot @Proto_GAIA and enter the 6-digit code we sent to you."
              : "Enter the 6-digit code we sent to your email"}
          </p>
          <div className="flex flex-col h-full md:flex-row items-center">
            <PasscodeInput
              value={value}
              onChange={value => {
                setValue(v => ({
                  ...v,
                  passcode: value,
                }))
              }}
              className="w-8 h-8 text-xl text-atherGray-0 bg-atherGray-900 rounded-none border-atherGray-800 border-b-[2px]"
            />
            <div className="flex items-center justify-center h-10 w-[7rem] mt-4 md:mt-0 md:ml-4 cursor-pointer">
              <IconButton
                isLoading={isLoadingSendCode}
                colorScheme="transparent"
                className="text-atherPurple-300 p-0 bg-atherGray-900 text-sm font-semibold"
                onClick={() => {
                  googleAnalytics.event({
                    action: "click",
                    category: "settings_account",
                    label: `send_code`,
                    params: {
                      user_id: userInfoQuery?.data?.uid ?? "",
                      user_username: userInfoQuery?.data?.username ?? "",
                    },
                    value: 1,
                  })
                  handleSendCode()
                }}
              >
                Send Code
              </IconButton>
            </div>
          </div>
        </div>
      </div>

      <div className={classNames("flex justify-end space-x-2 w-full")}>
        <IconButton
          onClick={() =>
            setStateModel(v => ({
              ...v,
              delete: {
                ...v.delete,
                step2: false,
              },
            }))
          }
        >
          Cancel
        </IconButton>
        <IconButton
          isLoading={isLoadingComplete}
          disabled={value?.length !== 6 || isLoadingComplete}
          onClick={() => {
            handleComplete()
            googleAnalytics.event({
              action: "click",
              category: "settings_account",
              label: `delete account: complete`,
              params: {
                user_id: userInfoQuery?.data?.uid ?? "",
                user_username: userInfoQuery?.data?.username ?? "",
                passcode: value,
              },
              value: 1,
            })
          }}
        >
          Complete
        </IconButton>
      </div>
    </Modal>
  )
}

export default EnterCodeModal
