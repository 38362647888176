import IconButton from "@/components/IconButton"
import Input from "@/components/Input"
import Modal from "@/components/Modal"
import { useCreateFolderMutation } from "@/queries"
import { useCreateFolderV2Store } from "@/stores"
import { FormEvent, useEffect, useRef, useState } from "react"

const CreateFolderModalV2 = () => {
  const { parentFolder, setParentFolder } = useCreateFolderV2Store()

  const [input, setInput] = useState("")
  const [error, setError] = useState("")
  const inputRef = useRef<HTMLInputElement>(null)

  const { mutate: mutateCreateFolder, isPending } = useCreateFolderMutation({
    onSuccess: () => {
      setParentFolder(null)
    },
    onError: (e: any) => {
      const errorMessage = e?.response?.data?.message || "Something went wrong"
      setError(errorMessage)
    },
  })

  const handleCreateFolder = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!input) {
      setError("Folder name is required")
      return
    }

    mutateCreateFolder({ name: input, parentFolderId: parentFolder!.id })
  }

  const isOpen = !!parentFolder
  const onClose = () => setParentFolder(null)

  useEffect(() => {
    setInput("")
    setError("")

    let timeout: NodeJS.Timeout
    if (isOpen) {
      timeout = setTimeout(() => inputRef.current?.focus(), 100)
    }

    return () => {
      clearTimeout(timeout)
    }
  }, [isOpen])

  useEffect(() => {
    setError("")
  }, [input])

  return (
    <Modal className="max-w-md" title="Create New Folder" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleCreateFolder}>
        <div>
          <label className="block text-atherGray-300 text-xs mb-1">Folder name</label>
          <Input ref={inputRef} value={input} onChange={e => setInput(e.target.value)} />
          {error && <p className="mt-1 text-xs text-red-500">{error}</p>}
        </div>
        <div className="flex justify-end mt-6 space-x-2">
          <IconButton colorScheme="secondary" onClick={onClose} type="button">
            Cancel
          </IconButton>
          <IconButton isLoading={isPending} type="submit">
            Create
          </IconButton>
        </div>
      </form>
    </Modal>
  )
}

export default CreateFolderModalV2
