import { useAuth } from "@/providers/authContext"
import { useState } from "react"
import ChangeEmailModal from "./ChangeEmailModal"
import UploadAvatarProfile from "./UploadAvatarProfile"
import ProfileInfoInput from "./ProfileInfoInput"
import WalletInfo from "./WalletInfo"
import RemoveFarcasterModal from "./RemoveFarcasterModal"
import RemoveSocialModal from "./RemoveSocialModal"
import RemoveTelegramModal from "./RemoveTelegramModal"
import useScript from "@/hooks/useScript"
import SocialsConnected from "./SocialsConnected"

const Profile = () => {
  useScript("https://telegram.org/js/telegram-widget.js")
  const { user, userInfoQuery } = useAuth()

  const [modal, setModal] = useState<"WALLET" | "EMAIL" | "SOCIAL" | "FARCASTER" | "TELEGRAM" | null>(null)

  return (
    <>
      <div className="flex flex-col w-full max-w-[30rem]">
        {!!user && !!userInfoQuery?.data && (
          <>
            <p className="font-semibold text-[#FFFBEE] mb-4">Profile photo</p>
            <UploadAvatarProfile />
            <div className="flex flex-col w-full">
              <ProfileInfoInput setModal={setModal} />
              <SocialsConnected setModal={setModal} user={user} userDetail={userInfoQuery.data} />
              <WalletInfo />
            </div>
          </>
        )}
        <ChangeEmailModal
          isOpen={modal === "EMAIL"}
          onClose={() => {
            setModal(null)
          }}
        />
        <RemoveSocialModal
          isOpen={modal === "SOCIAL"}
          onClose={() => {
            setModal(null)
          }}
        />
        <RemoveFarcasterModal
          isOpen={modal === "FARCASTER"}
          onClose={() => {
            setModal(null)
          }}
        />
        <RemoveTelegramModal
          isOpen={modal === "TELEGRAM"}
          onClose={() => {
            setModal(null)
          }}
        />
      </div>
    </>
  )
}

export default Profile
