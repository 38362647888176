import client from "@/api/client"
import { ImageSimpleDetail } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import LoadingLogo from "@/components/LoadingLogo"
import Modal from "@/components/Modal"
import { useAuthenticatedInfinityQuery, useAuthenticatedQuery } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import { googleAnalytics } from "@/lib/gtag"
import useModalStore from "@/lib/store"
import { useSignInStore } from "@/stores"
import { useBeMissUniverseStore } from "@/stores/useBeMissUniverseStore"
import { useTempTaskStore } from "@/stores/useTempTaskStore"
import classNames from "classnames"
import { motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import { Fragment, useMemo, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import SubmitModal from "../SubmitModal"
import useBeMiss from "../hooks/useBeMiss"
import { CardBeMissImage } from "./BeMissUniverseOnlyTaskModal"
import ViewMyVotes from "./ViewMyVotes"

const BeMissUniverseModal = () => {
  const { isOpen, setIsOpen } = useBeMissUniverseStore()
  const { user } = useAuth()
  const take = 10
  const { tempTask } = useTempTaskStore()
  const scrollRef = useRef<HTMLDivElement>(null)
  const router = useCustomRouter()
  const [selected, setSelected] = useState<ImageSimpleDetail>()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const setSharingData = useModalStore(state => state.setSharingData)
  const setSignInModal = useSignInStore(state => state.setSignInModal)

  const { data: isChecked, isLoading: isLoadingChecked } = useAuthenticatedQuery(["check-participation"], () =>
    client.api.missUniverseControllerCheckUserParticipation().then(res => res.data),
  )

  const onClose = () => {
    if (isOpenModal || router.query.signIn) return
    setIsOpen(false)
    setSelected(undefined)
  }

  const {
    data: historyRecipes,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isError,
  } = useAuthenticatedInfinityQuery(
    ["recipe-history", user?.uid, "miss-universe", take, tempTask?.filter(i => !i.isTransfer).map(t => t.id).length],
    ({ pageParam = 0 }) =>
      client.api
        .recipeTaskControllerGetHistoryTasksPost({
          recipeIds: ["miss-universe"],
          statuses: ["COMPLETED"],
          taskIds: !user && tempTask ? tempTask.filter(i => !i.isTransfer).map(t => t.id) : undefined,
          skip: pageParam * take,
          take,
        })
        .then(res => res.data.other),
    {
      getNextPageParam: (lastPage, pages) => (lastPage && lastPage?.length < take ? undefined : pages.length),
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      enabled: isOpen,
    },
  )

  const mappedHistoryRecipes = useMemo(
    () =>
      historyRecipes?.pages.reduce(
        (acc: ImageSimpleDetail[], curr) => [
          ...acc,
          ...curr.reduce(
            (acc: ImageSimpleDetail[], curr) => [
              ...acc,
              ...(curr.fullDataImages?.map(i => ({ ...i, status: curr.status, params: curr.params })) ?? []),
            ],
            [],
          ),
        ],
        [],
      ) ?? [],
    [historyRecipes],
  )

  const { mutate: mutateBeMiss, isPending: isLoadingMutate } = useBeMiss({
    onSuccess: () => {
      setSharingData({
        id: selected?.id ?? "",
        link: null,
        name: selected?.name ?? selected?.id ?? "",
        type: "image",
        creator: selected?.owner ?? undefined,
        url: selected?.url ?? "",
        firstContent: [
          "Thanks for joining the Miss Universe Challenge!",
          "Share your photo to get more votes and and win up to $100 Gift card & 230 free Credits",
        ],
      })

      onClose()
    },
  })

  const renderListVoted = () => {
    if (isLoading)
      return (
        <div className="flex items-center justify-center flex-1 w-full text-gray-600">
          <LoadingLogo />
        </div>
      )

    if (isError)
      return (
        <div className="flex items-center justify-center flex-1 w-full">
          <p className="text-lg text-red-500">Error</p>
        </div>
      )

    if (!mappedHistoryRecipes || !mappedHistoryRecipes.length) {
      return (
        <div className="flex items-center justify-center flex-1 w-full">
          <p className="text-sm text-atherGray-500">No image found</p>
        </div>
      )
    }

    return (
      <InfiniteScroll
        loadMore={() => fetchNextPage()}
        hasMore={!!hasNextPage && !isFetching}
        threshold={800}
        useWindow={false}
        getScrollParent={() => scrollRef.current}
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "100%",
          width: "100%",
          position: "relative",
        }}
        loader={
          <div key="loader" className="flex items-center justify-center py-4">
            Loading...
          </div>
        }
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.35 }}
          className="w-full flex-1 grid grid-col grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-4 2xl:grid-cols-4"
        >
          {mappedHistoryRecipes.map(image => (
            <CardBeMissImage
              className="w-full"
              key={image.id}
              isActive={selected?.id === image.id}
              onClick={() => {
                setSelected(image)
              }}
              name={image.name ?? image.id}
              url={image.url ?? ""}
            />
          ))}
        </motion.div>
      </InfiniteScroll>
    )
  }

  if (isLoadingChecked) return null

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        className={classNames("max-w-6xl overflow-hidden max-h-(calc(100vh-4rem)) w-full h-full", {
          "h-auto max-w-xl": isChecked,
        })}
        bodyClassName="overflow-hidden"
        containerClassName="overflow-hidden"
      >
        {isChecked ? (
          <ViewMyVotes onSubmit={onClose} />
        ) : (
          <div className="flex w-full flex-col items-center flex-1 overflow-hidden">
            <p className="mb-4">Select an image you wish to join the competition</p>
            <div className="flex-1 flex-col flex overflow-auto" ref={scrollRef}>
              {renderListVoted()}
            </div>
            <div className="w-full flex justify-center pt-4 space-x-4">
              <IconButton onClick={onClose} className="text-[#EFE8FD] w-full max-w-[12rem]">
                Later
              </IconButton>
              <IconButton
                disabled={!selected}
                onClick={() => {
                  if (!user) {
                    setSignInModal({
                      signIn: true,
                    })
                    return
                  }

                  googleAnalytics.event({
                    action: "click",
                    category: "be_miss_universe_modal",
                    label: "join_miss_universe",
                    params: {
                      image_id: selected?.id ?? "",
                      image_name: selected?.name ?? "",
                      image_owner: selected?.owner.name ?? "",
                    },
                  })

                  setIsOpenModal(true)
                }}
                className="bg-atherPurple-500 hover:bg-atherPurple-500 active:bg-atherPurple-500 text-[#EFE8FD] w-full max-w-[12rem]"
              >
                {"Join Miss Universe Contest"}
              </IconButton>
            </div>
          </div>
        )}
      </Modal>
      <SubmitModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        onSubmit={() => {
          googleAnalytics.event({
            action: "click",
            category: "be_miss_universe_modal",
            label: "submit_miss_universe",
            params: {
              image_id: selected?.id ?? "",
              image_name: selected?.name ?? "",
              image_owner: selected?.owner.name ?? "",
            },
          })
          setIsOpenModal(false)
          mutateBeMiss(selected?.id ?? "")
        }}
        isLoadingSubmit={isLoadingMutate}
      />
    </Fragment>
  )
}

export default BeMissUniverseModal
