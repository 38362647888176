import IconButton from "@/components/IconButton"
import { ArrowSquareLeftIcon } from "@/components/shared/icons"
import { useScreen } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import useClientStore from "@/lib/clientStore"
import { googleAnalytics } from "@/lib/gtag"
import { cn } from "@/utils/cn"
import classNames from "classnames"
import { motion } from "framer-motion"
import { Fragment } from "react"
import SidebarBody from "./SidebarBody"

const Sidebar = () => {
  const { user } = useAuth()
  const { toggleSidebar, isOpenSidebar } = useClientStore()
  const { width } = useScreen()

  if (!user) return null

  const handleEventToggleSidebar = () => {
    googleAnalytics.event({
      action: "click",
      category: "sidebar",
      label: `toggle_sidebar`,
      value: 1,
    })
    toggleSidebar()
  }

  return (
    <Fragment>
      <motion.div
        className={cn(
          "hidden lg:block fixed top-0 lg:top-[5.5rem] left-0 bg-black lg:sticky z-[24] h-[calc(var(--vh,1vh)*100)] lg:h-[calc((var(--vh,1vh)*100)-5.5rem)]",
          "transition-all duration-300 ease-in-out ",
          {
            "w-0 p-0": !isOpenSidebar,
            "w-80 p-4": isOpenSidebar,
          },
        )}
      >
        {width > 1024 && (
          <div className="absolute top-0 left-0 pointer-events-none w-full h-full p-4">
            <div className="sidebar-homespace w-full h-full" />
          </div>
        )}
        <motion.div
          animate={{
            opacity: isOpenSidebar ? 1 : 0,
          }}
          transition={{
            duration: 0.15,
          }}
          className="overflow-hidden h-full"
        >
          <SidebarBody className={classNames("w-full p-0 overflow-y-auto overflow-x-hidden")} />
        </motion.div>
        <IconButton
          colorScheme="secondary"
          className={classNames(
            "hidden absolute top-[0] p-0 min-h-0 z-[24] lg:flex",
            "transition-all duration-300 ease-in-out",
            {
              "left-1 rotate-180": !isOpenSidebar,
              "left-[17rem]": isOpenSidebar,
            },
          )}
          onClick={handleEventToggleSidebar}
        >
          <ArrowSquareLeftIcon width={24} height={24} />
        </IconButton>
      </motion.div>
    </Fragment>
  )
}

export default Sidebar
