import { FolderDetail, SubfolderView } from "@/api/sdk"
import UserCreated from "@/components/Explore/Gallery/UserCreated"
import IconButton from "@/components/IconButton"
import Modal from "@/components/Modal"
import { useAuth } from "@/providers/authContext"
import {
  useCopyImageToFolderMutation,
  useMoveFolderMutation,
  useMoveImageMutation,
  useRootFolderQuery,
  useSubFoldersInfiniteQuery,
} from "@/queries"
import { useCreateFolderV2Store, useMoveItemV2Store } from "@/stores"
import { capitalize } from "@/utils"
import { cn } from "@/utils/cn"
import { AnimatePresence, motion } from "framer-motion"
import Image from "next/image"
import { useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import LoadingIcon from "../../LoadingIcon"
import { SharpChevronRightIcon } from "../../shared/icons"
import Breadcrumbs from "./Breadcrumbs"
import FolderTabs from "./FolderTabs"
import FolderIcon from "@/components/Workspace/Folder/FolderIcon"

const MoveItemModalV2 = () => {
  const { movingItem, setMovingItem } = useMoveItemV2Store()
  const { setParentFolder } = useCreateFolderV2Store()
  const { user } = useAuth()

  const title =
    movingItem?.type === "folder" ? `${movingItem?.action ?? "Move"} Folder` : `${movingItem?.action ?? "Move"} Image`

  const [selectedView, setSelectedView] = useState<SubfolderView>(SubfolderView.MyFolder)
  const [breadcrumbs, setBreadcrumbs] = useState<FolderDetail[]>([])
  const [selectedFolder, setSelectedFolder] = useState<FolderDetail | undefined>(undefined)
  const [destinationFolder, setDestinationFolder] = useState<FolderDetail | undefined>(undefined)
  const scrollRef = useRef<HTMLDivElement>(null)

  const isOpen = !!movingItem

  const onClose = () => {
    setMovingItem(null)
    setSelectedView(SubfolderView.MyFolder)
    setSelectedFolder(undefined)
    setBreadcrumbs([])
  }

  const { mutate: mutateMoveFolder, isPending: isMovingFolder } = useMoveFolderMutation({
    onSuccess: () => {
      onClose()
      movingItem?.onClose?.()
    },
  })

  const { mutate: mutateMoveImage, isPending: isMovingImage } = useMoveImageMutation({
    onSuccess: () => {
      onClose()
      movingItem?.onClose?.()
    },
  })

  const { mutate: mutateCopyImage, isPending: isCopyImage } = useCopyImageToFolderMutation({
    onSuccess: () => {
      onClose()
      movingItem?.onClose?.()
    },
  })

  const isPending = isMovingFolder || isMovingImage || isCopyImage

  const handleSelectView = (view: SubfolderView) => {
    setSelectedView(view)
    setSelectedFolder(undefined)
    setBreadcrumbs([])
  }

  const handleSelectFolder = (folder: FolderDetail) => {
    setSelectedFolder(folder)
    setDestinationFolder(folder)
    setBreadcrumbs([...breadcrumbs, folder])
  }

  const handleSelectDestinationFolder = (folder: FolderDetail) => {
    setDestinationFolder(folder)
  }

  const handleBreadcrumbClick = (index: number) => {
    setSelectedFolder(breadcrumbs[index])
    setBreadcrumbs(breadcrumbs.slice(0, index + 1))
  }

  const handleMove = () => {
    if (movingItem?.type === "folder") {
      mutateMoveFolder({
        folderIds: movingItem.folders.map(f => f.id),
        newParentId: destinationFolder!.id,
        oldParentId: movingItem.parentFolderId ?? "",
      })
      return
    }

    if (movingItem?.type === "image") {
      if (movingItem.action === "copy") {
        mutateCopyImage({
          oldParentId: movingItem.parentFolderId ?? "",
          imageIds: movingItem.images.map(i => i.id),
          toFolderId: destinationFolder!.id,
        })
        return
      }

      mutateMoveImage({
        oldParentId: movingItem.parentFolderId ?? "",
        folderId: destinationFolder!.id,
        imageIds: movingItem.images.map(i => i.id),
      })
      return
    }
  }

  const { data: rootFolder } = useRootFolderQuery()

  const selectedOrRootFolder = selectedFolder ?? rootFolder

  const canCreateNewFolder =
    selectedView === SubfolderView.MyFolder
      ? selectedOrRootFolder?.capabilities.canCreate
      : selectedFolder?.capabilities.canCreate

  const canMove = destinationFolder?.capabilities.canMove ?? false

  const {
    flattenData = [],
    hasNextPage,
    isLoading,
    isFetching,
    fetchNextPage,
    error,
  } = useSubFoldersInfiniteQuery({
    variables: { folderId: selectedOrRootFolder?.id, view: selectedView, canUpload: true },
    enabled: isOpen,
  })

  const filteredData = flattenData.filter(f =>
    movingItem?.action === "copy"
      ? true
      : movingItem?.type === "folder"
        ? !movingItem.folders.map(f => f.id).includes(f.id)
        : true,
  )

  return (
    <Modal className="max-w-md" title={capitalize(title)} isOpen={isOpen} onClose={onClose}>
      <div>
        <label className="text-xs text-atherGray-300 font-normal mb-2 block">Select destination</label>
        <div className="border border-atherGray-850 rounded-lg">
          <FolderTabs selectedView={selectedView} onSelectView={handleSelectView} />
          <div className="h-[20rem] overflow-hidden">
            <AnimatePresence initial={false} mode="popLayout">
              <motion.div
                ref={scrollRef}
                key={`${selectedView}-${selectedFolder?.id}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="h-full overflow-auto flex flex-col"
              >
                <InfiniteScroll
                  getScrollParent={() => scrollRef.current!}
                  loadMore={() => fetchNextPage()}
                  hasMore={!isFetching && hasNextPage}
                  useWindow={false}
                  threshold={250}
                  className="flex-col flex"
                >
                  <Breadcrumbs
                    breadcrumbs={breadcrumbs}
                    selectedView={selectedView}
                    onSelectView={handleSelectView}
                    onBreadcrumbClick={handleBreadcrumbClick}
                  />
                  <div className="p-2 space-y-1 flex-1 flex flex-col">
                    {filteredData.length > 0 ? (
                      filteredData.map(folder => (
                        <div
                          key={folder.id}
                          className={cn(
                            "flex items-center justify-between w-full space-x-2 p-1 rounded-md cursor-pointer select-none",
                            {
                              "bg-atherGray-850": destinationFolder?.id === folder.id,
                            },
                          )}
                          onClick={() => handleSelectDestinationFolder(folder)}
                          onDoubleClick={() => handleSelectFolder(folder)}
                        >
                          <div>
                            <FolderIcon width={48} height={48} draggable={false} alt="Folder" />
                          </div>
                          <div className="ml-1 overflow-hidden flex-1">
                            <p className={"text-xs font-semibold text-atherGray-100 line-clamp-1 mb-1"}>
                              {folder.name}
                            </p>
                            {user?.uid !== folder.creator?.uid && (
                              <UserCreated
                                size="xss"
                                className="text-atherGray-300"
                                name={folder.creator?.name}
                                picture={folder.creator?.picture}
                              />
                            )}
                          </div>
                          <button
                            className="w-6 h-6 text-atherGray-300 hover:text-atherGray-0 ml-auto"
                            onClick={e => {
                              e.stopPropagation()
                              handleSelectFolder(folder)
                            }}
                          >
                            <SharpChevronRightIcon />
                          </button>
                        </div>
                      ))
                    ) : isLoading ? (
                      <div className="flex-1 flex flex-col justify-center items-center">
                        <LoadingIcon width={16} height={16} color="#5C14EB" />
                      </div>
                    ) : error ? (
                      <div className="flex-1 flex flex-col justify-center items-center">
                        <p className="text-xs text-red-400">Fetch folders failed. Try again later</p>
                      </div>
                    ) : (
                      <div className="flex-1 flex flex-col justify-center items-center">
                        <p className="text-xs text-atherGray-300">No folders</p>
                      </div>
                    )}
                  </div>
                </InfiniteScroll>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>

      <div className="flex justify-between mt-6">
        <div>
          {canCreateNewFolder && (
            <IconButton
              colorScheme="secondary"
              className="text-xs"
              onClick={() => {
                setParentFolder({ id: selectedOrRootFolder!.id })
              }}
            >
              Create new
            </IconButton>
          )}
        </div>
        <div className="flex items-center space-x-2">
          <IconButton colorScheme="secondary" className="text-xs" onClick={onClose} type="button">
            Cancel
          </IconButton>
          <IconButton
            className="text-xs capitalize"
            type="submit"
            disabled={!canMove}
            onClick={handleMove}
            isLoading={isPending}
          >
            {movingItem?.action ?? "Move"}
          </IconButton>
        </div>
      </div>
    </Modal>
  )
}

export default MoveItemModalV2
