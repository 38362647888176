import IconButton from "@/components/IconButton"
import { DeleteIcon } from "@/components/shared/icons"
import React from "react"

interface DeleteAccountProps {
  onDelete: () => void
}

const DeleteAccount = ({ onDelete }: DeleteAccountProps) => {
  return (
    <div className="flex flex-col">
      <p className="text-[#FFF8EE] font-semibold mb-3">Delete your account</p>
      <p className="text-atherGray-300 text-sm mb-3">
        By deleting your account, you&apos;ll no longer be able to access any of your designs or log in.
      </p>
      <IconButton colorScheme="secondary" className="p-2 rounded-lg w-fit" onClick={() => onDelete()}>
        <DeleteIcon className="mr-1 text-red-500" width={16} height={16} />
        <p className="text-[#fff8ee] text-sm font-semibold">Delete account</p>
      </IconButton>
    </div>
  )
}

export default DeleteAccount
