import React from "react"
import { useProfile } from "./useProfile"
import { useAuth } from "@/providers/authContext"
import InputField from "./InputField"
import { googleAnalytics } from "@/lib/gtag"

const ProfileInfoInput = ({ setModal }: { setModal: (v: "EMAIL" | "SOCIAL" | "WALLET" | null) => void }) => {
  const { mutateUpdateUserProfile, error, setError, activeEdit, setActiveEdit, isLoading } = useProfile()
  const { userInfoQuery } = useAuth()

  if (!userInfoQuery?.data) return null

  return (
    <div>
      <InputField
        title="Username"
        defaultValue={!!userInfoQuery.data.username ? userInfoQuery.data.username : "Unknown"}
        isActive={false}
        disabled
        setActiveEdit={v => {}}
        isLoading={false}
        onSubmit={v => {}}
        css="mb-6"
      />

      <InputField
        title="Profile name"
        defaultValue={!!userInfoQuery.data.name ? userInfoQuery.data.name : "Unknown"}
        isActive={activeEdit.name}
        setActiveEdit={v => {
          setActiveEdit({
            ...activeEdit,
            name: v,
          })
        }}
        isLoading={isLoading?.name}
        onSubmit={v => {
          if (!v) {
            setError(v => ({
              ...v,
              name: "Invalid name",
            }))
            return
          }
          if (v === userInfoQuery.data.name ?? "") {
            setError(v => ({
              ...v,
              name: "The name is the same as the one from earlier.",
            }))
            return
          }

          mutateUpdateUserProfile({
            name: v,
          })
          googleAnalytics.event({
            action: "click",
            category: "settings_account",
            label: `edit user's name`,
            params: {
              user_id: userInfoQuery?.data?.uid ?? "",
              user_username: userInfoQuery?.data?.username ?? "",
            },
            value: 1,
          })
        }}
        onClickClose={() => {
          setError(v => ({
            ...v,
            name: "",
          }))
        }}
        css="mb-6"
        error={error.name}
      />

      <InputField
        type="email"
        title="Email address"
        defaultValue={userInfoQuery.data.email ?? ""}
        isActive={false}
        setActiveEdit={v => {
          if (v) {
            setModal("EMAIL")
          }
        }}
        isLoading={isLoading?.email}
        onSubmit={v => {
          if (v === userInfoQuery.data.email ?? "") {
            setError(v => ({
              ...v,
              email: "The email is the same as the one from earlier.",
            }))
            return
          }

          mutateUpdateUserProfile({
            email: v,
          })
          googleAnalytics.event({
            action: "click",
            category: "settings_account",
            label: `edit user's email`,
            params: {
              user_id: userInfoQuery?.data?.uid ?? "",
              user_username: userInfoQuery?.data?.username ?? "",
            },
            value: 1,
          })
        }}
        onClickClose={() => {
          setError(v => ({
            ...v,
            email: "",
          }))
        }}
        css="mb-6"
        error={error.email}
      />

      <InputField
        title="About me"
        defaultValue={userInfoQuery.data.description ?? ""}
        isActive={activeEdit.description}
        setActiveEdit={v =>
          setActiveEdit({
            ...activeEdit,
            description: v,
          })
        }
        isLoading={isLoading?.description}
        onSubmit={v => {
          if (v !== undefined && v.length > 300) {
            setError(v => ({
              ...v,
              description: "It is not accecpted for exceed 300 characters",
            }))
            return
          }

          mutateUpdateUserProfile({
            description: v,
          })
          googleAnalytics.event({
            action: "click",
            category: "settings_account",
            label: `edit user's description`,
            params: {
              user_id: userInfoQuery?.data?.uid ?? "",
              user_username: userInfoQuery?.data?.username ?? "",
            },
            value: 1,
          })
        }}
        onClickClose={() => {
          setError(v => ({
            ...v,
            description: "",
          }))
        }}
        css="mb-6"
        error={error.description}
      />
    </div>
  )
}

export default ProfileInfoInput
