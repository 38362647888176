import React from "react"

export const TwitterIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 17" fill="none" {...props}>
      <path
        d="M12.2175 1.34448H14.4665L9.55315 6.96014L15.3333 14.6018H10.8075L7.26269 9.96717L3.20664 14.6018H0.956293L6.21163 8.59521L0.666672 1.34448H5.30741L8.5116 5.5807L12.2175 1.34448ZM11.4282 13.2557H12.6744L4.63027 2.6199H3.29298L11.4282 13.2557Z"
        fill={"currentColor"}
      />
    </svg>
  )
}
