import Input from "@/components/Input"
import Modal from "@/components/Modal"
import { EyeCloseIcon, EyeIcon } from "@/components/shared/icons"
import { cn } from "@/lib/utils"
import React, { useEffect, useState } from "react"
import { FormProvider, useForm, useWatch } from "react-hook-form"
import PasscodeInput from "../PasscodeInput"
import IconButton from "@/components/IconButton"
import { useAuth } from "@/providers/authContext"
import { EmailAuthProvider, getAuth, linkWithCredential } from "firebase/auth"
import { useToast } from "@/hooks"
import { useMutation } from "@tanstack/react-query"
import client from "@/api/client"
import { googleAnalytics } from "@/lib/gtag"
import { isAcceptPassword, isTelegramEmail } from "@/utils/is-email-address"

interface ChangePasswordModalProps {
  isOpen: boolean
  onClose: () => void
}

type ChangePasswordModalForm = {
  email: string
  newPassword: string
  code: string
}

const ChangePasswordModal = ({ isOpen, onClose }: ChangePasswordModalProps) => {
  const [isSucess, setIsSuccess] = useState(false)
  const { userInfoQuery } = useAuth()
  const form = useForm<ChangePasswordModalForm>({
    mode: "onSubmit",
  })

  const { code, newPassword } = useWatch({
    control: form.control,
  })

  const {
    clearErrors,
    reset,
    setError,
    formState: { errors },
  } = form

  const { user, logout } = useAuth()
  const toast = useToast()

  const handleClose = () => {
    reset()
    clearErrors()
    onClose()
  }

  const isHavePasswordProvider = user?.providerData.some(provider => provider.providerId === "password")
  const isSendToTelegram =
    isTelegramEmail(user?.email ?? "") &&
    userInfoQuery?.data?.telegram?.["id"] &&
    userInfoQuery?.data?.telegram?.["allowSendPM"] === true

  const [showPassword, setShowPassword] = useState(false)

  const { mutate: mutateSendResetPasswordPasscode, isPending: isSending } = useMutation({
    mutationFn: ({ email }: { email: string }) =>
      client.api.authControllerResetPassword({
        email,
      }),
    onSuccess: () => {
      toast({
        status: "success",
        title: "Success",
        message: ["Passcode sent"],
      })
    },
    onError: (error: any) => {
      toast({
        status: "error",
        title: "Error",
        message: [error?.message ?? "Error sending passcode confirmation"],
      })
    },
  })

  const { mutateAsync: mutateVerifyResetPassword, isPending: isVerifyingResetPassword } = useMutation({
    mutationFn: (data: ChangePasswordModalForm) =>
      client.api
        .authControllerVerifyResetPassword({
          email: data.email,
          code: data.code,
          password: data.newPassword,
        })
        .then(res => res.data),
    onSuccess: () => {
      setIsSuccess(true)

      toast({
        status: "success",
        title: "Success",
        message: ["Your password has been reset"],
      })
    },
    onError: (error: any) => {
      toast({
        status: "error",
        title: "Error",
        message: [error?.message],
      })
    },
  })

  const handleResendResetPasswordPasscode = async () => {
    if (!user?.email) {
      toast({
        status: "error",
        title: "Your account does not have an email address.",
        message: ["Please add an email address to your account."],
      })
      return
    }

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "profile",
        action: "Send code",
        user_name: userInfoQuery?.data?.name ?? "",
        user_username: userInfoQuery?.data?.username ?? "",
        user_id: userInfoQuery?.data?.uid ?? "",
        user_email: user?.email ?? "",
      },
    })

    try {
      mutateSendResetPasswordPasscode({
        email: user?.email,
      })
    } catch (error) {
      console.error("Error sending email confirmation", error)
    }
  }

  const submit = async e => {
    e.preventDefault()

    const { newPassword, code } = form.getValues()
    let isValidate = true
    const trigger = await form.trigger()

    if (!user?.email) {
      toast({
        status: "error",
        title: "Your account does not have an email address.",
        message: ["Please add an email address to your account."],
      })

      return
    }

    if (newPassword && !isAcceptPassword(newPassword)) {
      setError("newPassword", {
        message:
          "Password must contain at least 8 characters, including uppercase, lowercase, numbers, and special characters",
      })
      isValidate = false
    }

    if (!trigger || !isValidate) return

    googleAnalytics.handleCategoryEvent({
      action: "click",
      params: {
        tab_name: "profile",
        action: "Change Password",
        user_name: userInfoQuery?.data?.name ?? "",
        user_username: userInfoQuery?.data?.username ?? "",
        user_id: userInfoQuery?.data?.uid ?? "",
        user_email: user?.email ?? "",
      },
    })

    const verifyData = await mutateVerifyResetPassword({
      code,
      email: user?.email,
      newPassword,
    })

    if (verifyData.success) {
      if (!isHavePasswordProvider) {
        const credential = EmailAuthProvider.credential(user?.email, newPassword)
        const auth = getAuth()

        if (!auth.currentUser) return

        await linkWithCredential(auth.currentUser, credential).catch(error => {
          console.error("Account linking error", error)
        })

        return
      }
    }
  }

  const isDisabled = !newPassword || !code || code.length < 6

  const handleSignIn = async () => {
    await logout()
  }

  useEffect(() => {
    if (!isSucess || !isOpen) return

    const timer = setTimeout(() => {
      if (isSucess) handleSignIn()
    }, 10000)

    return () => {
      clearTimeout(timer)
    }
  }, [isOpen, isSucess])

  return (
    <Modal
      className="max-w-md"
      key={isSucess ? "success-change-password" : "form-change-password"}
      title={isSucess ? "Password changed successfully" : isHavePasswordProvider ? "Change Password" : "Set Password"}
      isOpen={isOpen}
      onClose={handleClose}
      closeOnOutsideClick={isSucess}
      showCloseButton={!isSucess}
      closeOnEscape={!isSucess}
    >
      {isSucess ? (
        <div>
          <p className="text-atherGray-300">
            Your password has been changed successfully. Use your new password to sign in again
          </p>
          <div className="mt-6 flex items-center justify-end">
            <IconButton onClick={() => handleSignIn()}>Sign-In Now</IconButton>
          </div>
        </div>
      ) : (
        <FormProvider {...form}>
          <form onSubmit={submit}>
            <div className="space-y-4">
              <div>
                <p className="text-sm text-atherGray-500 mb-1">New Password</p>
                <Input
                  {...form.register("newPassword")}
                  placeholder="Enter Password"
                  className="placeholder:text-atherGray-500 rounded-lg text-atherGray-0"
                  rightIcon={
                    <button
                      className={cn("", {
                        "text-atherGray-500": !newPassword,
                        "text-atherGray-0": newPassword,
                      })}
                      type="button"
                      onClick={() => setShowPassword(prev => !prev)}
                    >
                      {showPassword ? <EyeIcon /> : <EyeCloseIcon />}
                    </button>
                  }
                  type={showPassword ? "text" : "password"}
                />
                {errors.newPassword && (
                  <p className="text-[0.65rem] mt-1 leading-4 text-red-500">{errors.newPassword?.message}</p>
                )}
              </div>

              <div>
                <p className="text-sm text-atherGray-500 mb-1">Passcode</p>
                <p className="text-xs text-atherGray-500">
                  {isSendToTelegram
                    ? "Because you are signing with Telegram. Please check telegram bot @Proto_GAIA and enter the 6-digit code we sent to you."
                    : "Enter the 6-digit code we sent to your email"}
                </p>
                <div className="flex flex-col h-full md:flex-row items-center">
                  <PasscodeInput
                    value={code}
                    onChange={v => form.setValue("code", v)}
                    className="w-8 h-8 text-xl text-atherGray-0 bg-atherGray-900 rounded-none border-atherGray-800 border-b-[2px]"
                  />
                  <div className="flex items-center justify-center h-10 w-[7rem] mt-4 md:mt-0 md:ml-4 cursor-pointer">
                    <IconButton
                      isLoading={isSending}
                      colorScheme="transparent"
                      className="text-atherPurple-300 p-0 bg-atherGray-900 text-sm font-semibold"
                      onClick={() => handleResendResetPasswordPasscode()}
                    >
                      Send Code
                    </IconButton>
                  </div>
                </div>
                {errors.code && <p className="text-[0.65rem] text-red-500 mt-1 leading-4">{errors.code?.message}</p>}
              </div>
            </div>

            <div className="flex items-center justify-end space-x-2 mt-6">
              <IconButton onClick={handleClose} colorScheme="secondary">
                Cancel
              </IconButton>
              <IconButton isLoading={isVerifyingResetPassword} disabled={isDisabled} type="submit">
                Save
              </IconButton>
            </div>
          </form>
        </FormProvider>
      )}
    </Modal>
  )
}

export default ChangePasswordModal
