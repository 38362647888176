import client from "@/api/client"
import { ImageSimpleDetail } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import LoadingLogo from "@/components/LoadingLogo"
import Modal from "@/components/Modal"
import Skeleton from "@/components/Skeleton"
import { imageExtensionRegex } from "@/constants"
import { useAuthenticatedQuery } from "@/hooks"
import { useAuth } from "@/providers/authContext"
import { googleAnalytics } from "@/lib/gtag"
import useModalStore from "@/lib/store"
import { useSignInStore, useSkipModalMissStore } from "@/stores"
import { useTempTaskStore } from "@/stores/useTempTaskStore"
import { useInfiniteQuery } from "@tanstack/react-query"
import classNames from "classnames"
import { motion } from "framer-motion"
import useCustomRouter from "@/hooks/useCustomRouter"
import { Fragment, useEffect, useMemo, useRef, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { twMerge } from "tailwind-merge"
import SubmitModal from "../SubmitModal"
import { Radio } from "../View/GalleryFilter"
import useBeMiss from "../hooks/useBeMiss"
import ViewMyVotes from "./ViewMyVotes"

export const CardBeMissImage = ({
  url,
  name,
  className,
  onClick,
  isActive,
}: {
  isActive: boolean
  url: string
  className?: string
  name: string
  onClick: () => void
}) => {
  const [imageLoaded, setImageLoaded] = useState(false)
  const [imageStep, setImageStep] = useState(1)

  return (
    <div className={twMerge("overflow-hidden relative p-2 ", className)} onClick={onClick}>
      <Skeleton className="relative group min-h-[12rem] cursor-pointer" isLoaded={imageLoaded} blurHash={undefined}>
        <img
          loading="lazy"
          className="rounded-xl block pointer-events-none select-none object-contain w-full"
          src={imageStep >= 2 ? `${url}` : `${url?.replace(imageExtensionRegex, "")}.w400.webp`}
          alt={name}
          onLoad={() => {
            setImageLoaded(true)
          }}
          onError={() => {
            setImageStep(prev => prev + 1)
          }}
        />
        <div
          className={classNames(
            "border-[2px] rounded-xl z-[1] absolute top-0 left-0 w-full h-full pointer-events-none",
            {
              "border-atherPurple-500": isActive,
              "border-transparent": !isActive,
            },
          )}
        />
      </Skeleton>
    </div>
  )
}

const BeMissUniverseOnlyTaskModal = () => {
  const { user } = useAuth()
  const take = 10
  const tempTask = useTempTaskStore(state => state.tempTask)
  const scrollRef = useRef<HTMLDivElement>(null)
  const router = useCustomRouter()
  const [selected, setSelected] = useState<ImageSimpleDetail>()
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const setSharingData = useModalStore(state => state.setSharingData)
  const [checked, setChecked] = useState(false)
  const { isOpen: isSkipModal, setIsOpen: setIsSkipModal } = useSkipModalMissStore()
  const setSignInModal = useSignInStore(state => state.setSignInModal)

  const { data: isChecked, isLoading: isLoadingChecked } = useAuthenticatedQuery(["check-participation"], () =>
    client.api.missUniverseControllerCheckUserParticipation().then(res => res.data),
  )

  const {
    data: historyRecipes,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isLoading,
    isError,
  } = useInfiniteQuery({
    queryKey: [
      "recipe-history",
      user?.uid,
      "miss-universe",
      take,
      tempTask?.filter(i => !i.isTransfer).map(t => t.id).length,
    ],
    queryFn: ({ pageParam = 0 }) =>
      client.api
        .recipeTaskControllerGetHistoryTasksPost({
          recipeIds: ["miss-universe"],
          statuses: ["COMPLETED"],
          taskIds: !user && tempTask ? tempTask.filter(i => !i.isTransfer).map(t => t.id) : undefined,
          skip: pageParam * take,
          take,
        })
        .then(res => res.data.other),

    initialPageParam: 0,
    getNextPageParam: (lastPage, pages) => (lastPage && lastPage?.length < take ? undefined : pages.length),
    refetchOnWindowFocus: false,
    enabled: user ? true : !!(tempTask && tempTask.length > 0),
  })

  const mappedHistoryRecipes = useMemo(
    () =>
      historyRecipes
        ? historyRecipes?.pages[0]?.filter(item => item.fullDataImages && item.fullDataImages?.length > 0)?.[0]
            ?.fullDataImages ?? []
        : [],
    [historyRecipes],
  )

  const onClose = () => {
    if (isOpenModal || router.query.signIn) return
    setIsOpen(false)
    setSelected(undefined)

    if (mappedHistoryRecipes.length === 0) return

    let oldData = localStorage.getItem("skip-popup-miss-universe")?.split(",") ?? []

    if (oldData.includes(mappedHistoryRecipes?.[0]?.recipeTask?.id ?? "")) return

    oldData.push(mappedHistoryRecipes?.[0]?.recipeTask?.id ?? "")

    localStorage.setItem("skip-popup-miss-universe", oldData.join(","))
  }

  const { mutate: mutateBeMiss, isPending: isLoadingMutate } = useBeMiss({
    onSuccess: () => {
      setSharingData({
        link: null,
        id: selected?.id ?? "",
        name: selected?.name ?? selected?.id ?? "",
        type: "image",
        creator: selected?.owner ?? undefined,
        url: selected?.url ?? "",
        firstContent: [
          "Thanks for joining the Miss Universe Challenge!",
          "Share your photo to get more votes and and win up to $100 Gift card & 230 free Credits",
        ],
      })

      onClose()
    },
  })

  useEffect(() => {
    setIsOpen(false)

    if (isSkipModal) return

    const skipPopupToLocalStore = localStorage
      .getItem("skip-popup-miss-universe")
      ?.split(",")
      .includes(mappedHistoryRecipes?.[0]?.recipeTask?.id ?? "")

    if (tempTask?.some(i => i.id === mappedHistoryRecipes?.[0]?.recipeTask?.id)) {
      if (skipPopupToLocalStore) return
      setIsOpen(true)
      return
    }

    if (isChecked) return

    if (mappedHistoryRecipes.length > 0) {
      if (skipPopupToLocalStore) return

      setIsOpen(true)
    }
  }, [historyRecipes])

  const renderListVoted = () => {
    if (isLoading)
      return (
        <div className="flex items-center justify-center flex-1 w-full text-gray-600">
          <LoadingLogo />
        </div>
      )

    if (isError)
      return (
        <div className="flex items-center justify-center flex-1 w-full">
          <p className="text-lg text-red-500">Error</p>
        </div>
      )

    if (!mappedHistoryRecipes || !mappedHistoryRecipes.length) {
      return (
        <div className="flex items-center justify-center flex-1 w-full">
          <p className="text-sm text-atherGray-500">No image found</p>
        </div>
      )
    }

    return (
      <InfiniteScroll
        loadMore={() => fetchNextPage()}
        hasMore={!!hasNextPage && !isFetching}
        threshold={800}
        useWindow={false}
        getScrollParent={() => scrollRef.current}
        style={{
          display: "flex",
          flexDirection: "column",
          maxWidth: "100%",
          width: "100%",
          position: "relative",
        }}
        loader={
          <div key="loader" className="flex items-center justify-center py-4">
            Loading...
          </div>
        }
      >
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.35 }}
          className="w-full flex-1 grid grid-col grid-cols-2"
        >
          {mappedHistoryRecipes.map(image => (
            <CardBeMissImage
              className="w-full"
              key={image.id}
              isActive={selected?.id === image.id}
              onClick={() => {
                setSelected(image)
              }}
              name={image.name ?? image.id}
              url={image.url ?? ""}
            />
          ))}
        </motion.div>
      </InfiniteScroll>
    )
  }
  if (isLoadingChecked) return null

  return (
    <Fragment>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        className="max-w-xl overflow-hidden max-h-(calc(100vh-4rem)) w-full"
        bodyClassName="overflow-hidden"
        containerClassName="overflow-hidden"
      >
        {isChecked ? (
          <ViewMyVotes onSubmit={onClose} />
        ) : (
          <div className="flex w-full flex-col items-center flex-1 overflow-hidden">
            <h3 className="text-center text-xl font-semibold mb-2">
              What a beautiful photo! <br />
              {`Let's join Miss Universe and get rewards!`}
            </h3>
            <p className="mb-4">Select an image you wish to join the competition</p>
            <div className="flex-1 flex-col flex overflow-auto" ref={scrollRef}>
              {renderListVoted()}
            </div>
            <div className="w-full flex justify-center pt-4 space-x-4">
              <IconButton onClick={onClose} className="text-[#EFE8FD] w-full">
                Later
              </IconButton>
              <IconButton
                disabled={!selected}
                onClick={() => {
                  if (!user) {
                    setSignInModal({
                      signIn: true,
                    })
                    return
                  }

                  googleAnalytics.event({
                    action: "click",
                    category: "be_miss_universe_modal",
                    label: "join_miss_universe",
                    params: {
                      image_id: selected?.id ?? "",
                      image_name: selected?.name ?? "",
                      image_owner: selected?.owner.name ?? "",
                    },
                  })

                  setIsOpenModal(true)
                }}
                className="bg-atherPurple-500 hover:bg-atherPurple-500 active:bg-atherPurple-500 text-[#EFE8FD] w-full "
              >
                {"Join Miss Universe Contest"}
              </IconButton>
            </div>
            <div className="w-full mt-4">
              <Radio
                checked={checked}
                onChange={v => {
                  const oldChecked = !checked
                  setChecked(oldChecked)
                  if (oldChecked) {
                    setIsSkipModal(true)
                  } else {
                    setIsSkipModal(false)
                  }
                }}
                name="Don't show me again"
                value="1"
              />
            </div>
          </div>
        )}
      </Modal>
      <SubmitModal
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        onSubmit={() => {
          googleAnalytics.event({
            action: "click",
            category: "be_miss_universe_modal",
            label: "submit_miss_universe",
            params: {
              image_id: selected?.id ?? "",
              image_name: selected?.name ?? "",
              image_owner: selected?.owner.name ?? "",
            },
          })
          setIsOpenModal(false)
          mutateBeMiss(selected?.id ?? "")
        }}
        isLoadingSubmit={isLoadingMutate}
      />
    </Fragment>
  )
}

export default BeMissUniverseOnlyTaskModal
