import IconButton from "@/components/IconButton"
import Modal from "@/components/Modal"
import { UploadIcon, XIcon } from "@/components/shared/icons"
import { useToast } from "@/hooks"
import { useEffect, useRef, useState } from "react"
import { useUpdateRecipeStore } from "@/stores"
import { Controller, FormProvider, useForm } from "react-hook-form"
import { RecipeItemTag, UpdateRecipeDto } from "@/api/sdk"
import { uploadComfyUIWorkflowFiles } from "../Tools/ComfyUIRecipe/ComfyUIRecipeBuilder/ComfyUIRecipeInfo"
import ImageUpload from "../ImageUpload"
import classNames from "classnames"
import { ImageViewWithDelete } from "../ImageUpload/ImageView"
import AspectRatio from "../AspectRatio"
import Input from "../Input"
import { useAddTagRecipesMutation, useGetRecipeCategories, useUpdateRecipeMutation } from "@/queries/workspace/recipe"
import AddTagInput from "../Explore/Gallery/GalleryFeatures/AddTagInput"
import Selector from "../Selector"
import Textarea from "../Textarea"

const UpdateRecipeModal = () => {
  const [isOpen, onClose] = useUpdateRecipeStore(state => [state.isOpen, state.onClose])

  const form = useForm<UpdateRecipeDto>()
  const { register } = form
  const toast = useToast()
  const [isUploading, setIsUploading] = useState(false)
  const [file, setFile] = useState<Blob | null>(null)
  const [preview, setPreview] = useState<string | null>(null)
  const [tags, setTags] = useState<RecipeItemTag[]>([])

  const fileInputThumbnailRef = useRef<HTMLInputElement>(null)
  const { data: tabDataCategories } = useGetRecipeCategories()

  const [isSubmitting, setSubmitting] = useState(false)

  const { mutate: addTags } = useAddTagRecipesMutation()

  const handleClose = () => {
    onClose()
    setFile(null)
    setPreview(null)
    setTags([])
  }

  const handleUploadThumbnail = async (file: Blob) => {
    if (!isOpen) return

    if (file.size > 10 * 1000 * 1000) {
      toast({
        status: "error",
        title: "File size too large",
        message: ["Input image exceeds 10MB, please scale down the image and try again"],
      })
      return
    }
    setIsUploading(true)

    const [uploaded] = await uploadComfyUIWorkflowFiles([file])

    setIsUploading(false)

    return uploaded
  }

  const { mutate: mutateUpdate, isPending: isUpdating } = useUpdateRecipeMutation({
    onSuccess: data => {
      if (isOpen?.recipeId) {
        addTags({
          recipeIds: [isOpen?.recipeId],
          tagIds: tags.map(tag => tag.id),
        })
      }

      toast({
        status: "success",
        title: "Recipe updated",
      })

      handleClose()
    },
  })

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!isOpen?.recipeId) return

    const valid = await form.trigger()

    if (!valid) return

    const values = form.getValues()

    setSubmitting(true)

    let thumbnailId = file ? undefined : preview ?? undefined
    let thumbnail: string | undefined | null = undefined

    if (!thumbnailId && !file) {
      form.setError("thumbnailId", {
        message: "Thumbnail is required",
      })

      setSubmitting(false)
      return
    }

    if (file) {
      const result = await handleUploadThumbnail(file).finally(() => {
        setIsUploading(false)
      })

      thumbnailId = result?.id ?? values.thumbnailId ?? ""
      thumbnail = result?.url
    }

    const newValues = {
      ...values,
      thumbnail,
      thumbnailId: file ? thumbnailId : values.thumbnailId,
    }

    setSubmitting(false)

    mutateUpdate({
      id: isOpen?.recipeId,
      values: newValues,
    })
  }

  useEffect(() => {
    if (!isOpen) return

    const values = {
      name: isOpen?.data.name,
      description: isOpen?.data.description,
      thumbnailId: isOpen?.data.thumbnailId,
      category: isOpen?.data.categories?.[0]?.id.toString(),
    }
    form.reset(values)
    setTags(isOpen?.data.tags ?? [])

    setPreview(isOpen?.data.thumbnail ?? null)
  }, [isOpen])

  return (
    <Modal title="Edit recipe" className="overflow-visible max-w-sm" isOpen={!!isOpen} onClose={handleClose}>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4">
        <FormProvider {...form}>
          <div className="flex flex-col gap-1">
            <label className="block font-semibold text-atherGray-300 text-sm">Thumbnail</label>
            <ImageUpload
              isLoading={isUploading}
              fileInputRef={fileInputThumbnailRef}
              upload={async files => {
                setFile(files[0])
                setPreview(URL.createObjectURL(files[0]))
              }}
              className={classNames("w-full", {
                "border-transparent": !!preview,
              })}
            >
              {preview ? (
                <ImageViewWithDelete
                  className="w-full"
                  url={preview}
                  alt="Recipe Image"
                  onDelete={() => {
                    setFile(null)
                    URL.revokeObjectURL(preview)

                    setPreview(null)
                  }}
                  preferThumbnail
                />
              ) : (
                <div className="flex flex-col items-center justify-center h-full">
                  <div className="p-2 mb-2 rounded-full bg-atherPurple-500">
                    <UploadIcon />
                  </div>
                  <p className="text-atherGray-300 text-center">
                    Upload images or Drag & Drop <br />
                    (max 2048px and less than 10MB)
                  </p>
                </div>
              )}
            </ImageUpload>
            {form.formState.errors.thumbnailId?.message && (
              <p className="text-red-500 text-xs mt-1">{form.formState.errors.thumbnailId?.message}</p>
            )}
          </div>
          <div className="flex flex-col gap-1">
            <label className="block font-semibold text-atherGray-300 text-sm">Recipe name</label>
            <Controller
              name="name"
              rules={{ required: { value: true, message: "This field is required" } }}
              render={({ field, fieldState }) => (
                <>
                  <Input className="rounded-md px-2 text-atherGray-100" placeholder="Recipe name" {...field} />
                  {fieldState.error && <p className="text-red-500 text-xs">{fieldState.error.message}</p>}
                </>
              )}
            ></Controller>
          </div>
          <div className="flex flex-col gap-1">
            <Controller
              name="category"
              rules={{ required: { value: true, message: "This field is required" } }}
              render={({ field, fieldState }) => (
                <>
                  <Selector
                    placement="bottom"
                    className="bg-atherGray-800 h-7 pl-2 py-1 text-atherGray-100"
                    listClassName="bg-atherGray-700"
                    value={
                      tabDataCategories?.categories.find(i => i.id.toString() === field.value)?.name ??
                      "Select category"
                    }
                    options={
                      tabDataCategories?.categories
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map(c => ({ id: c.id.toString(), name: c.name })) ?? []
                    }
                    onSelect={v => field.onChange(v.id)}
                  />
                  {fieldState.error && <p className="text-red-500 text-xs">{fieldState.error.message}</p>}
                </>
              )}
            ></Controller>
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-semibold text-whiteAlpha-900 text-sm pb-1 mt-2">Tags</p>
            <div className={classNames("rounded-md flex bg-atherGray-800 flex-wrap w-full min-h-[2.25rem] p-2 gap-1")}>
              {tags?.map(tag => (
                <div key={tag.id} className="flex items-center px-2 py-0.5 border rounded-lg border-atherGray-700">
                  <p className="mr-1 text-atherGray-300 text-sm">{tag.name}</p>
                  <button
                    className={classNames("flex items-center cursor-pointer justify-center")}
                    type="button"
                    onClick={() => {
                      setTags(tags => tags.filter(t => t.id !== tag.id))
                    }}
                  >
                    <XIcon width={10} height={10} className="text-atherGray-300" />
                  </button>
                </div>
              ))}
              <AddTagInput
                className="bg-atherGray-700"
                tags={tags}
                setTags={setTags as any}
                // onTag={tag => {
                //   onAddTagSuccess(tag as Tag)
                // }}
              />
            </div>
          </div>
          <div className="flex flex-col gap-1">
            <label className="block font-semibold text-atherGray-300 text-sm">Description</label>
            <div className="relative overflow-hidden rounded-md py-1 bg-atherGray-800">
              <Textarea
                {...register("description")}
                className="bg-transparent w-full pr-8 text-atherGray-100 h-[5rem] py-1 px-2 text-sm"
                placeholder={"Describe your recipe"}
              />
            </div>
          </div>

          <div className="flex justify-end mt-6 space-x-2">
            <IconButton colorScheme="secondary" className="text-xs" onClick={handleClose}>
              Cancel
            </IconButton>
            <IconButton className="text-xs" type="submit" isLoading={isSubmitting || isUpdating}>
              Confirm
            </IconButton>
          </div>
        </FormProvider>
      </form>
    </Modal>
  )
}

export default UpdateRecipeModal
