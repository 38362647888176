import client from "@/api/client"
import { UserDetail } from "@/api/sdk"
import IconButton from "@/components/IconButton"
import { DeleteIcon, LinkAccount } from "@/components/shared/icons"
import { cdnPublicFolderUrl, telegramBotId } from "@/constants"
import { useToast } from "@/hooks"
import { isFacebookApp } from "@/utils"
import { isTelegramEmail } from "@/utils/is-email-address"
import { useQueryClient } from "@tanstack/react-query"
import { TelegramAuthData } from "@telegram-auth/react"
import { getAuth, GoogleAuthProvider, linkWithPopup, User } from "firebase/auth"
import React from "react"

interface SocialsConnectedProps {
  user: User
  userDetail: UserDetail
  setModal: (modal: "TELEGRAM" | "SOCIAL" | "FARCASTER" | null) => void
}

const SocialsConnected = ({ user, userDetail, setModal }: SocialsConnectedProps) => {
  const isLinkedTelegram = userDetail?.telegram && !!userDetail?.telegram?.["id"]
  const toast = useToast()
  const qc = useQueryClient()

  const handleClickGoogle = async () => {
    const isFacebook = isFacebookApp()

    if (isFacebook) {
      toast({
        status: "error",
        title: "Can not log in from Facebook!",
        message: ["Please open this link in the external browser to sign in with Google account"],
      })
      return
    }

    const provider = new GoogleAuthProvider()

    const auth = getAuth()
    if (!auth.currentUser) return

    await linkWithPopup(auth.currentUser, provider)
      .then(result => {
        const credential = GoogleAuthProvider.credentialFromResult(result)
        if (credential) {
          const user = result.user

          if (user) {
            qc.invalidateQueries({ queryKey: ["user-info", user?.uid] })
            toast({
              status: "success",
              title: "Success",
              message: ["Link Google account successfully"],
            })
          }
        }
      })
      .catch(error => {
        console.error(error)
        toast({
          status: "error",
          title: "Error",
          message: ["Link Google account failed", "Please try again"],
        })
      })
  }

  const handleLinkTelegram = async (data: TelegramAuthData) => {
    try {
      const res = await client.api.telegramAuthControllerLinkAccount(data)
      const { customToken } = res.data

      if (customToken) {
        qc.invalidateQueries({ queryKey: ["user-info", user?.uid] })
        toast({
          status: "success",
          title: "Link Telegram account successfully",
        })
      }
    } catch (err: any) {
      console.error(`Error signing in with Telegram`, err)
      toast({
        status: "error",
        title: "Link Telegram account failed",
        message: [err?.response?.data?.message || "Please try again"],
      })
    }
  }

  const handleLinkTelegramClick = async () => {
    if (!window.Telegram?.Login?.auth) {
      toast({
        status: "error",
        title: "Error signing in with Telegram",
        message: ["Telegram Auth not available. Please try again later."],
      })
      return
    }

    window.Telegram.Login.auth({ bot_id: telegramBotId, request_access: "write" }, data => {
      if (!data) {
        // authorization failed
        toast({
          status: "error",
          title: "Error signing in with Telegram",
          message: ["Authorization failed"],
        })
      }

      handleLinkTelegram(data)
    })
  }

  const handleUnlinkTelegram = async () => {
    if (isTelegramEmail(user?.email || "")) {
      toast({
        status: "error",
        title: "Error",
        message: ["You can not unlink Telegram account from this email"],
      })
      return
    }

    setModal("TELEGRAM")
  }

  return (
    <div className="w-full mb-8">
      <p className="mb-1 font-semibold">Connected social accounts</p>
      <p className="font-300 text-atherGray-300 mb-6 text-sm">Services that you use to log in to GAIA</p>

      {/* hide link google account if user is a telegram user */}
      {!isTelegramEmail(user.email || "") && (
        <div className="w-full justify-between border-[1px] border-[#4C4B47] flex items-center rounded-3xl p-4">
          <div className="flex ">
            <img src={`${cdnPublicFolderUrl}/google.png`} alt="" className="w-[48px] h-[48px] mr-4" />
            <div className="flex flex-col">
              <p className="text-[#FFFBEE] font-bold">Google</p>
              <p className="text-atherGray-300 text-[14px]">
                {user.providerData.find(provider => provider.providerId === "google.com")?.displayName
                  ? user.providerData.find(provider => provider.providerId === "google.com")?.displayName ?? "Unknown"
                  : "Link your Google account"}
              </p>
            </div>
          </div>

          {!user.providerData.find(provider => provider.providerId === "google.com") ? (
            <IconButton onClick={() => handleClickGoogle()} className="p-0 min-h-0" colorScheme="transparent">
              <LinkAccount width={20} height={20} className="text-atherGray-300" />
            </IconButton>
          ) : (
            <IconButton
              onClick={() => {
                setModal("SOCIAL")
              }}
              colorScheme="transparent"
              className="p-0 min-h-0"
            >
              <DeleteIcon className="text-red-500" width={20} height={20} />
            </IconButton>
          )}
        </div>
      )}

      {!!userDetail?.farcaster && (
        <div className="w-full justify-between border-[1px] border-[#4C4B47] flex items-center rounded-3xl p-4 mt-4">
          <div className="flex ">
            <img src={`${cdnPublicFolderUrl}/farcaster.png`} alt="" className="w-[48px] h-[48px] mr-4" />
            <div className="flex flex-col">
              <p className="text-[#FFFBEE] font-bold">Farcaster</p>
              <p className="text-atherGray-300 text-[14px]">{userDetail?.farcaster?.["displayName"]}</p>
            </div>
          </div>
          <IconButton
            onClick={() => {
              setModal("FARCASTER")
            }}
            colorScheme="transparent"
            className="p-0 min-h-0"
          >
            <DeleteIcon className="text-red-500" width={20} height={20} />
          </IconButton>
        </div>
      )}

      <div className="w-full justify-between border-[1px] border-[#4C4B47] flex items-center rounded-3xl p-4 mt-4">
        <div className="flex ">
          <img src={`${cdnPublicFolderUrl}/telegram.png`} alt="" className="w-[48px] h-[48px] mr-4" />
          <div className="flex flex-col">
            <p className="text-[#FFFBEE] font-bold">Telegram</p>
            <p className="text-atherGray-300 text-[14px]">
              {isLinkedTelegram ? userDetail?.telegram?.["displayName"] : "Link your Telegram account"}
            </p>
          </div>
        </div>
        {!isLinkedTelegram ? (
          <IconButton onClick={() => handleLinkTelegramClick()} className="p-0 min-h-0" colorScheme="transparent">
            <LinkAccount width={20} height={20} className="text-atherGray-300" />
          </IconButton>
        ) : (
          <IconButton onClick={handleUnlinkTelegram} colorScheme="transparent" className="p-0 min-h-0">
            <DeleteIcon className="text-red-500" width={20} height={20} />
          </IconButton>
        )}
      </div>
    </div>
  )
}

export default SocialsConnected
