import LoadingLogo from "@/components/LoadingLogo"
import classNames from "classnames"
import React, { SetStateAction, useRef } from "react"
import InfiniteScroll from "react-infinite-scroller"
import RawItem from "../shared/RawItem"
import { formatDistance, parseISO } from "date-fns"
import IconButton from "@/components/IconButton"
import { DeleteIcon, EditIcon } from "@/components/shared/icons"
import { WorkspaceUser } from "@/api/sdk"

interface MemberListProps {
  mappedWorkspaceMembers: any
  fetchNextPage: () => void
  hasNextPage: boolean
  isFetching: boolean
  isLoadingWorkspaceMembers: boolean
  setSelectedBulkUser: React.Dispatch<SetStateAction<string[]>>
  selectedBulkUser: string[]
  setSelectedBulkAddUser: React.Dispatch<
    SetStateAction<
      {
        id?: number
        email: string
        role: string
      }[]
    >
  >
  setIsOpenAddMember: React.Dispatch<SetStateAction<boolean>>
  setSelectedUser: React.Dispatch<SetStateAction<WorkspaceUser | undefined>>
  handleSelectedAll: () => void
  handleClearAll: () => void
}

const MemberList = ({
  fetchNextPage,
  hasNextPage,
  isFetching,
  isLoadingWorkspaceMembers,
  mappedWorkspaceMembers,
  selectedBulkUser,
  setIsOpenAddMember,
  handleClearAll,
  handleSelectedAll,
  setSelectedBulkAddUser,
  setSelectedBulkUser,
  setSelectedUser,
}: MemberListProps) => {
  const inputRef = useRef<HTMLInputElement>(null)

  return (
    <div className="relative overflow-x-auto">
      <div>
        <InfiniteScroll
          loadMore={() => fetchNextPage()}
          hasMore={!!hasNextPage && !isFetching}
          loader={
            <div key="loader" className="flex items-center justify-center py-4">
              Loading...
            </div>
          }
        >
          {mappedWorkspaceMembers && (
            <table className="w-full text-sm text-left text-gray-500">
              <thead className="text-xs uppercase ">
                <tr className="border-b border-[#323230]">
                  <th scope="col" className="px-6 pl-0 pr-3">
                    <div className="flex items-center ">
                      <label className="flex items-center container-checkbox">
                        <p className="font-semibold text-[#7E7D77] normal-case">Name</p>
                        <input
                          ref={inputRef}
                          type="checkbox"
                          onChange={v => {
                            if (v.target.checked) {
                              handleSelectedAll()
                            } else {
                              handleClearAll()
                            }
                          }}
                        />
                        <span
                          className={classNames("checkmark", {
                            "!border-0": inputRef.current?.checked,
                          })}
                        >
                          {inputRef.current?.checked && (
                            <div className="flex items-center justify-center w-full h-full scale-150">
                              <svg
                                width="8"
                                scale={2}
                                height="8"
                                viewBox="0 0 8 8"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.33594 4.16667C1.33594 4.16667 2.4026 4.77502 2.93594 5.66667C2.93594 5.66667 4.53594 2.16667 6.66927 1"
                                  stroke="#FFF8EE"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          )}
                        </span>
                      </label>
                    </div>
                  </th>
                  <th scope="col" className="px-6 py-3">
                    <p className="font-semibold text-[#7E7D77] normal-case">Role</p>
                  </th>
                  <th scope="col" className="px-6 py-3">
                    <p className="font-semibold text-[#7E7D77] normal-case">Last login</p>
                  </th>
                  <th scope="col" className="px-6 py-3"></th>
                </tr>
              </thead>
              <tbody>
                {isLoadingWorkspaceMembers ? (
                  <tr>
                    <td colSpan={6}>
                      <div className="flex h-[300px] justify-center w-full items-center">
                        <LoadingLogo />
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {mappedWorkspaceMembers?.map((_i, _index) => (
                      <RawItem
                        uid={_i.user.uid}
                        name={_i.user.name}
                        email={_i.user.email}
                        username={_i.user.username}
                        picture={_i.user.picture}
                        role={_i.role}
                        lastLogin={
                          _i.isPending
                            ? "Waiting for confirmation"
                            : _i.isActive === true
                              ? "Active"
                              : formatDistance(parseISO(_i.lastLoginAt), new Date(), { addSuffix: true })
                        }
                        key={_index + _i.user.uid}
                        setSelectedBulk={setSelectedBulkUser}
                        selectedBulk={selectedBulkUser}
                        iconAction={
                          <div className="flex items-center space-x-2">
                            <IconButton
                              colorScheme="transparent"
                              className="p-0"
                              onClick={() => {
                                setSelectedBulkAddUser([
                                  {
                                    id: _i.id,
                                    email: _i.user.email,
                                    role: _i.role,
                                  },
                                ])
                                setIsOpenAddMember(true)
                              }}
                            >
                              <EditIcon width={20} height={20} className="text-atherGray-500" />
                            </IconButton>
                            <IconButton
                              colorScheme="transparent"
                              className="p-0"
                              onClick={() => setSelectedUser(_i.user)}
                            >
                              <DeleteIcon width={20} height={20} className="text-red-500" />
                            </IconButton>
                          </div>
                        }
                      />
                    ))}
                  </>
                )}
              </tbody>
            </table>
          )}
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default MemberList
