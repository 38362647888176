import IconButton from "@/components/IconButton"
import useCustomRouter from "@/hooks/useCustomRouter"

interface ViewMyVotesProps {
  onSubmit: () => void
}

const ViewMyVotes = ({ onSubmit }: ViewMyVotesProps) => {
  const router = useCustomRouter()
  return (
    <div className="flex items-center flex-col flex-1">
      <h3 className="text-2xl text-center mb-8 font-semibold">{`You've submitted your photo to Miss Universe Contest`}</h3>
      <IconButton
        onClick={() => {
          onSubmit()

          router.push("/ai-recipes/vote-miss-universe?viewMyVoted=true")
        }}
        className="bg-atherPurple-500 hover:bg-atherPurple-500 active:bg-atherPurple-500 text-[#EFE8FD] w-full max-w-[12rem] mb-2"
      >
        View My Votes
      </IconButton>

      <p className="italic font-semibold">Note: You can only 1 photo to the contest</p>
    </div>
  )
}

export default ViewMyVotes
