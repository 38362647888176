import { WorkspaceRole } from "@/api/sdk"
import UserBadge from "@/components/UserBadge"
import { useAuth } from "@/providers/authContext"
import { capitalize } from "@/utils"
import classNames from "classnames"
import { Dispatch, SetStateAction } from "react"

interface RawItemProps {
  uid: string
  name: string
  email: string
  picture: string
  username: string
  role: WorkspaceRole
  lastLogin: string
  selectedBulk: string[]
  setSelectedBulk: Dispatch<SetStateAction<string[]>>
  iconAction: React.ReactElement
}

const RawItem = ({
  uid,
  name,
  email,
  picture,
  role,
  lastLogin,
  username,
  setSelectedBulk,
  selectedBulk,
  iconAction,
}: RawItemProps) => {
  const { user } = useAuth()

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1)
  }

  const findIndex = selectedBulk.indexOf(uid)
  return (
    <tr className="border-b border-[#323230]">
      <td className="py-3 pl-0 pr-3">
        <label className="flex items-center container-checkbox">
          <UserBadge
            className="text-atherGray-0 mr-2"
            picture={picture}
            username={username}
            name={name}
            email={email}
            size="md"
          />
          {user?.uid !== uid && (
            <input
              type="checkbox"
              checked={findIndex !== -1}
              onChange={v => {
                if (v.target.checked) {
                  if (findIndex === -1) setSelectedBulk(v => [...v, uid])
                } else {
                  setSelectedBulk(() => {
                    const temp = [...selectedBulk]
                    if (findIndex !== -1) {
                      temp.splice(findIndex, 1) // Remove the element at the found index
                    }
                    return temp
                  })
                }
              }}
            />
          )}

          <span
            className={classNames("checkmark !top-[50%] !-translate-y-[50%] ", {
              "!border-0": findIndex !== -1,
              hidden: user?.uid === uid,
            })}
          >
            {findIndex !== -1 && (
              <div className="flex items-center justify-center w-full h-full scale-150">
                <svg width="8" scale={2} height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M1.33594 4.16667C1.33594 4.16667 2.4026 4.77502 2.93594 5.66667C2.93594 5.66667 4.53594 2.16667 6.66927 1"
                    stroke="#FFF8EE"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
          </span>
        </label>
      </td>
      <td className="px-6 py-4">
        <p className="text-[14px] font-semibold text-[#FFF8EE]">{capitalize(role)}</p>
      </td>
      <td className="px-6 py-4">
        <p className="text-[14px] font-semibold text-[#FFF8EE]">{capitalizeFirstLetter(lastLogin)}</p>
      </td>
      <td className="w-[55px]">{role !== "owner" && iconAction}</td>
    </tr>
  )
}

export default RawItem
